import React, { useContext, useEffect, useState } from "react";
import Style from "../Device.module.css";

import { Tooltip } from "@mui/material";

import { ConfigContext, socket,AppDataContext } from "../../../WebSocket/WebSocket";
import { addDeviceInterface } from "../../../types/app";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select,{SelectChangeEvent} from "@mui/material/Select";

export default function AddModal({ dataModal, setModal, deviceIndex }: any) {
  const [loginData, setLoginData] = useState({
    wsAdress: "",
    port: 11443,
    authType: 0, // způsob autorizace
    login: "", // heslo k přihlášení
    password: "", // uživatelské jméno
  });
  const ws = useContext(ConfigContext);
  const {socketState} = useContext(AppDataContext);

  useEffect(() => {
    if (typeof deviceIndex === "number") {
      const socket = socketState[deviceIndex];
      const [wsAdress,port] = (socket.wsAdress as string).split(':') 
      setLoginData({...loginData,...socket, wsAdress,port:Number(port)});
    }
  }, [deviceIndex]);

  function save() {
    const data = {
      wsAdress: loginData.wsAdress + ":" + loginData.port,
      authType: loginData.authType,
      login: loginData.login,
      password: loginData.password,
    };
    addLocalData(data);
    if (typeof deviceIndex === "number") {
      ws.deleteSocket(deviceIndex);
    }
    setTimeout(() => {
      ws.addSocket(data);
    },10)
    setModal(false);
  }

  function addLocalData(data: addDeviceInterface) {
    const jsonData = localStorage.getItem("socketDevice");
    let socketDeviceData = [];
    if (jsonData) {
      socketDeviceData = JSON.parse(jsonData);
    }
    socketDeviceData.push(data);
    localStorage.setItem("socketDevice", JSON.stringify(socketDeviceData));
  }

  const setForm = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, [name]: event.target.value });
  };


  const handleClose = () => {
    setModal(false);
  };

  const sxTextField = {
    width: "calc(100% - 10px)",
    m: "5px",
    mb: "10px",
  };
  const sxTextField50 = {
    width: "calc(50% - 10px)",
    m: "5px",
    mb: "10px",
  };

  return (
    <Modal open={dataModal} onClose={handleClose}>
      <div className={Style.Modal}>
        <div className={Style.Modal_Header}>
          Přidání zařízení
          <Tooltip title="Zavřít nastavení" placement="top" arrow>
            <IconButton
              color="primary"
              
              aria-label="Close setings"
              onClick={handleClose}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Přidat zařízení" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Save setings"
              onClick={save}
              component="span"
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={Style.Modal_body}>
          <TextField
            variant="outlined"
            size="small"
            label={"Adresa zařízení"}
            sx={sxTextField50}
            value={loginData.wsAdress}
            onChange={setForm("wsAdress")}
          />

          <TextField
            variant="outlined"
            size="small"
            label={"Port"}
            sx={sxTextField50}
            value={loginData.port}
            onChange={setForm("port")}
          />

          <TextField
            variant="outlined"
            size="small"
            label={"Uživatelské jméno"}
            sx={sxTextField50}
            value={loginData.login}
            onChange={setForm("login")}
          />

          <TextField
            variant="outlined"
            size="small"
            type="password"
            label={"Heslo"}
            sx={sxTextField50}
            value={loginData.password}
            onChange={setForm("password")}
          />

          <FormControl sx={sxTextField} size="small" fullWidth>
            <InputLabel id="costomer-select-label">Způsob ověření</InputLabel>
            <Select
              labelId="costomer-select-label"
              id="costomer-select"
              label={"Způsob ověření"}
              value={loginData.authType}
              onChange={(event) => {
                setLoginData({...loginData, authType:Number(event.target.value)})
              }}
            >
              <MenuItem value={0}>Default</MenuItem>
              <MenuItem value={1}>Linux</MenuItem>
              <MenuItem value={2}>LDAP</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </Modal>
  );
}
