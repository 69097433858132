import React, { useState, useContext, useMemo, useEffect, useRef } from "react";
import Style from "../InkData.module.css";
import style from "../style.module.css";
import {
  ConfigContext,
  socket,
  InkItemDataContext,
} from "../../../WebSocket/WebSocket";
import { inkDataItemInterface } from "../../../types/INK";
import DataModal from "./ModalData";
import { clickDataInterface } from "./ModalData";
import { electionInterface } from "../InkData";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// @ts-ignore
import { AutoSizer, List } from "react-virtualized";

import DataBox from "./DataBox";
import { dataGridLayoutTemplate } from "./DataBox";

export default function Data({
  election,
  checked,
}: {
  election: electionInterface;
  checked: boolean[] | undefined[];
}) {
  const [clickDataModal, setClickDataModal] = useState<clickDataInterface>({
    open: false,
    index: 0,
    deviceIndex: 0,
  });
  const ws = useContext(InkItemDataContext);

  const inkDataItem = ws[election.deviceIndex];
  console.log("data table ---");
  const filteredinkDataItem = inkDataItem && inkDataItem.filter((v) => v != null).filter(data => checked[data.id] || checked[data.id] === undefined);

  if (!inkDataItem) {
    return null;
  }

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const data: inkDataItemInterface = filteredinkDataItem[index];

    return (
      <React.Fragment key={index}>
        {checked[data.id] || checked[data.id] === undefined ? (
          <DataBox
            style={style}
            setClickDataModal={setClickDataModal}
            election={election}
            data={data}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }


  return (
    <>
      <div
        className={style.dataHader}
        style={{
          display: "grid",
          gridTemplateColumns: dataGridLayoutTemplate,
          height: 33,
        }}
      >
        <div>Index</div>
        <div>Ikona</div>
        <div>Název</div>
        <div>Hodnota</div>
        <div>Časová značka</div>
        <div>
          <IconButton style={{ marginTop: -8, }}>
            <MoreHorizIcon />
          </IconButton>
        </div>
      </div>
      <div className={style.data}>
        {/* @ts-ignore */}
        <AutoSizer>
          {({ height, width }: any) =>
            filteredinkDataItem && (
              <FixedSizeList
                height={height-31}
                // width={Math.max(width,1000)}
                width={width}
                // a={console.log({width})}
                itemSize={36}
                itemCount={filteredinkDataItem.length}
                overscanCount={8}
              >
                {renderRow}
              </FixedSizeList>
            )
          }
        </AutoSizer>
      </div>
      {clickDataModal.open ? (
        <DataModal dataModal={clickDataModal} setModal={setClickDataModal} />
      ) : (
        ""
      )}
    </>
  );
}
