import { useMutation, useQuery } from "@apollo/client";
import style from './style.module.css';
import { Avatar, Box, Button, Input } from "@mui/material";
import Badge from '@mui/material/Badge';
import { loader } from "graphql.macro";
import React, { useEffect, useRef, useState, useContext } from "react";
import { GetUserQuery } from "../../types";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {ChangePassword, changePasswordInterface} from './components/ChangePassword';
import { useTranslation } from 'react-i18next';
import {AppDataContext} from '../../WebSocket/WebSocket';
import SaveButton from './SaveButton';
import { toast } from "react-toastify";
import {
  UpdateUserMutation
} from '../../types';

const Mutation_UpdateUser = loader('../../graphql/UpdateUser.graphql');

export default function Profile() {
  const { t } = useTranslation();
  const { appData,setAppData } = useContext(AppDataContext);
  const [updateUser, { loading, error }] = useMutation<UpdateUserMutation>(Mutation_UpdateUser);
  const [changePasswordData, setChangePasswordData] = React.useState<changePasswordInterface>({
    email: "",
    open: false
  });

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    image: "",
  });

  const fileRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setFormData({
      fname: appData.user.fname,
      lname: appData.user.lname,
      email: appData.user.email,
      image: appData.user.image || ""
    });
  }, [appData]);

  const setForm = (name: string) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

  function save() {
    updateUser({variables:{
      fname: formData.fname,
      lname: formData.lname,
      email: formData.email,
      image: formData.image, 
      id: appData.user.id,
      settings: appData.user.settings, 
      newsletter: appData.user.newsletter
     }
   }).then(()=>{
     toast.success("Uživatelské nastavení uloženo");
   })
  }

  return (
    <div>
      <h2 style={{ textAlign: "center" }} >{t("manage.profile")}</h2>
      <Box className={style.infoProfile} style={{ display: "flex", marginBottom: 15, justifyContent: "center" }}>
        <table style={{ width: "400px", borderSpacing: "10px" }}>
          <thead>
            <tr>
              <th colSpan={2} style={{ textAlign: "center", backgroundColor:"var(--zvolena)", color:"white" }}>
                <h3 style={{margin:"10px"}} >{t("basic-info")}</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                {t("photo")}
              </th>
              <td style={{ display: "flex" }}>
                <input
                  //@ts-ignore
                  ref={fileRef}
                  style={{ marginLeft: 10 }}
                  type="file"
                  hidden
                  onChange={(e: any) => {
                    const file = e.target.files[0];
                    console.log(file);
                    const maximumSize = 0.5 * 1024 * 1024;

                    if (file.size >= maximumSize) {
                      alert("Obrázek může mít maximálně 0.5mb")
                      return;
                    }

                    if (file.type.includes("image")) {
                      const reader = new FileReader();
                      reader.addEventListener("load", (event) => {
                        // console.log(event?.target?.result);
                        // @ts-ignore
                        setFormData((data) => ({ ...data, image: event?.target?.result }));
                      });
                      reader.readAsDataURL(file);
                      // img.src = event.target.result;
                    } else {
                      alert("Neplatný typ souboru, přiložte obrázek ve formátu jpg nebo png.");
                    }
                  }}
                />

                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    
                    <IconButton
                      sx={{
                        backgroundColor:"var(--zvolena)",
                        color: "white",
                      }}
                      size="small"
                      //@ts-ignore
                      onClick={() => fileRef.current.click()}
                    >
                      <EditIcon />
                    </IconButton>
                  }
                >
                  <Avatar
                    sx={{ width: 56, height: 56 }}
                    src={formData?.image || undefined}
                  />
                </Badge>
              </td>
            </tr>
            <tr>
              <th >  <label htmlFor="jmeno">{t("name")}</label></th>
              <td id="jmeno"><Input placeholder="jmeno" value={formData.fname} onChange={setForm("fname")} /></td>
            </tr>
            <tr>
              <th ><label htmlFor="prijmeni">{t("surname")}</label></th>

              <td id="prijmeni">  <Input placeholder="prijmeni" value={formData.lname} onChange={setForm("lname")} /></td>
            </tr>
            <tr>
              <th ><label htmlFor="email">{t("email")}</label></th>

              <td id="email">   <Input placeholder="email" value={formData.email} onChange={setForm("email")} /></td>
            </tr>
            <tr>
              <th></th>
              <td>
                <Button
                  disabled={loading}
                  variant="contained"
                  onClick={save}
                >
                  {t('save')}
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>

      <Button variant={"contained"} onClick={()=>{
        setChangePasswordData({open: true, email: appData.user.email || ""})
      }}>
        {t("change-password")}</Button>

  
      <div style={{ marginLeft: 10 }}>




      </div>
      {changePasswordData.open && <ChangePassword dataModal={changePasswordData} setModal={setChangePasswordData}  />}
    </div>
  );
}


