import React, { useContext, useState, useEffect } from "react";
import Style from "./../EOV_OV.module.css";
import Out from "./Out.module.css";
import Roz from "./Roz.module.css";
import { useParams } from "react-router-dom";
import { ConfigContext, DataContext, socket } from "../../../../../WebSocket/WebSocket";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsIcon from "@mui/icons-material/Settings";

import ModalSettings from './SettingsRozvadec';

export default function Rozvadec() {
  let { id } = useParams<{ id: string }>();
  let adress = id;

  const [setings, setSetings] = useState({
    state: false,
    name: "",
    adress: adress,
  });

  useEffect(() => {
    var sendState = {
      command: "send-state",
      sendState: {
        Outputs: false,
        CVA: false,
      }
    }
    function checkAdult(age: any) {
      return age.io.engine.hostname + ":" + age.io.engine.port === adress;
    }
    const IndexDevice = socket.findIndex(checkAdult);
    if(IndexDevice != -1){
      socket[IndexDevice].emit("command", sendState);
    }
  }, [])

  const ws = useContext(ConfigContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const SetingsIndex = ws.eovSettingsData.findIndex(checkAdult);

  function test() {

  }
  function NouzoveEOV() {

  }
  function NouzoveOV() {

  }
  function reset() {

  }
  function More() {

  }
  function Edit(e: any) {
    let idd = e.target.getAttribute("data-id");
    
    const datta = {
      state: true,
      name: "něco",
      adress: adress,
    };
    setSetings(datta);
  }

  return(
    <ConfigContext.Consumer>
      {({ eovSettingsData }) => (
        <React.Fragment>
          <div className={Roz.Roz}>
            
              <div className={Roz.buttonHeader}>
                <Button onClick={test}>Test</Button>
                <Button onClick={NouzoveEOV}>Nouzově EOV</Button>
                <Button onClick={NouzoveOV}>Nouzově OSV</Button>
                <IconButton
                  color="primary"
                  onClick={More}
                  component="span"
                  data-id={id}
                >
                  <MoreHorizIcon/>
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={Edit}
                  component="span"
                  data-id={id}
                >
                  <SettingsIcon/>
                </IconButton>
              </div>
            
            <hr></hr>

            <div className={Roz.body}>
              <table>
                <tr>
                  <th colSpan={3}>Čidla</th>
                </tr>
                <tr>
                  <td>Soumradové čidlo</td>
                  <td>15 lx</td>
                </tr>
                <tr>
                  <td>Venkovní vlhkost</td>
                  <td>20 %</td>
                </tr>
                <tr>
                  <td>Venkovní teplota</td>
                  <td>10 °C</td>
                </tr> 
                <tr>
                  <td>Teplota koleje</td>
                  <td>3 °C</td>
                </tr>
              </table>
              <table>
                <tr>
                  <th colSpan={2}>Rozvaděč</th>
                </tr>
                <tr>
                  <td>IP</td>
                  <td>192.168.2.104</td>
                </tr>
                <tr>
                  <td>Gateway</td>
                  <td>192.168.2.1</td>
                </tr>
                <tr>
                  <td>Mask</td>
                  <td>255.255.255.0</td>
                </tr>
                <tr>
                  <td>MAC</td>
                  <td>0A-23-11-F0-AA-D0</td>
                </tr>
              </table>
            </div>
            <hr></hr>

            <div className={Roz.body}>

            <table>              
              <tr>
                <th colSpan={3}>Ohřev</th>
              </tr>
              <tr>
                <td>
                  Mez teploty koleje
                </td>
                <td>
                  13°C
                </td>
                <td>
                  1°C
                </td>
              </tr>
              <tr>
                <td>
                  Mez teploty opornice mokro
                </td>
                <td>
                  3°C
                </td>
                <td>
                  1°C
                </td>
              </tr>
              <tr>
                <td>
                  Mez teploty opornice sucho
                </td>
                <td>
                  -7°C
                </td>
                <td>
                  1°C
                </td>
              </tr>
              <tr>
                <td>
                  Doba topného cyklu
                </td>
                <td colSpan={2}>
                  něco
                </td>
              </tr>
              <tr>
                <td>
                  Doba zapnutí výhřevu
                </td>
                <td colSpan={2}>
                  1s
                </td>
              </tr>
              <tr>
                <td>
                  Doba vypnutí výhřevu
                </td>
                <td colSpan={2}>
                  1s
                </td>
              </tr>
              </table>

           
            <table>
              <tr>
                <th colSpan={2}>Osvětlení</th>
              </tr>
              <tr>
                <td>
                  Doba zapnutí osvětlení
                </td>
                <td>
                  1s
                </td>
              </tr>
              <tr>
                <td>
                  Doba vypnutí osvětlení
                </td>
                <td>
                  1s
                </td>
              </tr>
              <tr>
                <td>
                  Oblast osvětlovacího kalendáře
                </td>
                <td>
                  1
                </td>
              </tr>
              <tr>
                <td>
                  Posunutí východu
                </td>
                <td>
                  0:00
                </td>
              </tr>
              <tr>
                <td>
                  Posunutí západu
                </td>
                <td>
                  0:00
                </td>
              </tr>
            </table>

            </div>
            
            <hr></hr>

            <div>
              {eovSettingsData[SetingsIndex]?.Turnout?.map(({id, turnout, name, opornice, rod, phs, tongue}: any) => {
                return (
                  <div key={id} className={Roz.turnout}>
                    <div className={Roz.turnGrid}>
                      
                      <div className={Roz.turnNumber}>
                        <span>{turnout}</span>
                      </div>
                      <div className={Roz.turnName}>
                        <span>{name}</span>
                      </div>
                      <IconButton
                            color="primary"
                            onClick={Edit}
                            component="span"
                            data-id={id}
                          >
                        <SettingsIcon onClick={Edit} data-id={id} />
                      </IconButton>
                      
                      <div className={Roz.ovladani}>
                        <span>Ovládání</span><br></br>
                        <Button variant="contained" color="primary">
                          Ručně
                        </Button>
                        <Button variant="contained" color="primary">
                          Automatikou
                        </Button>
                      </div>
                      <div className={Roz.turnNumber}></div>
                      <Vyhybka settings={{id: id, opornice: opornice, rod: rod, phs: phs, tongue: tongue}} adress={adress} />
                    </div>
                    
                  </div>
                );
              })}

            </div>
              
          </div>
          {setings.state === true ? (
            <ModalSettings dataModal={setings} setModal={setSetings} />
          ) : (
            ""
          )}
        </React.Fragment>
      )}
    </ConfigContext.Consumer>
  )
}

function Vyhybka({settings, adress}:any) {

  const ws = useContext(DataContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const StateIndex = ws.eovStateData.findIndex(checkAdult);

  function checkAdult2(age: any) {
    return age.id === settings.id;
  }
  const IndexField = ws.eovStateData[StateIndex].Turnout.findIndex(checkAdult2);
  const data = ws.eovStateData[StateIndex].Turnout[IndexField]

  function color(state:any) {
    let color = "";
    switch (state) {
      case 'offAuto':
        color = "#ECECEC";
        break;
      case 'onAuto':
        color = "#38A738";
        break;
      case 'offMan':
        color = "#949396";
        break;
      case 'onMan':
        color = "#38EC38";
        break;
      case 'swBlock':
        color = "#00EFFF";
        break;
      case 'hwBlock':
        color = "#38384D";
        break;
      case 'error':
        color = "#EC3B38";
        break;
      case 'emerg':
        color = "#ECEC38";
        break;
      default:
        console.log("Neznámý stav výhybky pro výběr barvy ", state);
    }
    return color;
  }

  return(
    <svg width="160" height="65" viewBox="0 0 71 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      {!data?.valid ? 
        <path // komunikace
          d="M0 29V0H71V29H0Z" 
          fill="#EC38DE"
        />
      : "" }
      {data?.blocking ?  
        <path // blokace
          d="M63.8587 18.0007C62.3727 18.0007 61.1767 19.0827 61.1767 20.427V22.4174H62.3532V20.4536C62.3532 19.6989 63.0244 19.0916 63.8587 19.0916H64.1422C64.9765 19.0916 65.6477 19.6989 65.6477 20.4536V22.4174H66.7745C66.7934 22.4174 66.8085 22.425 66.8236 22.425V20.4261C66.8236 19.0818 65.6277 18 64.1417 18L63.8587 18.0007ZM61.0202 20.8907C60.4555 20.8907 60 21.303 60 21.8139V25.0785C60 25.5894 60.4555 25.9999 61.0202 25.9999H66.9817C67.5463 25.9999 68 25.5894 68 25.0785V21.8139C68 21.303 67.5463 20.8907 66.9817 20.8907H61.0202ZM63.9999 21.6656C64.1351 21.6656 64.2691 21.693 64.394 21.7465C64.5189 21.8001 64.6323 21.8787 64.7279 21.9777C64.8235 22.0767 64.8993 22.1941 64.9511 22.3235C65.0028 22.4528 65.0294 22.5916 65.0294 22.7316C65.0291 22.9 64.9904 23.0659 64.9162 23.2158C64.8421 23.3657 64.7348 23.4952 64.603 23.5939V25.01C64.603 25.0637 64.5614 25.1069 64.5088 25.1069H63.4905C63.4383 25.1069 63.3967 25.0633 63.3967 25.01V23.5939C63.2649 23.4952 63.1575 23.3657 63.0833 23.2158C63.0092 23.0659 62.9704 22.9 62.9702 22.7316C62.9702 22.5916 62.9969 22.4528 63.0486 22.3235C63.1003 22.1941 63.1761 22.0767 63.2717 21.9777C63.3673 21.8787 63.4808 21.8001 63.6057 21.7465C63.7306 21.693 63.8644 21.6656 63.9996 21.6656H63.9999Z" 
          fill="black"
        ><title>Blokace</title></path>
      : "" }
      {settings?.phs != 0 ? 
        <path // PHS
          fill-rule="evenodd" clip-rule="evenodd" d="M41.465 18.0001L46.0822 26.0001H57.0001V18.0001H41.465Z" 
          fill={color(data?.phs[0])}
      ><title>PHS, vývod: {settings?.phs}</title></path>
      : ""}
      {settings?.rod != 0 ? 
        <path // táhlo
          d="M3 18H19V26H3V18Z" 
          fill={color(data?.rod[0])}
        ><title>Táhlo, vývod: {settings?.rod}</title></path>
      : ""}
      {settings?.opornice != 0 ?
        <path // opornice
          d="M2.99999 3H57V11H2.99999V3Z" 
          fill={color(data?.opornice[0])}
          
        ><title>Opornice, vývod: {settings?.opornice}</title></path>
      : ""}
      {settings?.tongue != 0 ?
        <path // jazyk
          fill-rule="evenodd" clip-rule="evenodd" d="M22.0002 18.0001V26.0001H38.0001L33.381 18.0001H22.0002Z" 
          fill={color(data?.tongue[0])}
        ><title>Jazyk, vývod: {settings?.tongue}</title></path>
      : ""}
      <path // kolej
        d="M71 13V16H37.5L45.5 29H42L34 16H0V13H71Z" 
        fill="black"
      />
    </svg>
  );
}