import React, { useState } from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


export default function SelectCVA({value, onChange, name}:any) {
  const test = [1.1,1.2,1.3,1.4,1.5,1.6,1.7,1.8,1.9,2.1,2.2,2.3,2.4,2.5,2.6,2.7,2.8,2.9]
  return(
    <Select
      variant="outlined"
      value={value}
      onChange={onChange}
      name={name}      
    >
      <MenuItem key={0} value={0} >
        Nevybráno
      </MenuItem>
      {test.map((number: any) => (
        <MenuItem key={number} value={number} >
          {number}
        </MenuItem>
      ))}
    </Select>
  );
}