import React from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./WebSocket/data";
import { BrowserRouter as Router } from "react-router-dom";
import create from "zustand";

import Routing from "./Routing";
import { ToastContainer } from "react-toastify";
import { GetUserQuery } from "./components/Auth/types";

interface UserStore {
  user?: GetUserQuery["users"][0];
  setUser: (user: GetUserQuery["users"][0]) => void;
}

export const useUserStore = create<UserStore>((set) => ({
  user: undefined,
  setUser: (user) => set({ user }),
}));

export default function App() {
  return (
    <div>
      <Router>
        <Routing />
      </Router>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
