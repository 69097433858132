import React ,{useContext} from 'react';
import Style from './Home.module.css';

import {ConfigContext,AppDataContext} from '../../WebSocket/WebSocket';

import Input from '@mui/material/Input';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

//import { createStyles, makeStyles, useTheme, Theme, withTheme } from '@mui/material/styles';

export default function Home({defaultData, setDefaultData}:any) {
    
  return(
    <div className={Style.Home}>
      <div className={Style.Suma}>
        <div className={Style.Container}>
          <Souhrn />
        </div>
      </div>
      <div className={Style.Log}>
        <div className={Style.Info}>
          <Log />
        </div>
      </div>
      <div className={Style.Comunication}>
        <div className={Style.Container}>
          <Comunication />
        </div>
      </div>
    </div>
  )
    
}

function Souhrn() {

  return(
    <div>
      <span>Souhrn</span>

    </div>
  );
}

/*const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: "5px",
      marginBottom: 0,
      marginTop: 0,
      width:"33%"
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      
   
     
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);*/

function Log() {
  let lastDate = "";
  //const classes = useStyles();
  const data = useContext(ConfigContext);
  const dataData = useContext(AppDataContext);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [deviceName, setDeviceName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setPersonName(event.target.value as string[]);
  };
  const handleChangeDevice = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDeviceName(event.target.value as string[]);
  };

  function Dat(dat: string) {
    lastDate = dat;
  }

  const hlasky = [
    'info',
    'warning',
    'error'
  ];

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  return(
    <React.Fragment>
      <span>Oznámení </span>
      {/*<FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Zařízení</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={deviceName}
          onChange={handleChangeDevice}
          input={<Input />}
          renderValue={(selected) => (selected as string[]).join(', ')}
          MenuProps={MenuProps}
        >
          {dataData.deviceData.map(({settings}:any, index:number) => (
            <MenuItem key={index} value={settings.name}>
              <Checkbox checked={deviceName.indexOf(settings.name) > -1} />
              <ListItemText primary={settings.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Druh hlášky</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => (selected as string[]).join(', ')}
          MenuProps={MenuProps}
        >
          {hlasky.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={Style.HomeInfoBody}>
        {/*data.infoData.map(({DeviceAdress, data }: infoDeviceDataInterface, index:number) => {
            return (
              <React.Fragment key={index}>
                {data.map(({ name, type, link, time, date, content, effect }: infoDataInterface, index:number) => {
                  if(personName.indexOf(type) > -1){
                    return (
                      <React.Fragment key={index}>
                        {date === lastDate ? '' : date}
                        {Dat(date)}
                        <div className={Style.HomeInfoBlock}>
                          <div className={Style.HomeInfoBlockHeader}>
                          {type === 'info' ? <InfoIcon style={{ color: green[500] }} /> : ''}
                          {type === 'warning' ? <WarningIcon style={{ color: orange[500] }} /> : ''}
                          {type === 'error' ? <WarningIcon style={{ color: red[500] }} /> : ''}
                          <span style={{ marginLeft: '10px' }}> {name} </span>
                          <span style={{ float: 'right' }}> {time} </span>
                        </div>
                        <div className={Style.HomeInfoBlockBlock}>{content}</div>
                      </div>
                    </React.Fragment>
                    );
                  }
                })}
              </React.Fragment>
            );
        })}
      </div>*/}
    </React.Fragment>
  );
}

function Comunication() {

  return(
    /*<WebSocketContext.Consumer>
      {({locale, toggleLocale}) => (*/
        <div>
          <span>Poruchy  komunikací</span> <br></br>
           <br></br>
          
        </div> 
      /*)}
    </WebSocketContext.Consumer>*/
  );
}

