import { InMemoryCache, ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// @ts-ignore
import { CachePersistor, persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';



const apiURL = process.env.REACT_APP_API_URL;
console.log({apiURL})

export const httpLink = createHttpLink({
  uri: apiURL,
});

export const authLink = setContext((_, { headers }) => {

  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  const authorization = token ? `Bearer ${token}` : undefined;

  // return the headers to the context so httpLink can read them
  if(authorization) {
    if(typeof headers !== "object") {
      headers = {};
    }
    headers.Authorization = authorization;
  }

  return {
    headers: {
      ...headers, 
    },
  };
});
export const cache = new InMemoryCache({});

persistCache({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
});

export const clientDefault = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});