import React from 'react';
import style from './Style.module.css';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

export default function Menu(defaultData:any) {

  return (
    <React.Fragment>
      <NavLink to={'/DRT/prehled'} className={({isActive}) => isActive ? style.Button_Active : undefined}>
        <Button variant="contained" color="secondary" startIcon={<ViewQuiltIcon />}>
          Přehled
        </Button>
      </NavLink>
      <NavLink to={'/DRT/R110Kv'} className={({isActive}) => isActive ? style.Button_Active : undefined}>
        <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
          R110Kv
        </Button>
      </NavLink>
      <NavLink to={'/DRT/R22Kv'} className={({isActive}) => isActive ? style.Button_Active : undefined}>
        <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
          R22Kv
        </Button>
      </NavLink>
      <NavLink to={'/DRT/R6Kv'} className={({isActive}) => isActive ? 'Settings-Menu-Botton-Active' : undefined}>
        <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
          R6Kv
        </Button>
      </NavLink>
      <NavLink to={'/DRT/R3Kv'} className={({isActive}) => isActive ? 'Settings-Menu-Botton-Active' : undefined}>
        <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
          R3Kv
        </Button>
      </NavLink>
    </React.Fragment>
  );
}
