import React from 'react';
import style from './style.module.css';
import Menu from './Menu';

export default function SettingsLayout({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className={style.Settings}>
      <div className={style.Settings_Menu}>
        <Menu />
      </div>
      <div className={style.Settings_Obsah}>{children}</div>
    </div>
  );
}
