import React, {useState, useContext, useEffect} from 'react';
import Style from '../Device.module.css';
import {useParams} from "react-router-dom";
import {ConfigContext,InkDefaultDataContext, socket} from '../../../WebSocket/WebSocket';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import PauseIcon from '@mui/icons-material/Pause';
import DownloadIcon from '@mui/icons-material/Download';

export default function CommandLine() {
  let {index}  = useParams<{index:any}>();
  const ws = useContext(ConfigContext);
  const inkDefaultData = useContext(InkDefaultDataContext);
  const onlineData = ws.onlineDeviceData[index];

  useEffect(() => {
    // Update the document title using the browser API
    //document.title = `You clicked ${count} times`;
    gotoBottom("10000");
  });

  

  function gotoBottom(id:any){
    var element = document.getElementById(id);
    //@ts-ignore
    element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  return(
    <React.Fragment>
      <div className={Style.body_body_line}>
        <div className={Style.body_body_block} style={{width: "100%", minHeight: "100px", maxHeight: "500px"}}>
          <div className={Style.body_body_header}>Netstat
            <Tooltip title="Spustit netstat" placement="top" arrow>
              <IconButton 
                onClick={()=>{
                  socket[index].emit("cmd",{cmd:"netstat",act:"start"});
                }}
              >
                <PlayArrowIcon />
              </IconButton>
            </Tooltip> 
          </div>
          <pre>{onlineData?.netstat}</pre>
        </div>
      </div>

      <div className={Style.body_body_line}>
        <div className={Style.body_body_block} style={{width: "100%", minHeight: "100px", maxHeight: "550px"}}>
          <div className={Style.body_body_header}>Tail (ez.log)
            {onlineData?.tailState ? 
              <Tooltip title="Zastavit tail" placement="top" arrow>
                <IconButton 
                  onClick={()=>{
                    socket[index].emit("cmd",{cmd:"tail",act:"stop"});
                  }}
                >
                  <PauseIcon />
                </IconButton>
              </Tooltip>
            : 
              <React.Fragment>
                {onlineData?.tailState === false && onlineData?.tail === undefined ?
                  <Tooltip title="Spustit tail" placement="top" arrow>
                    <IconButton 
                      disabled={!inkDefaultData[index]?.info?.logName}
                      onClick={()=>{
                        socket[index].emit("cmd",{cmd:"tail",act:"start",path:inkDefaultData[index]?.info?.logName});
                      }}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Tooltip>
                :
                  <Tooltip title="Ukončit tail" placement="top" arrow>
                    <IconButton 
                      onClick={()=>{
                        ws.setOnlineDeviceData(() => {
                          var test = [...ws.onlineDeviceData];
                          test[index].tail = undefined;
                          return test;
                        });
                      }}
                    >
                      <StopIcon />
                    </IconButton>
                  </Tooltip> 
                }
              </React.Fragment>
            }
          </div>
          <pre id={"10000"} style={{overflow: "auto", maxHeight: "500px", overflowX: "auto"}} >{onlineData?.tail}</pre>
        </div>
      </div>
    </React.Fragment>
  );
}