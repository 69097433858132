import React, { useContext, useEffect } from "react";
import Cva from "./CVA.module.css";
import { ConfigContext, DataContext, socket } from "../../../../../WebSocket/WebSocket";
import { useParams } from "react-router-dom";



export default function CVA() {
  let { id } = useParams<{ id: string }>();
  let adress = id;
  useEffect(() => {
    var sendState = {
      command: "send-state",
      sendState: {
        Outputs: false,
        CVA: true,
      }
    }
    function checkAdult(age: any) {
      return age.io.engine.hostname + ":" + age.io.engine.port === adress;
    }
    const IndexDevice = socket.findIndex(checkAdult);
    socket[IndexDevice].emit("command", sendState);
  }, [])

  const ws = useContext(ConfigContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const SetingsIndex = ws.eovSettingsData.findIndex(checkAdult);

  return(
    <ConfigContext.Consumer>
      {({ eovSettingsData }) => (
        <React.Fragment>
          {eovSettingsData[SetingsIndex]?.CVA.map(({ id, setingsMeasurement, setingsVoltage }: any) => {
            return (
              <div key={id} className={Cva.CVA}>
                <div className={Cva.header}>
                  <span>{id}</span>
                </div>
                <div className={Cva.body}>
                <div className={Cva.grid}>
                  <div className={Cva.grid2}>
                    <div>Vývod</div>
                    <div>Hystereze</div>
                    <div>Limit</div>
                    <div>Měření</div>
                  </div>
                  {setingsMeasurement.map(({idd, output, hystereze, limit, phase, comparation }:any) => {
                    return(
                      <div key={idd} className={Cva.grid3}>
                        <div className={Cva.vyvod}>{output}</div>
                        <div>{hystereze} {comparation}</div>
                        <div>{limit} {comparation}</div>
                        <div className={Cva.mereni}><Consumption id={id} idd={idd} adress={adress} /> {comparation}</div>
                      </div>
                    )
                  })}
                </div>
                <div className={Cva.grid4}>
                  <div className={Cva.grid2}>
                    <div>Hystereze</div>
                    <div>Limit</div>
                    <div>Uf</div>
                    <div>Us</div>
                  </div>
                  {setingsVoltage.map(({idd, hystereze, limit}:any) => {
                    return(
                      <div key={idd} className={Cva.grid3}>
                        <div>{hystereze} V</div>
                        <div>{limit} V</div>
                        <div className={Cva.mereni}><VoltageUf id={id} idd={idd} adress={adress} /> V</div>
                        <div className={Cva.mereni}><VoltageUs id={id} idd={idd} adress={adress} /> V</div>
                      </div>
                    )
                  })}
                </div>

                </div>
              </div>
            )
          })}
        </React.Fragment>
      )}
    </ConfigContext.Consumer>
  )
}

function Consumption({id, idd, adress}: any) {

  const ws = useContext(DataContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const DeviceIndex = ws.eovOnlineData.findIndex(checkAdult);
  function checkAdult2(age: any) {
    return age.id === id;
  }
  const CVAIndex = ws.eovOnlineData[DeviceIndex]?.CVA.findIndex(checkAdult2);
  function checkAdult3(age: any) {
    return age.idd === idd;
  }
  const MeasurementIndex = ws.eovOnlineData[DeviceIndex]?.CVA[CVAIndex]?.measurement.findIndex(checkAdult3);

  return (
    <DataContext.Consumer>
      {({ eovOnlineData }) => (
        <React.Fragment>
          {eovOnlineData[DeviceIndex]?.CVA[CVAIndex]?.measurement[MeasurementIndex]}
        </React.Fragment>
      )}
    </DataContext.Consumer>
  );
}

function VoltageUf({id, idd, adress}: any) {

  const ws = useContext(DataContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const DeviceIndex = ws.eovOnlineData.findIndex(checkAdult);
  function checkAdult2(age: any) {
    return age.id === id;
  }
  const CVAIndex = ws.eovOnlineData[DeviceIndex]?.CVA.findIndex(checkAdult2);
  function checkAdult3(age: any) {
    return age.idd === idd;
  }
  const MeasurementIndex = ws.eovOnlineData[DeviceIndex]?.CVA[CVAIndex]?.voltage.findIndex(checkAdult3);

  return (
    <DataContext.Consumer>
      {({ eovOnlineData }) => (
        <React.Fragment>
          {eovOnlineData[DeviceIndex]?.CVA[CVAIndex]?.voltage[MeasurementIndex]?.Uf}
        </React.Fragment>
      )}
    </DataContext.Consumer>
  );
}

function VoltageUs({id, idd, adress}: any) {

  const ws = useContext(DataContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const DeviceIndex = ws.eovOnlineData.findIndex(checkAdult);
  function checkAdult2(age: any) {
    return age.id === id;
  }
  const CVAIndex = ws.eovOnlineData[DeviceIndex]?.CVA.findIndex(checkAdult2);
  function checkAdult3(age: any) {
    return age.idd === idd;
  }
  const MeasurementIndex = ws.eovOnlineData[DeviceIndex]?.CVA[CVAIndex]?.voltage.findIndex(checkAdult3);

  return (
    <DataContext.Consumer>
      {({ eovOnlineData }) => (
        <React.Fragment>
          {eovOnlineData[DeviceIndex]?.CVA[CVAIndex]?.voltage[MeasurementIndex]?.Us}
        </React.Fragment>
      )}
    </DataContext.Consumer>
  );
}
