import React, { useState, useContext, useMemo } from "react";
import Style from "../InkData.module.css";

import {electionInterface} from '../InkData';
import {InkItemDataContext,InkEventDataContext} from '../../../WebSocket/WebSocket';

import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {cloneDeep} from 'lodash';

import DataBox from './DataBox';
import DataModal, {clickDataInterface} from './ModalData';

export default function Events({election}:{election:electionInterface}){
	const [clickDataModal, setClickDataModal] = useState<clickDataInterface>({
    open: false,
    index: 0,
    deviceIndex: 0
	});

	const ws = useContext(InkItemDataContext);
	const inkDataItem = ws[election.deviceIndex];
	const event = useContext(InkEventDataContext);
  const inkDataEvent = event.inkEventData[election.deviceIndex];

  return(
		<table>
      <thead>
        <tr className={Style.tableHader}>
          <th>Index</th>
          <th>Ikona</th>
          <th>Název</th>
          <th>Hodnota</th>
          <th>Časová značka</th>
          <th>
            <IconButton>
              <MoreHorizIcon />
            </IconButton>
          </th>
        </tr>
      </thead>
      <tbody>
				{inkDataEvent.map(({id,value,time},index) => {
					let data = cloneDeep(inkDataItem[id]);
					data.value = value;
          data.time = time;
          

					return(
            <React.Fragment key={index} >
              <DataBox setClickDataModal={setClickDataModal} election={election} data={data} />
            </React.Fragment>
					); 
				})}
      </tbody>
			{clickDataModal.open ? <DataModal dataModal={clickDataModal} setModal={setClickDataModal} /> : ""}
    </table>
  );
}

