import React, {useContext} from 'react';
import style from './style.module.css';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { getColorCSS, setColorCSS } from '../../utils';
import {AppDataContext} from '../../WebSocket/WebSocket';
import SaveButton from './SaveButton';

export default function Customize() {
  const { t } = useTranslation();
  const { setAppData } = useContext(AppDataContext);

  let zelena = getColorCSS('--zelena');
  let oranzova = getColorCSS('--oranzova');
  let modra = getColorCSS('--modra');
  let cervena = getColorCSS('--cervena');

  function setColors(color:string){
    
    setAppData((appDataOrg)=>{
      if(appDataOrg.user.settings){
        appDataOrg.user.settings = {...appDataOrg.user.settings, style:{...appDataOrg.user.settings.style, color:color}};
      }else{
        appDataOrg.user.settings = {
          style:{
            color: color
          }
        }
      }
      return {...appDataOrg}
    })
  }

  return (
    <div className={style.Settings_Customize}>
      <span>{t('default-settings')}</span><br></br>

      <span>{t('choose-your-color')}</span>

      <div className={style.Settings_Customize_button}>
        <div>
          <Button variant="contained" style={{ backgroundColor: zelena }} onClick={()=>{setColors(zelena)}}/>
          <Button variant="contained" style={{ backgroundColor: oranzova }} onClick={()=>{setColors(oranzova)}}/>
          <Button variant="contained" style={{ backgroundColor: modra }} onClick={()=>{setColors(modra)}}/>
          <Button variant="contained" style={{ backgroundColor: cervena }} onClick={()=>{setColors(cervena)}}/>
        </div>
      </div>

      <div className={style.Customize_ulozit}>
        <SaveButton />
      </div>
    </div>
  );
}
