import React, {useState, useContext} from 'react';
import Style from '../Device.module.css';
//import { PieChart, Pie, Label, Cell, ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Legend,  Tooltip } from 'recharts';
import {useParams} from "react-router-dom";
import {ConfigContext, OnlineDeviceDataContext} from '../../../WebSocket/WebSocket';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [10,15,16,20,45,75,20],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [10,15,16,20,45,75,20],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};


export default function SystemPanel() {
    const [statisticsChoice, setStatisticsChoice] = useState(1);

    let {index}  = useParams<{index:any}>();
    const ws = useContext(OnlineDeviceDataContext);

    const lastData = ws?.statisticsData[index]?.minute[ws.statisticsData[index].minute.length-1];
    //@ts-ignore
    let freeStorage = 100 - lastData?.storage?.sd[0].size/100*lastData?.storage?.sd[0].free
    let freeCpu = 100 - lastData?.cpu.ink - lastData?.cpu.node - lastData?.cpu.total;
    let useCpu = 100 - freeCpu;
    let freeRam = 100 - lastData?.ram.ink - lastData?.ram.node - lastData?.ram.total;
    let useRam = 100 - freeRam;
  
    const dataCPU = [
      { value: lastData?.cpu.ink },
      { value: lastData?.cpu.node },
      { value: lastData?.cpu.total },
      { value:  freeCpu}
    ];
    const dataRAM = [
      { value: lastData?.ram.ink },
      { value: lastData?.ram.node },
      { value: lastData?.ram.total },
      { value: freeRam }
    ];
    const dataStorage = [
      { value: freeStorage },
      { value: 100 - freeStorage },
    ];
  
    function colorGraph(proc:number){
      let color = "red";
      if (proc < 30){
        color = '#3CB371'
      }
      else if (proc < 70){
        color = 'orange'
      }
      return color;
    }

    function ChartData() {
      let data;
      switch (statisticsChoice) {
        case 1: //minuta
          data = ws.statisticsData[index]?.minute;
          break;
        case 2: // hodina
          data = ws.statisticsData[index]?.hour;
          break;
        case 3: // den
          data = ws.statisticsData[index]?.day;
          break;
        case 4: // měsíc
          data = ws.statisticsData[index]?.month;
          break;
        default:
        console.log("Neznámý stav menu pro výběr grafu ", data);
      }
      console.log("render grafu ", data);
      return data;
    }
  
    
    return(
      <div className={Style.body_body_block} style={{width: "60%"}}>
        <div className={Style.body_body_header}>Stav systému</div>
        {/*<div  style={{width: "100%", height: "150px", display: "flex"}}>
          <ResponsiveContainer width="33%" height="100%">
          <PieChart>
            <Pie data={dataCPU} dataKey="value" cx="50%" cy="50%" startAngle={180}
              endAngle={0} innerRadius={50} outerRadius={65} fill="#82ca9d"
            >
              <Cell key={0} fill={"orange"} />
              <Cell key={1} fill={"green"} />
              <Cell key={2} fill={"red"} />
              <Cell key={3} fill={'dodgerblue'} />
              <Label value={"CPU " + useCpu.toFixed(1) + "%"} position="center" />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <ResponsiveContainer width="33%" height="100%">
          <PieChart>
            <Pie data={dataRAM} dataKey="value" cx="50%" cy="50%" startAngle={180}
              endAngle={0} innerRadius={50} outerRadius={65} fill="#82ca9d"
            >
              <Cell key={0} fill={"orange"} />
              <Cell key={1} fill={"green"} />
              <Cell key={2} fill={"red"} />
              <Cell key={3} fill={'dodgerblue'} />
              <Label value={"RAM " + useRam.toFixed(1) + "%"} position="center" />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
          <ResponsiveContainer width="33%" height="100%">
            <PieChart>
              <Pie data={dataStorage} dataKey="value" cx="50%" cy="50%" startAngle={180}
              endAngle={0} innerRadius={50} outerRadius={65} fill="#82ca9d"
              >
                <Cell key={0} fill={colorGraph( 100 - freeStorage)} />
                <Cell key={1} fill={'dodgerblue'} />
                <Label value={"Uložiště " +  (100 - freeStorage).toFixed(0) + "%"} position="center" />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className={Style.body_system_button} >
          <ButtonGroup color="primary" aria-label="outlined primary button group">
            <Button
                onClick={() =>  {setStatisticsChoice(1)}}
                style={statisticsChoice === 1 ? {backgroundColor: "dodgerblue", color: "white"} : {}}
            >
                Minuta
            </Button>
            <Button
                onClick={() =>  {setStatisticsChoice(2)}}
                style={statisticsChoice === 2 ? {backgroundColor: "dodgerblue", color: "white"} : {}}
            >
                Hodina
            </Button>
            <Button
                onClick={() =>  {setStatisticsChoice(3)}}
                style={statisticsChoice === 3 ? {backgroundColor: "dodgerblue", color: "white"} : {}}
            >
                Den
            </Button>
            <Button
                onClick={() =>  {setStatisticsChoice(4)}}
                style={statisticsChoice === 4 ? {backgroundColor: "dodgerblue", color: "white"} : {}}
            >
                Měsíc
            </Button>
          </ButtonGroup>
        </div>
        <div style={{width: "100%", height: "300px"}}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={ChartData()}
              margin={{
                top: 5,
                right: 30,
                left: -20,
                bottom: 5,
              }}
              key={ChartData()?.[0]?.time}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line dot={false} isAnimationActive={false}  dataKey="cpu.ink" stroke="orange" />
              <Line dot={false} isAnimationActive={false}  dataKey="cpu.node" stroke="green" />
              <Line dot={false} isAnimationActive={false}  dataKey="cpu.total" stroke="red" />
              <Line dot={false} isAnimationActive={false}  dataKey="ram.ink" stroke="blue" />
              <Line dot={false} isAnimationActive={false}  dataKey="ram.node" stroke="brown" />
              <Line dot={false} isAnimationActive={false}  dataKey="ram.total" stroke="black" />
            </LineChart>
          
        </div>*/}


        <div style={{width: "100%", height: "200px"}}>
            <Line
              className={Style.ChartLine}
              options={options}
              data={data}
            />
        </div>
      </div>
    );
  }