import React from 'react';
import Style from './Style.module.css';
import Menu from './Menu';

export default function index(defaultData:any, { children }: React.PropsWithChildren<{}>) {
  return (
    <div className={Style.Hosting}>
      <div className={Style.Menu}>
        <Menu defaultData={defaultData}  />
      </div>
      <div className={Style.Obsah}>{children}</div>
    </div>
  );
}
