import React from 'react';
import SideBar from './SideBar';
import NavBar from './NavBar';
import style from './Style.module.css';


export default function index({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className={style.Layout}>
      <div className={style.Layout_Navbar}>
        <NavBar />
      </div>
      <div className={style.Layout_Sidebar}>
        <SideBar  />
      </div>
      <div className={style.Layout_Body}>{children}</div>
    </div>
  );
}
