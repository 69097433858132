import React, { useEffect, useState } from 'react';
import style from '../../modal.module.css';
import { Modal, Tooltip, IconButton, TextField, Button, LinearProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { useMutation } from "@apollo/client";
import { loader } from "graphql.macro";
import { toast } from "react-toastify";

import { ChangePasswordMutation, ChangePasswordMutationVariables } from '../../../types';

export interface changePasswordInterface {
	email: string,
	open: boolean
}

interface formDataInterface {
	oldPassword: string,
	newPassword1: string,
	newPassword2: string
}

const MUTATION_ChangePassword = loader('../../../graphql/ChangePassword.graphql');

export function ChangePassword({ dataModal, setModal }:{dataModal:changePasswordInterface, setModal:React.Dispatch<React.SetStateAction<changePasswordInterface>>}){
	const [formData, setFormData] = useState<formDataInterface>({
		oldPassword: "",
		newPassword1: "",
		newPassword2: ""
	});
	const [changePassword, { error, loading, data }] = useMutation<ChangePasswordMutation>(MUTATION_ChangePassword);

	const setForm = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [name]: event.target.value });
  };

	function close(){
		setModal({...dataModal, open: false});
	};

	function save(){
		console.log(formData)
		changePassword({ variables: {email:dataModal.email,oldpassword:formData.oldPassword,password:formData.newPassword1}})
	};

	const sxTextField50 = {
    width: "calc(50% - 10px)",
    m: "5px",
    mb: "10px",
		fieldset:{
			borderRadius: "0px"
		}
  };

	useEffect(()=>{
		if(data?.changePassword?.status === "success"){
			toast.success("Heslo úspěšně změněno");
			close();
		}

		if(error){
			if(error?.graphQLErrors[0].message === "user not found"){
				toast.error("Uživatel nenalezen");
			}else	if(error?.graphQLErrors[0].message === "bad password"){
				toast.error("Spatné heslo");
			}else{
				toast.error("Uložení selhalo, neznámá chyba");
			}
		}
	},[data,error])

	

  return(
		<Modal open={dataModal.open} onClose={close}>
			<div className={style.Modal}>
				<div className={style.Modal_Header}>
					Přidání zařízení
					<Tooltip title="Zavřít nastavení" placement="top" arrow>
						<IconButton
							color="primary"
							
							aria-label="Close setings"
							onClick={close}
							component="span"
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title="Přidat zařízení" placement="top" arrow>
						<IconButton
							color="primary"
							aria-label="Save setings"
							onClick={save}
							component="span"
							disabled={formData.newPassword1 !== formData.newPassword2 || formData.newPassword1.length < 1 || formData.oldPassword.length < 1}
						>
							<SaveIcon />
						</IconButton>
					</Tooltip>
				</div>
				{loading && <LinearProgress />}
				<div className={style.Modal_body}>
					<form  onSubmit={(e) => {e.preventDefault(); save()}} >
						<TextField
							variant="outlined"
							size="small"
							type="password"
							label={"Aktuální heslo"}
							sx={sxTextField50}
							value={formData.oldPassword}
							onChange={setForm("oldPassword")}
						/><br></br>
						<TextField
							variant="outlined"
							size="small"
							type="password"
							label={"Nové heslo"}
							error={formData.newPassword1 !== formData.newPassword2}
							sx={sxTextField50}
							value={formData.newPassword1}
							onChange={setForm("newPassword1")}
						/>
						<TextField
							variant="outlined"
							size="small"
							type="password"
							label={"Nové heslo"}
							error={formData.newPassword1 !== formData.newPassword2}
							sx={sxTextField50}
							value={formData.newPassword2}
							onChange={setForm("newPassword2")}
						/>
						<Button
							variant="contained"
							type="submit"
							disabled={formData.newPassword1 !== formData.newPassword2 || formData.newPassword1.length < 1 || formData.oldPassword.length < 1}
							sx={{margin:"5px",borderRadius:"0px"}}
						>
							Uložit
						</Button>
					</form>
				</div>
			</div>
		</Modal>
	)
}