import React, { useContext, useState } from "react";
import Style from "../Device.module.css";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import { Modal } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { ConfigContext } from "../../../WebSocket/WebSocket";
import { addDeviceInterface } from "../../../types/app";
import { useTranslation } from "react-i18next";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { GetProjectsQuery, GetProjectbyIdQuery, GetProjectbyIdQueryVariables } from "../../../types";


const QUERY_GetProjects = loader("../../../graphql/GetProjects.graphql");
const QUERY_GetProjectById = loader("../../../graphql/GetProjectById.graphql");

export default function LoadModal({ dataModal, setModal }: any) {
  const { t } = useTranslation();
  const [loadData, setLoadData] = useState({
    list_id: 0,
  });
  const { loading, error, data, refetch } = useQuery<GetProjectsQuery>(QUERY_GetProjects);
  const {
    loading: loadingDevice,
    error: errorDevice,
    data: dataDevice,
  } = useQuery<GetProjectbyIdQuery>(QUERY_GetProjectById, {
    variables: { id: loadData.list_id } as GetProjectbyIdQueryVariables,
    fetchPolicy: "cache-and-network",
  });
  const ws = useContext(ConfigContext);
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //if (loading && loadingDevice) return <p>Loading...</p>;
  //if (error && errorDevice ) return <p>Error :(</p>;

  function SetFrom(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setLoadData({ ...loadData, [name]: value });
  }

  const handleClose = () => {
    setModal(false);
  };

  function addLocalData(data: addDeviceInterface) {
    const jsonData = localStorage.getItem("socketDevice");
    let socketDeviceData = [];
    if (jsonData) {
      socketDeviceData = JSON.parse(jsonData);
    }
    socketDeviceData.push(data);
    localStorage.setItem("socketDevice", JSON.stringify(socketDeviceData));
  }
  console.log(data);

  return (
    <Modal open={dataModal} onClose={handleClose}>
      <div className={Style.Modal}>
        <div className={Style.Modal_Header}>
          Načíst zařízení
          <Tooltip title="Zavřít" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Close setings"
              onClick={handleClose}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={Style.Modal_body}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Stavby" value="1" />
                  <Tab label="Sítě" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {loading && loadingDevice ? "Loading ..." : ""}
                {error && errorDevice ? t("device-list-load-failed") : ""}
                <div style={{ display: "flex" }}>
                  <div>
                    <FormHelperText>Stavby</FormHelperText>
                    <Select name="list_id" variant="outlined" value={loadData.list_id} onChange={SetFrom}>
                      <MenuItem value={0}>Vyberte stavbu</MenuItem>
                      {data?.projects.map((list) => {
                        return <MenuItem value={list.id}>{list.name}</MenuItem>;
                      })}
                    </Select>
                  </div>
                </div>
                <div className={Style.Output}>
                  {dataDevice?.projects_by_pk?.devices.map((devices, index: number) => {
                    return (
                      <div
                        style={{
                          cursor: "pointer",
                          background: "var(--zvolena)",
                          color: "var(--pismo)",
                          padding: 10,
                          margin: 5,
                          width: "90%",
                        }}
                        key={index}
                        onClick={() => {
                          const data = {
                            wsAdress: devices.name + "." + devices.domain + ":" + 11443,
                            authType: 1,
                            login: "plcadm",
                            password: "suojelija",
                          };
                          //console.log("add device",data);
                          addLocalData(data);
                          ws.addSocket(data);
                        }}
                      >
                        <div>
                          <b>{devices.name}</b> <br></br>
                          {devices.domain + ":" + 11443}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>
              <TabPanel value="2">
                Item Two
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
    </Modal>
  );
}
