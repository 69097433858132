import React, { useContext, useState, useEffect } from "react";
import Style from "../EOV_OV.module.css";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from '@mui/icons-material/Save';
import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Modal } from "@mui/material";
import { ConfigContext, socket } from "../../../../../WebSocket/WebSocket";
import { AnyARecord } from "dns";
import {cloneDeepWith} from 'lodash';

export default function SettingsSwitchboard({ dataModal, setModal }: any) {
  const [settingsData, setSettingsData] = useState({
    turnout: -99,
    lighting: -99,
  })

  function save() {
    function checkAdult(age: any) {
      return age.io.engine.hostname + ":" + age.io.engine.port === dataModal.adress;
    }
    const IndexDevice = socket.findIndex(checkAdult);

    const data = {
      command: "global-setings",
      settingsGlobal: {
        turnout: settingsData.turnout,
        lighting: settingsData.lighting,
      }
    }

    socket[IndexDevice].emit("command", data);
  }

  function SetFrom(event:any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setSettingsData({ ...settingsData, [name]: value });
  }

  const handleClose = () => {
    setModal({ ...dataModal, state: false });
  };

  const ws = useContext(ConfigContext);

  useEffect(() => {
    function checkAdult(age:any) {
      return age.DeviceAdress >= dataModal.adress;
    }
    const index = ws.eovSettingsData.findIndex(checkAdult);
    if(!ws.eovSettingsData[index]?.Global){ return };
    var test = cloneDeepWith(ws.eovSettingsData[index]);
    setSettingsData({
        turnout: test?.Global?.turnout,
        lighting: test?.Global?.lighting,
    });
  }, []);

  return(
    <Modal open={dataModal.state} onClose={handleClose}>
      <div className={Style.Modal}>
        <div className={Style.Modal_Header}>
          {dataModal.id} {dataModal.name}
          <Tooltip title="Zavřít nastavení" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Close setings"
              onClick={handleClose}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Uložit nastavení" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Save setings"
              onClick={save}
              component="span"
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={Style.Modal_body}>
          <div className={Style.Output}>
            <TextField
              type="number"
              label="Počet výhybek"
              name="turnout"
              variant="outlined"
              value={settingsData.turnout}
              onChange={SetFrom}
            />
            <TextField
              type="number"
              label="Počet skupin osvětlení"
              name="lighting"
              variant="outlined"
              value={settingsData.lighting}
              onChange={SetFrom}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
