import React, {useState, useContext} from 'react';
import Style from './Device.module.css';
import {ConfigContext, socket, AppDataContext, InkDefaultDataContext} from '../../WebSocket/WebSocket';
import {networkInterface} from '../../types/app';

import Button from '@mui/material/Button';
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsModal from './components/SettingsDevice';
import Tooltip from '@mui/material/Tooltip';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RefreshIcon from '@mui/icons-material/Refresh';

import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SyncIcon from '@mui/icons-material/Sync';

import {useParams} from "react-router-dom";

import SystemPanel from './components/SystemPanel';
import CommandLine from './components/CommandLine';

export default function Device() {
  let {index}  = useParams<{index:any}>();
  const [openSettingsModal, setOpenSettingsModal] = useState(false);

  //const ws = useContext(ConfigContext);
  //const data = ws.deviceData[index];
  //const wsAdress = ws.socketState[index]?.wsAdress
  //console.log('device dats', data);

  function edit() {
    setOpenSettingsModal(!openSettingsModal);
  }
  function reboot(){

  }
  function uploadXml(){

  }
  function downloadXml(){

  }

  return(
    <React.Fragment>
      <div className={Style.body_Header}>
        <span> {/*data?.settings?.name*/}</span>
        <Tooltip title="Upravit nastavení PLC" arrow>
          <IconButton color="primary" style={{float: "right", marginLeft: "5px"}}  onClick={edit} component="span">
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Restart PLC" arrow>
          <IconButton color="primary" style={{float: "right", marginLeft: "5px"}}  onClick={reboot} component="span">
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Nahrát XML" arrow>
          <IconButton color="primary" style={{float: "right", marginLeft: "5px"}}  onClick={uploadXml} component="span">
            <UploadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Stáhnout XML" arrow>
          <IconButton color="primary" style={{float: "right", marginLeft: "5px"}}  onClick={downloadXml} component="span">
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Načíst nové informace" arrow>
          <IconButton color="primary" style={{float: "right", marginLeft: "5px"}}  onClick={()=>{socket[index].emit("cmd",{cmd:"reload"})}} component="span">
            <SyncIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div className={Style.body_body}>
        <div className={Style.body_body_line}>
          <SystemPanel />
          <Info index={index}/>
        </div>
        <div className={Style.body_body_line}>
          <Network index={index}/>
          <Dokumentace deviceIndex={index}/>
          <Logy deviceIndex={index}/>
        </div>
        <CommandLine />
      </div>
      {openSettingsModal === true ? <SettingsModal dataModal={openSettingsModal} setModal={setOpenSettingsModal} /> : ""}
    </React.Fragment>
  );
}




function Info({index}:{index:number}){
  console.log("rendr tabulka dat");

  return(
    <AppDataContext.Consumer>
      {({deviceData}) =>(
        <InkDefaultDataContext.Consumer>
          {(data) => (
            <div className={Style.body_body_block} style={{width: "40%"}}>
              <div className={Style.body_body_header}>Informace</div>
              <table>
                <tbody>
                  <tr>
                    <th>Název</th><td>{deviceData[index]?.settings?.hostName}</td>
                  </tr>
                  <tr>
                    <th>Umístění</th><td>{data[index]?.info.destination}</td>
                  </tr>
                  <tr>
                    <th>Časová zóna</th><td>{deviceData[index]?.settings?.ntp.timeZone}</td>
                  </tr>
                  <tr>
                    <th>NTP Primární</th><td>{deviceData[index]?.settings?.ntp.primary}</td>
                  </tr>
                  <tr>
                    <th>NTP Sekundární</th><td>{deviceData[index]?.settings?.ntp.secondary}</td>
                  </tr>
                  <tr>
                    <th>Firmware PLC</th><td>{deviceData[index]?.info.firmware}</td>
                  </tr>
                  <tr>
                    <th>Model PLC</th><td>{deviceData[index]?.info.model}</td>
                  </tr>
                  <tr>
                    <th>v.soft PLC</th><td>{deviceData[index]?.info.vSoftwarePLC}</td>
                  </tr>
                  <tr>
                    <th>typ PLC</th><td>{deviceData[index]?.info.typPLC}</td>
                  </tr>
                  <tr>
                    <th colSpan={2} style={{backgroundColor:"darkorange",color:"white", padding:"5px"}}>Informace (Web Vizu)</th>
                  </tr>
                  <tr>
                    <th>licence</th><td>{deviceData[index]?.info.license === true ? <CheckIcon /> : <CloseIcon />}  </td>
                  </tr>
                  <tr>
                    <th>doména</th><td>{deviceData[index]?.settings?.domain}</td>
                  </tr>
                  <tr>
                    <th>oprávnění</th><td>{deviceData[index]?.auth} </td>
                  </tr>
                  <tr>
                    <th>typy zařízení</th><td>{data[index]?.deviceType}</td>
                  </tr>
                  <tr>
                    <th>ca server</th><td>{deviceData[index]?.settings?.caServer}</td>
                  </tr>
                  <tr>
                    <th>ldap server</th><td>{deviceData[index]?.settings?.ldapServer}</td>
                  </tr>
                  <tr>
                    <th>verze</th><td>{deviceData[index]?.info.vSoftwareSocket}</td>
                  </tr>
                  <tr>
                    <th colSpan={2} style={{backgroundColor:"darkorange",color:"white", padding:"5px"}}>Informace (integrační koncentrátor)</th>
                  </tr>
                  <tr>
                    <th>licence</th><td>{deviceData[index]?.info.license === true ? <CheckIcon /> : <CloseIcon />}  </td>
                  </tr>
                  <tr>
                    <th>název aplikace</th><td>{data[index]?.info.inkName}</td>
                  </tr>
                  <tr>
                    <th>binárka</th><td>{data[index]?.info.appName}</td>
                  </tr>
                  <tr>
                    <th>xml</th><td>{data[index]?.info.xmlName}</td>
                  </tr>
                  <tr>
                    <th>log</th><td>{data[index]?.info.logName}</td>
                  </tr>
                  <tr>
                    <th>verze</th><td>{data[index]?.info.vSoftInk}</td>
                  </tr>
                </tbody>
                
              </table>
            </div>
          )}
        </InkDefaultDataContext.Consumer>
      )}
    </AppDataContext.Consumer>
  );
}


function Network({index}:{index:number}) {
  console.log("rendr network");

  const data = useContext(AppDataContext);
  let poleKaret:string[] = [];
  if(data.deviceData[index]?.settings?.network){
    Object.keys(data.deviceData[index]?.settings?.network).forEach(key => poleKaret.push(key));
  }
  
  return(
    <div className={Style.body_body_block} style={{width: "100%"}} >
      <div className={Style.body_body_header}>Síťové karty
        <Tooltip title="Znovu načíst" placement="top" arrow>
          <IconButton 
            color="primary" 
              onClick={()=>{
                socket[index].emit("cmd",{cmd:"reload",act:"interface"});
              }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </div>
      <table>
        <tbody>
          {poleKaret.map((key)=>{
            //@ts-ignore
            const network:networkInterface = data.deviceData[index]?.settings?.network[key]
            return(
              <React.Fragment key={key} >
                <tr>
                  <th>ETH {key}</th><td>{network.dhcp ? "DHCP" : "Static" }</td>
                </tr>
                <tr>
                  <td style={{paddingLeft: "15px"}}>IP:</td><td>{network.ip}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft: "15px"}}>mask:</td><td>{network.mask}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft: "15px"}}>mac:</td><td>{network.mac}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft: "15px"}}>getway:</td><td>{network.gateway}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft: "15px"}}>dns1:</td><td>{network.dns1}</td>
                </tr>
                <tr>
                  <td style={{paddingLeft: "15px"}}>dns2:</td><td>{network.dns2}</td>
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  );
}

function Dokumentace({deviceIndex}:{deviceIndex:number}) {
  console.log("rendr dokumentace");

  return(
    <AppDataContext.Consumer>
      {({socketState, deviceData}) =>(
        <div className={Style.body_body_block} style={{width: "100%"}} >
          <div className={Style.body_body_header}>Dokumentace
            <Tooltip title="Znovu načíst" placement="top" arrow>
              <IconButton 
                color="primary"
                onClick={()=>{
                  socket[deviceIndex].emit("cmd",{cmd:"reload",act:"documents"});
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
          <table>
            <tbody>
              {deviceData[deviceIndex]?.file?.documentation?.map((link:string,index:number)=>{
                return(
                  <tr key={index}>
                    <td><a href={"https://" + socketState[deviceIndex]?.wsAdress + "/" + link} target="_blank">{link}</a></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </AppDataContext.Consumer>
  );
}

function Logy({deviceIndex}:{deviceIndex:number}) {
  console.log("rendr logy");

  return(
    <AppDataContext.Consumer>
      {({socketState, deviceData}) =>(
        <div className={Style.body_body_block} style={{width: "100%"}} >
          <div className={Style.body_body_header}>Logy
            <Tooltip title="Znovu načíst" placement="top" arrow>
              <IconButton 
                color="primary"
                onClick={()=>{
                  socket[deviceIndex].emit("cmd",{cmd:"reload",act:"logs"});
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
          <table>
            <tbody>
              {deviceData[deviceIndex]?.file?.log?.map((link:string,index:number)=>{
                return(
                  <tr key={index}>
                    <td><a href={"https://" + socketState[deviceIndex]?.wsAdress + "/" + link} target="_blank">{link}</a></td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </AppDataContext.Consumer>
  );
}