import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { io, Socket } from "socket.io-client";
import { immer } from 'zustand/middleware/immer'


import {
  setingsOutputsInterface,
  stateDataOutputsInterface,
  onlineDataInterface,
  eovSettingsCommandInterface,
  eovStateCommandInterface,
} from "../types/EOV_OV";
import {
  appDataInterface,
  socketStateInterface,
  addDeviceInterface,
  infoDataInterface,
  infoDeviceDataInterface,
  onlineDeviceDataInterface,
  defaultMessageInterface,
  statisticsInterface,
  resorcesStatisticsDataInterface,
  userDataInterface
} from "../types/app";
import {
  inkDataInterface,
  inkDefaultData,
  inkDataItemInterface,
  inkDataDriverInterface,
  inkDataEventInterface,
  systemDataMapingInterface,
} from "../types/INK";

import {
  findIndexDeviceByAdress,
  findIndexDeviceByID,
  filterParseData,
} from "../utils";

import { useQuery } from "@apollo/client";
import { loader } from "graphql.macro";
import { setColorCSS } from '../utils';
import { GetUserQuery } from "../types";
import { cp } from "fs";
import create from 'zustand'
import { inkParseSystemData } from "./inkParseData";
import { useTypeVariableStore } from "./data";

//import {defaultMessageReceive} from './defaultMessage'

//import {inkParseData} from './inkParseData';
//import {eovGenerateStructure} from './generateData/eov_ov';

const QUERY_UserInfo = loader("./../graphql/GetUser.graphql");

export const socket: Socket[] = [];
//export let globalState: globalStateInterface;

/*interface globalStateInterface {
  setingsOutputs: any;
  stateDataOutputs: any;
  socketState: any;
  onlineDeviceData: any;
  statisticsData: any;
  inkItemData: inkDataItemInterface[][];
}*/

// EOV_OV povely
export let eovSettingsCommand: eovSettingsCommandInterface[] = [];
export let eovStateCommand: eovStateCommandInterface[] = [];

export interface contextInterface {
  onlineDeviceData: onlineDeviceDataInterface[];
  addSocket(data: addDeviceInterface): void;
  deleteSocket(index: number): void;
  eovSettingsData: setingsOutputsInterface[];
  infoData: infoDeviceDataInterface[];
  setOnlineDeviceData: React.Dispatch<
    React.SetStateAction<onlineDeviceDataInterface[]>
  >;
}

export interface appContextInterface {
  deviceData: defaultMessageInterface[];
  socketState: socketStateInterface[];
  appData: appDataInterface;
  setAppData: React.Dispatch<React.SetStateAction<appDataInterface>>;
}

export interface onlineDeviceDataContextInterface {
  statisticsData: statisticsInterface[];
}

interface InkEventDataContextInterface {
  inkEventData: inkDataEventInterface[][];
  clearInkEventData: (index: number) => void;
}

// @ts-ignore
export const ConfigContext = createContext<contextInterface>({});
// @ts-ignore
export const AppDataContext = createContext<appContextInterface>({});
// @ts-ignore
export const OnlineDeviceDataContext = createContext<onlineDeviceDataContextInterface>({});

export const InkDefaultDataContext = createContext<inkDefaultData[]>([]);
export const InkItemDataContext = createContext<inkDataItemInterface[][]>([]);
export const InkEventDataContext = createContext<InkEventDataContextInterface>({
  inkEventData: [],
  clearInkEventData: () => {},
});

export const DataContext = createContext({
  eovOnlineData: [{} as onlineDataInterface],
  eovStateData: [{} as stateDataOutputsInterface],
});

interface SocketStore {
  socketState: socketStateInterface[];
  setSocketState: (data: socketStateInterface[]) => void;
}

export const useSocketStore = create<SocketStore>((set) => ({
  socketState: [],
  setSocketState: (socketState) => set({socketState}),
}))

// useSocketStore.getState()
// const {socketState} = useSocketStore(state => state)

export default function WebSocket({ children }: React.PropsWithChildren<{}>) {
  // Data potřebná pro fungování aplikace
  const [appData, setAppData] = useState<appDataInterface>({
    user:{
      id: 0,
      fname: "Anonymní",
      lname: "uživatel",
      email: "",
      logged: false,
      role: "anonym",
      newsletter: false,
      settings: null
    },
    language: ""
  });
  //const [socketState, setSocketState] = useState<socketStateInterface[]>([]);
  const [socketState, setSocketState] = useSocketStore(state => [state.socketState,state.setSocketState]);
  const [deviceData, setDeviceData] = useState<defaultMessageInterface[]>([]);
  const [onlineDeviceData, setOnlineDeviceData] = useState<onlineDeviceDataInterface[]>([]);
  const [infoData, setInfoData] = useState<infoDeviceDataInterface[]>([]);
  const [statisticsData, setStatisticsData] = useState<statisticsInterface[]>([]); // výkonostní statystiky

  // INK data
  const [inkDefaultData, setInkDefaultData] = useState<inkDefaultData[]>([]);
  const [inkItemData, setInkItemData] = useState<inkDataItemInterface[][]>([]);
  const [inkDriverData, setInkDriverData] = useState<inkDataDriverInterface[][]>([]);
  const [inkEventData, setInkEventData] = useState<inkDataEventInterface[][]>([]);
  // Pole inků, každý objekt specifikuje indexy item položek pro jednotlivé proměné
  //const [inkSytemDataMaping, setInkSytemDataMaping] = useState<systemDataMapingInterface[]>([]);
  const [deviceStoreData, setInkSytemDataMaping] = useTypeVariableStore((state) => [state.device, state.setInkSytemDataMaping]);

  // EOV_OV data
  const [eovSettingsData, setEovSettingsData] = useState<setingsOutputsInterface[]>([]);
  const [eovStateData, setEovStateData] = useState<stateDataOutputsInterface[]>([]);
  const [eovOnlineData, setEovOnlineData] = useState<onlineDataInterface[]>([]);
  const { loading, error, data, refetch } = useQuery<GetUserQuery>(QUERY_UserInfo,{ 
    fetchPolicy: "cache-and-network",    
    onError(error) {
      if (error.message.includes("JWTExpired")) {
        window.localStorage.removeItem("apollo-cache-persist");
        window.localStorage.removeItem("token");
        window.location.href = "/";
      }
    }, 
    variables:{
      id: Number(window.localStorage.getItem("user-id"))
    }
  });

  // načtení informací o uživately
  useEffect(()=>{
    const user = data?.users[0];
    if(user){
      const dataUser:userDataInterface = {
        id: user.id,
        fname: user.fname,
        lname: user.lname,
        email: user.email,
        logged: true,
        role: user.role,
        image: user.image || undefined,
        newsletter: user.newsletter || false,
        settings: user.settings
      }
      console.log("Data o uživately",dataUser)
      setAppData({...appData,user: dataUser});
    }
  },[data])

  // provedení nastvení aplikace
  useEffect(()=>{
    const user = appData.user
    if(user?.settings?.style?.color){
      setColorCSS(user.settings.style.color)
    }
  },[appData])

  // načtení naposledy připojených zařízení 
  useEffect(() => {
    const jsonData = localStorage.getItem("socketDevice");
    if (jsonData) {
      const data = JSON.parse(jsonData);
      for (let i = 0; data.length > i; i++) {
        addSocket(data[i]);
      }
    }
    return () => {
      setInkEventData([]);
      setSocketState([])
    }
  },[]);

  function clearInkEventData(index: number) {
    setInkEventData((inkEventDataOrig) => {
      inkEventDataOrig[index] = [];
      return [...inkEventDataOrig];
    });
  }

  function addSocket(wsData: addDeviceInterface) {
    console.log("addSocker",wsData)
    // vytvoření socket state
    const socketStateData: socketStateInterface = {
      wsAdress: wsData.wsAdress,
      wsID: null,
      connect: false,
      defaultMessage: false,
    };
  
    let socketStateOrig = useSocketStore.getState().socketState;
    socketStateOrig.push(socketStateData);
    setSocketState([...socketStateOrig]);
    

    // vytvoření socket.io
    const test = io(wsData.wsAdress, {
      withCredentials: true,
      extraHeaders: {
        "my-custom-header": "abcd",
      },
      auth: {
        username: wsData.login,
        password: wsData.password,
        type: wsData.authType,
        language: "cs",
      },
    });
    socket.push(test);
    Connect(socket.length - 1); // vytvoření připojení

    const infoDataDefault = {
      DeviceAdress: wsData.wsAdress,
      data: [],
    };
    //@ts-ignore
    setInfoData([...infoData, infoDataDefault]);

    const onlineDeviceDataDefault: onlineDeviceDataInterface = {
      DeviceAdress: wsData.wsAdress,
      time: "", // čas PLC
      date: "", // Datum PLC
      cpu: 0, // využití procesoru v %
      ram: 0, // využitá paměť ram v MB
      storage: 0, // využitá paměť uložiště v MB
      netstat: "",
      tail: undefined,
      tailState: false,
      tcpdump: undefined,
      tcpdumpState: false,
    };
    setOnlineDeviceData([...onlineDeviceData, onlineDeviceDataDefault]);
  }

  function deleteSocket(index: number) {
    const jsonData = localStorage.getItem("socketDevice");
    if (jsonData) {
      let socketDeviceData = JSON.parse(jsonData);
      socketDeviceData.splice(index, 1);
      localStorage.setItem("socketDevice", JSON.stringify(socketDeviceData));
    }

    socket[index].disconnect(); // uzavření socket.io
    socket.splice(index, 1); // smazání socket.io

    // vymyzání záznamu o zařízení
    let socketStateData = [...socketState];
    socketStateData.splice(index, 1);
    setSocketState(socketStateData);

    // vymazání informací o zařízení
    let deviceDataData = [...deviceData];
    deviceDataData.splice(index, 1);
    setDeviceData(deviceDataData);

    // vymazání defaultních dat inku
    let inkDefaultDataData = [...inkDefaultData];
    inkDefaultDataData.splice(index, 1);
    setInkDefaultData(inkDefaultDataData);
    // vymazání item dat inku
    let inkItemDataData = [...inkItemData];
    inkItemDataData.splice(index, 1);
    setInkItemData(inkItemDataData);
    // vymazání driver dat inku
    let inkDriverDataData = [...inkDriverData];
    inkDriverDataData.splice(index, 1);
    setInkDriverData(inkDriverDataData);
    // vymazání událostí dat inku
    let inkEventDataData = [...inkEventData];
    inkEventDataData.splice(index, 1);
    setInkEventData(inkEventDataData);

    // výpis aktuálního stavu dat
    console.log("###### Data vymyzána ######");
    console.log("socket", socket);
    console.log("socketState", socketState);
    console.log("deviceData", deviceData);
    console.log("inkDefaultData", inkDefaultData);
    console.log("inkItemData", inkItemData);
    console.log("inkDriverData", inkDriverData);
    console.log("inkEventData", inkDriverData);
    console.log("###########################");
  }

  function Connect(e: number) {
    socket[e].on("connect_error", (err: any) => {
      console.log("socket error: ", err.message);
      if (err.message === "xhr poll error") {
        toast.error("Zařízení nedosažitelné");
      } else if (err.message === "Autorizace odmítnuta") {
        toast.error("Autorizace odmítnuta");
      } else {
        toast.error("Neznámá chyba: " + err.message);
      }
    });

    socket[e].on("connect", () => {
      console.log("Zařízení: " + socket[e].io.opts.hostname + " připojeno");
      toast.success("Zařízení: " + socket[e].io.opts.hostname + " připojeno");
      const index = findIndexDeviceByAdress(socket[e].io.opts.hostname + ":" + socket[e].io.opts.port);

      let socketStateOrig = useSocketStore.getState().socketState;
      socketStateOrig[index].wsID = socket[e].id;
      socketStateOrig[index].connect = true;
      setSocketState([...socketStateOrig]);
    });

    socket[e].on("disconnect", () => {
      console.log("Zařízení: " + socket[e].io.opts.hostname + " odpojeno");
      toast.error("Zařízení: " + socket[e].io.opts.hostname + " odpojeno");
      const index = findIndexDeviceByAdress(socket[e].io.opts.hostname + ":" + socket[e].io.opts.port);

      // nastavení zařízení jako offline
      let socketStateOrig = useSocketStore.getState().socketState;
      socketStateOrig[index].connect = false;
      setSocketState([...socketStateOrig]);

      // nastavení špatné kvality všem položkám
      setInkItemData((InkItemDataOrig) => {
        var data = [...InkItemDataOrig];
        for (let i = 0; data[index].length > i; i++) {
          if (data[index][i]) {
            data[index][i].quality = 1;
          }
        }
        return data;
      });
    });

    /*socket[e].on("date", ( date:any ) => {
      setEovOnlineData((eovOnlineDataOrig):any => {
        let adress = socket[e].io.engine.hostname + ":" + socket[e].io.engine.port
        var test = [...eovOnlineDataOrig];
        for(let i = 0; i < test.length; i ++){
          if(test[i].DeviceAdress === adress){
            test[i].Global.date = date.date;
            test[i].Global.time = date.time;
          };
        }
        return test;
      });
    });*/

    socket[e].on("onlineDataDevice", (data: any) => {
      setOnlineDeviceData((onlineDeviceDataOrig): any => {
        let adress =
          socket[e].io.opts.hostname + ":" + socket[e].io.opts.port;
        var test = [...onlineDeviceDataOrig];
        for (let i = 0; i < test.length; i++) {
          if (test[i].DeviceAdress === adress) {
            test[i].time = data.time;
            test[i].date = data.date;
            test[i].cpu = data.cpu;
            test[i].ram = data.ram;
            test[i].storage = data.storage;
          }
        }
        return test;
      });
    });

    // výkonostní statistiky
    socket[e].on("resorcesStatistics",(data: statisticsInterface) => {
      const index = findIndexDeviceByID(socket[e].id);
      /*var test = [...globalState.statisticsData];
      test[index] = data;
      setStatisticsData(test);*/
    });

    socket[e].on("resorcesStatisticsMinute-push",(data: resorcesStatisticsDataInterface) => {
        const index = findIndexDeviceByID(socket[e].id);
        /*var test = [...globalState.statisticsData];
        if (test[index]?.minute) {
          test[index].minute.push(data);
          if (test[index].minute.length > 60) {
            test[index].minute.shift();
          }
          setStatisticsData(test);
        }*/
      }
    );
    socket[e].on("resorcesStatisticsHour-push",(data: resorcesStatisticsDataInterface) => {
        const index = findIndexDeviceByID(socket[e].id);
        setStatisticsData((dataOrig)=>{
          if (dataOrig[index]?.hour) {
            dataOrig[index].hour.push(data);
            if (dataOrig[index].hour.length > 60) {
              dataOrig[index].hour.shift();
            }
          }
          return [...dataOrig]
        })
      }
    );
    socket[e].on("resorcesStatisticsDay-push",(data: resorcesStatisticsDataInterface) => {
        const index = findIndexDeviceByID(socket[e].id);
        setStatisticsData((dataOrig)=>{
          if (dataOrig[index]?.day) {
            dataOrig[index].day.push(data);
            if (dataOrig[index].day.length > 60) {
              dataOrig[index].day.shift();
            }
          }
          return [...dataOrig]
        })
      }
    );
    socket[e].on("resorcesStatisticsMonth-push",(data: resorcesStatisticsDataInterface) => {
        const index = findIndexDeviceByID(socket[e].id);
        /*
        var test = [...globalState.statisticsData];
        if (test[index]?.month) {
          test[index].month.push(data);
          if (test[index].month.length > 60) {
            test[index].month.shift();
          }
          setStatisticsData(test);
        }*/
      }
    );

    socket[e].on("default",(ddd: { defaultMess: defaultMessageInterface; auth: string }) => {
        console.log("Default data", ddd, socket[e].id);
        const index = findIndexDeviceByID(socket[e].id);

        //const data = defaultMessageReceive(message);

        setDeviceData((deviceDataOrig) => {
          var data = [...deviceDataOrig];
          data[index] = ddd.defaultMess;
          //data[index].auth = auth;
          return data;
        });

        /*
      let address = socket[e].io.engine.hostname + ":" + socket[e].io.engine.port
      if(message.info.type === "EOV_OV"){
        const data = eovGenerateStructure(address, args.configData);
        eovSettingsCommand = data.settingsCommandDefault;
        eovStateCommand = data.stateCommandDefault;
        setEovSettingsData([...eovSettingsData, data.setingsDataDefault]);
        setEovStateData([...eovStateData, data.stateDataOutputsDefault]);
        setEovOnlineData([...eovOnlineData, data.onlineDataDefault]);
      }
      */
      }
    );

    socket[e].on("mes-success", (args: string) => {
      toast.success(args);
      console.log("toast nitifi", args);
    });
    socket[e].on("mes-error", (args: string) => {
      toast.error(args);
      console.log("toast nitifi", args);
    });
    socket[e].on("mes-info", (args: string) => {
      toast.info(args);
      console.log("toast nitifi", args);
    });
    socket[e].on("mes-warning", (args: string) => {
      toast.warning(args);
      console.log("toast nitifi", args);
    });

    socket[e].on("log-data",(args: { level: string; message: string; data: object }) => {
        switch (args.level) {
          case "info":
            toast.success(args.message);
            break;
          case "warn":
            toast.warning(args.message);
            break;
          case "error":
            toast.error(args.message);
            break;
          default:
            toast.info(args.message);
        }
      }
    );

    socket[e].on("cmd-netstat-data", (args: string) => {
      const index = findIndexDeviceByID(socket[e].id);
      console.log("netstat-data", args);

      setOnlineDeviceData((onlineDeviceDataOrig): any => {
        var test = [...onlineDeviceDataOrig];
        test[index].netstat = args;
        return test;
      });
    });

    // data příkazu tail
    socket[e].on("cmd-tail-data", (args: ArrayBuffer) => {
      const index = findIndexDeviceByID(socket[e].id);
      //console.log("tail-data", args);
      const decoder = new TextDecoder("utf-8");

      function limitSize(str: string, maxdelka: number) {
        let splitted = str.split("\n");
        splitted = splitted.slice(Math.max(splitted.length - maxdelka, 0));
        return splitted.join("\n");
      }

      setOnlineDeviceData((dataOrig)=>{
        dataOrig[index].tail = limitSize(
          dataOrig[index].tail + decoder.decode(args),
          200
        );
        return [...dataOrig]
      })
    });
    socket[e].on("cmd-tail-state", (args: boolean) => {
      const index = findIndexDeviceByID(socket[e].id);
      console.log("tail-state", args);

      setOnlineDeviceData((onlineDeviceDataOrig): any => {
        var test = [...onlineDeviceDataOrig];
        test[index].tailState = args;
        return test;
      });
    });

    // data příkazu tcpdump
    socket[e].on("cmd-tcpdump-data", (args: ArrayBuffer) => {
      const index = findIndexDeviceByID(socket[e].id);
      //console.log("tail-data", args);

      const decoder = new TextDecoder("utf-8");

      function limitSize(str: string, maxdelka: number) {
        let splitted = str.split("\n");
        splitted = splitted.slice(Math.max(splitted.length - maxdelka, 0));
        return splitted.join("\n");
      }

      setOnlineDeviceData((dataOrig)=>{
        dataOrig[index].tcpdump = limitSize(
          dataOrig[index].tcpdump + decoder.decode(args),
          200
        );
        return [...dataOrig]
      })
    });
    socket[e].on("cmd-tcpdump-state", (args: boolean) => {
      const index = findIndexDeviceByID(socket[e].id);
      console.log("tcpdump-state", args);

      setOnlineDeviceData((onlineDeviceDataOrig): any => {
        var test = [...onlineDeviceDataOrig];
        test[index].tcpdumpState = args;
        return test;
      });
    });

    // vškerá data inku
    socket[e].on("InkData", (inkData: inkDataInterface) => {
      console.log("InkData", inkData);
      const index = findIndexDeviceByID(socket[e].id);

      //const newData = inkParseData(args[0], adress, globalState.setingsOutputs, globalState.stateDataOutputs);
      //console.log("nasrat data",newData);
      //setEovSettingsData(newData.setingsOutputs);
      //setEovStateData(newData.stateDataOutputs);

      // nastavení defaultních dat inku
      setInkDefaultData((InkDefaultDataOrig) => {
        var data = [...InkDefaultDataOrig];
        data[index] = inkData.defaultData;
        return data;
      });
      // nastavení item položek inku
      setInkItemData((InkItemDataOrig) => {
        var data = [...InkItemDataOrig];
        data[index] = inkData.item;
        return data;
      });
      // nastavení driver položek inku
      setInkDriverData((InkDriverDataOrig) => {
        var data = [...InkDriverDataOrig];
        data[index] = inkData.driver;
        return data;
      });
      // vytvoření pole událostí položek inku
      setInkEventData((InkEventDataOrig) => {
        var data = [...InkEventDataOrig];
        data[index] = [];
        return data;
      });
      // vytvoření mapování systémových proměných inku
      inkParseSystemData(inkData.item,index,setInkSytemDataMaping,deviceStoreData[index]?.inkSytemDataMaping);
    });

    interface changeDataInterface {
      id: number;
      value: number;
      quality: number;
      time: number;

      value_t?: number;
      quality_t?: number;
    }

    // změna položky item dat inku
    socket[e].on("InkData-change-item", (items: changeDataInterface[]) => {
      console.log("InkData-change-item", items);
      const index = findIndexDeviceByID(socket[e].id);

      //const newData = inkParseData([args.data], adress, globalState.setingsOutputs, globalState.stateDataOutputs);
      //console.log("edit ink data pokus", newData);
      //if(newData.settingsChange){
      //setEovSettingsData(newData.setingsOutputs);
      //}
      //if(newData.stateChange){
      //setEovStateData(newData.stateDataOutputs);
      //}

      // Provedení změny dat
      setInkItemData((InkItemDataOrig) => {
        var data = [...InkItemDataOrig];
        let itemstParse = [];
        for (let i = 0; items.length > i; i++) {
          data[index][items[i].id].value = items[i].value;
          data[index][items[i].id].quality = items[i].quality;
          data[index][items[i].id].time = items[i].time;

          if(items[i]?.value_t !== undefined){
            data[index][items[i].id].value_t = items[i].value_t;
          }

          if(items[i]?.quality_t !== undefined){
            data[index][items[i].id].quality_t = items[i].quality_t;
          }
          
          itemstParse.push(data[index][items[i].id]);
        }
        // Zaznamenání změny dat
        setInkEventData((dataOrig)=>{
          for (let i = 0; items.length > i; i++) {
            if (data[index][items[i].id].typ !== 7) {
              if (
                dataOrig[index].unshift({
                  id: items[i].id,
                  value: items[i].value,
                  time: items[i].time,
                }) > 200
              ) {
                dataOrig[index].pop();
              }
            }
          }
          return [...dataOrig];
        })
        // vytvoření mapování systémových proměných inku
        inkParseSystemData(itemstParse,index,setInkSytemDataMaping,deviceStoreData[index]?.inkSytemDataMaping);
      
        return data;
      });
      
    });

    socket[e].on("InkData-offline", () => {
      console.log("InkData-offline");
      const index = findIndexDeviceByID(socket[e].id);
      // nastavení špatné kvality všem položkám
      setInkItemData((InkItemDataOrig) => {
        var data = [...InkItemDataOrig];
        //data[index][0].value = 1;
        for (let i = 1; data[index].length > i; i++) {
          if (data[index][i]) {
            data[index][i].quality = 1;
          }
        }
        return data;
      });
    });

    socket[e].on("NotifiDataDefault", (...args: any) => {
      console.log("Info data default: ", args);
      setInfoData((infoDataOrig): any => {
        let adress =
          socket[e].io.opts.hostname + ":" + socket[e].io.opts.port;
        var test = [...infoDataOrig];
        for (let i = 0; i < test.length; i++) {
          console.log("adresa", test[i].DeviceAdress);
          if (test[i].DeviceAdress === adress) {
            test[i].data = args[0];
          }
        }
        return test;
      });
    });

    socket[e].on("NotifiData", (args: any) => {
      console.log("Info data: ", args);
      let newInfoData: infoDataInterface = args;
      setInfoData((infoDataOrig): any => {
        let adress =
          socket[e].io.opts.hostname + ":" + socket[e].io.opts.port;
        var test = [...infoDataOrig];
        for (let i = 0; i < test.length; i++) {
          console.log("adresa", test[i].DeviceAdress);
          if (test[i].DeviceAdress === adress) {
            test[i].data.push(newInfoData);
          }
        }
        return test;
      });
    });
  }

  return (
    <ConfigContext.Provider
      value={{
        onlineDeviceData,
        setOnlineDeviceData,
        addSocket,
        deleteSocket,
        eovSettingsData,
        infoData,
      }}
    >
      <DataContext.Provider value={{ eovOnlineData, eovStateData }}>
        <AppDataContext.Provider value={{ socketState, deviceData, appData, setAppData }}>
          <OnlineDeviceDataContext.Provider value={{ statisticsData }}>
            <InkDefaultDataContext.Provider value={inkDefaultData}>
              <InkItemDataContext.Provider value={inkItemData}>
                <InkEventDataContext.Provider
                  value={{ clearInkEventData, inkEventData }}
                >
                  {children}
                </InkEventDataContext.Provider>
              </InkItemDataContext.Provider>
            </InkDefaultDataContext.Provider>
          </OnlineDeviceDataContext.Provider>
        </AppDataContext.Provider>
      </DataContext.Provider>
    </ConfigContext.Provider>
  );
}
