import React, { useState } from 'react';
import style from './Device.module.css';
import Menu from './Menu';

import IconButton from '@mui/material/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

export default function Index({children}:React.PropsWithChildren<{}>) {
  const [state,setState] = useState(true);

  //setTimeout(zmensit, 10000);

  function zmensit(){
    var elementMenu = document.getElementById("device-menu");
    var elementBody = document.getElementById("device-device");

    if(state){
      //@ts-ignore;
      elementMenu.style.display = "none";
      //@ts-ignore;
      elementBody.style.width = "100%";
    }else{
      //@ts-ignore;
      elementMenu.style.display = "block";
      //@ts-ignore;
      elementBody.style.width = "calc(100% - 350px)";
    }

    setState(!state);
  }
  
  return (
    <div className={style.body}>
      {state ?
        <IconButton style={{ left: "410px" }} onClick={zmensit}>
          <ArrowLeftIcon /> 
        </IconButton>
      :
        <IconButton onClick={zmensit}>
          <ArrowRightIcon />
        </IconButton>
      }
      <div className={style.menu} id="device-menu">
        <Menu />
      </div>
      <div className={style.device} id="device-device">{children}</div>
    </div>
  );
}
