import React from 'react';
import Style from './Style.module.css';
import Menu from './Menu';

export default function index({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className={Style.ddts}>
      <div className={Style.Menu}>
        <Menu />
      </div>
      <div className={Style.Obsah}>{children}</div>
    </div>
  );
}
