import React from 'react';
import Style from './EOV_OV.module.css';
import Menu from './Menu';
import DeviceInfo from './DeviceInfo';

export default function index({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className={Style.EOV}>
      <div className={Style.DeviceInfo}>
        <DeviceInfo />
      </div>
      <div className={Style.Menu}>
        <div>
          <Menu />
        </div>
      </div>
      <div className={Style.Obsah}>{children}</div>
    </div>
  );
}