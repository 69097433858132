import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import InkData from "./components/InkData/InkData";

import PopupHandle from "./components/Auth/PopupHandle";

import Layout from "./Layout/index";
import Layout_Device from "./components/Device/index";
import Layout2 from "./components/DRT/index";
import Layout3 from "./components/DDTS/index";
import Layout4 from "./components/DDTS/components/EOV_OV/index";
import Prehled from "./components/DRT/Prehled";
import PrehledDDTS from "./components/DDTS/components/Prehled/Prehled";
import Device from "./components/Device/Device";
import EOV_OVoutputs from "./components/DDTS/components/EOV_OV/components/Outputs";
import EOV_OVcva from "./components/DDTS/components/EOV_OV/components/CVA";
import EOV_OVrozvadec from "./components/DDTS/components/EOV_OV/components/Rozvadec";
import Profile from "./components/Settings/Profile";
import Customize from "./components/Settings/Customize";
import SettingsLayout from "./components/Settings";
import { useTranslation } from 'react-i18next'
import ListUsers from "./components/Settings/ListUsers";

export default function Routing() {
  return (
    <React.Fragment>
      <Routes>
        <Route path="*" element={<RoutesWithLayout />} />
        <Route path="/popup-handle" element={<PopupHandle />} />
      </Routes>
    </React.Fragment>
  );
}

function SettingsRoutes() {
  const { i18n, t } = useTranslation();

  return (
    <SettingsLayout>
      <Routes>
        <Route path="customize" element={<Customize />} />
        <Route path="notifications" element={<>{t("notifications")}</>} />
        <Route path="profile" element={<Profile />} />
        <Route path="users" element={<ListUsers />} />
      </Routes>
    </SettingsLayout>
  );
}

function RoutesWithLayout() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/device/*" element={<DeviceRoutes />} />
        <Route path="/data" element={<InkData />} />
        <Route
          path="/DRT/prehled"
          element={
            <Layout2>
              <Prehled />
            </Layout2>
          }
        />
        <Route path="/DDTS" element={<PrehledDDTS />} />
        <Route path="/DDTS/*" element={<DDTSRoutes />} />
        <Route path="/settings/*" element={<SettingsRoutes />} />
      </Routes>
    </Layout>
  );
}

function DeviceRoutes() {
  return (
    <Layout_Device>
      <Routes>
        <Route path=":index" element={<Device />} />
      </Routes>
    </Layout_Device>
  );
}

function DDTSRoutes() {
  return (
    <Layout3>
      <Routes>
        <Route
          path="/DDTS/EOV_OV/:id"
          element={
            <Layout4>
              <Routes>
                <Route path="/DDTS/EOV_OV/:id" element={<EOV_OVrozvadec />} />
                <Route path="/DDTS/EOV_OV/:id/CVA" element={<EOV_OVcva />} />
                <Route
                  path="/DDTS/EOV_OV/:id/outputs"
                  element={<EOV_OVoutputs />}
                />
              </Routes>
            </Layout4>
          }
        />
      </Routes>
    </Layout3>
  );
}
