import React from 'react';
import Style from '../EOV_OV.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Modal } from '@mui/material';
import {socket} from '../../../../../WebSocket/WebSocket';

export default function Command({ data, setCommand }: any) {

  function checkAdult(age: any) {
    return age.io.engine.hostname + ":" + age.io.engine.port === data.adress;
  }
  const IndexDevice = socket.findIndex(checkAdult);

  const handleClose = () => {
    setCommand({...data, state:false});
  };

  function On() {
    const sendData = {
      command: data.command,
      index: data.index,
      set: true,
    };
    socket[IndexDevice].emit("command", sendData);
    handleClose();
  }

  function Off() {
    const sendData = {
      command: data.command,
      index: data.index,
      set: false,
    };
    socket[IndexDevice].emit("command", sendData);
    handleClose();
  }

  return(
    <Modal
      open={data.state}
      onClose={handleClose}
      >
      <div className={Style.Modal}>
        <div className={Style.Modal_Header}>
          {data.id} {data.name}
          <IconButton color="primary" aria-label="upload picture" onClick={handleClose} component="span">
            <CloseIcon />
          </IconButton>
        </div>
        <div className={Style.Modal_body_command}>
          <h3>
          {data.command === "output-state" ? "Ručně" : "Automatikou"} <br></br>
          </h3>
          <Button variant="contained" color="secondary" onClick={On}>
            Zapnout
          </Button>
          <Button variant="contained" color="secondary" onClick={Off}>
            Vypnout
          </Button>
        </div>
      </div>
    </Modal>
  )
}
