import { create } from "zustand";
import { persist } from "zustand/middleware";
import { systemDataMapingInterface } from "../types/INK";

export interface deviceStoreInterface {
	inkSytemDataMaping: systemDataMapingInterface;
}

export interface TypeVariableStore {
	device: deviceStoreInterface[],
	setInkSytemDataMaping: (inkSytemDataMaping:systemDataMapingInterface, deviceIndex:number) => void;
}

export const useTypeVariableStore = create<TypeVariableStore>()(
	set => ({
		device: [],
		setInkSytemDataMaping(inkSytemDataMaping, deviceIndex) {
			set((orgData)=>{
				if(orgData.device[deviceIndex]?.inkSytemDataMaping){
					orgData.device[deviceIndex].inkSytemDataMaping = inkSytemDataMaping;
				}else{
					orgData.device[deviceIndex] = {
						inkSytemDataMaping 
					}
				}
				return {...orgData}
			})
		}
	})
);