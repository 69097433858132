import React,{ useEffect } from 'react'
import { toast } from 'react-toastify';

export default function PopupHandle() {

  useEffect(() => {
    //@ts-ignore
    let params = (new URL(document.location)).searchParams;
    let token = params.get('token');
    let id = params.get('id');

    window.localStorage.setItem("user-id", id as string );
    window.localStorage.setItem("token", token as string);

    console.log("token",token)

    window.opener.postMessage(JSON.stringify({"reload":true}),window.location.origin)
    toast.success("Login Successfull");

    setTimeout(() => {
      window.close();
    },1000)
  },[]);


  return (
    <div>PopupHandle</div>
  )
}