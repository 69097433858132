import React, {useContext} from 'react';
import {ConfigContext, DataContext, AppDataContext} from '../../../../WebSocket/WebSocket';
import { useParams } from "react-router-dom";
import {findIndexDeviceByAdress} from '../../../../utils';

export default function DeviceInfo() {
  const ws = useContext(AppDataContext);
  const online = useContext(DataContext);

  let { id } = useParams<{ id: string }>();
  let adress = id;

  const index = findIndexDeviceByAdress(adress);
 
  return(
    <React.Fragment>
     <div>
        <span> <strong>{ws.deviceData[index]?.settings.hostName}</strong> </span>
      </div>
      <div>
        <span>{online.eovOnlineData[index].Global.time} - {online.eovOnlineData[index].Global.date} <strong>{ws.deviceData[index].auth}</strong> </span>
      </div>
    </React.Fragment>
  )
}