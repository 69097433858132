import {inkDataInterface, inkDataItemInterface, systemDataMapingInterface} from '../types/INK';
import {setingsOutputsInterface, stateDataOutputsInterface, eovSettingsCommandInterface, eovStateCommandInterface} from '../types/EOV_OV';
import {eovSettingsCommand, eovStateCommand} from './WebSocket';
import { TypeVariableStore, deviceStoreInterface, useTypeVariableStore } from './data';

export function inkParseData(
    InkData:inkDataItemInterface[],
    wsAddress:string,
    setingsOutputs:setingsOutputsInterface[],
    stateDataOutputs:stateDataOutputsInterface[]
  ){

  function findAddress(age: any) {
    return age.DeviceAdress === wsAddress;
  }
  const deviceIndexEovSettings = setingsOutputs.findIndex(findAddress);
  const deviceIndexEOVState = stateDataOutputs.findIndex(findAddress);
  const deviceIndexEovSettingsCommand = eovSettingsCommand.findIndex(findAddress);

  let settingsChange = false;
  let stateChange = false;

  for(let i = 0; InkData.length > i; i++){
    if(InkData[i].typeVariable && InkData[i].typ <= 20){
      parseEovSignalData(i);
    }
    else if(InkData[i].typeVariable && InkData[i].typ > 20){
      parseEovCommandData(i);
    }
  }
      
  function parseEovSignalData(index:number) {
  //@ts-ignore
  if(InkData[index].typeVariable >= 100 && InkData[index].typeVariable <= 125){
    settingsChange = true;
  }

  //@ts-ignore
  if(InkData[index].typeVariable >= 126 && InkData[index].typeVariable <= 128){
    stateChange = true;
  }

  //@ts-ignore
  if(InkData[index].typeVariable >= 130 && InkData[index].typeVariable <= 136){
    settingsChange = true;
  }

  //@ts-ignore
  if(InkData[index].typeVariable >= 141 && InkData[index].typeVariable <= 142){
    stateChange = true;
  }

  //@ts-ignore
  if(InkData[index].typeVariable >= 143 && InkData[index].typeVariable <= 144){
    settingsChange = true;
  }

    if(InkData[index].typeVariable === 100){ setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].name = InkData[index].value; }
    else if(InkData[index].typeVariable === 101) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].typ.typ = InkData[index].value; }
    else if(InkData[index].typeVariable === 102) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].typ.phase = InkData[index].value;}
    else if(InkData[index].typeVariable === 103) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].typ.his = InkData[index].value; }
    else if(InkData[index].typeVariable === 104) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].typ.switch = InkData[index].value; }
    else if(InkData[index].typeVariable === 105) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].manual = InkData[index].value; }
    else if(InkData[index].typeVariable === 106) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].binInput.state = InkData[index].value; }
    else if(InkData[index].typeVariable === 107) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].binInput.Card = InkData[index].value; }
    else if(InkData[index].typeVariable === 108) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].binInput.input = InkData[index].value; }
    else if(InkData[index].typeVariable === 109) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].binOutput.Card = InkData[index].value; }
    else if(InkData[index].typeVariable === 110) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].binOutput.output = InkData[index].value; }
    else if(InkData[index].typeVariable === 111) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].workingGroup = InkData[index].value; }
//      else if(InkData[index].typeVariable === 112) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].mask[InkData[index].B].state = InkData[index].value; }
//      else if(InkData[index].typeVariable === 113) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].QHM[InkData[index].B].state = InkData[index].value; }
    else if(InkData[index].typeVariable === 114) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.interval = InkData[index].value; }
    else if(InkData[index].typeVariable === 115) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.calendar = InkData[index].value; }
    else if(InkData[index].typeVariable === 116) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.sensor.state = InkData[index].value; }
    else if(InkData[index].typeVariable === 117) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.sensor.limit = InkData[index].value; }
    else if(InkData[index].typeVariable === 118) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.sensor.hystereze = InkData[index].value; }
    else if(InkData[index].typeVariable === 119) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].limitAutoOff.state = InkData[index].value; }
    else if(InkData[index].typeVariable === 120) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].limitAutoOff.limit = InkData[index].value; }
    else if(InkData[index].typeVariable === 121) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.intervals[InkData[index].B].state = InkData[index].value; }
    else if(InkData[index].typeVariable === 122) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.intervals[InkData[index].B].name = InkData[index].value; }
    else if(InkData[index].typeVariable === 123) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.intervals[InkData[index].B].start = InkData[index].value; }
    else if(InkData[index].typeVariable === 124) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.intervals[InkData[index].B].end = InkData[index].value; }
//      else if(InkData[index].typeVariable === 125) { setingsOutputs[deviceIndexEovSettings].Outputs[InkData[index].A].auto.intervals[InkData[index].B].useDay[InkData[index].C].state = InkData[index].value; }
    else if(InkData[index].typeVariable === 126) { stateDataOutputs[deviceIndexEOVState].Outputs[InkData[index].A].state = InkData[index].value; 
    console.log("stav zapsán", stateDataOutputs[deviceIndexEOVState].Outputs[InkData[index].A].state)
    }
    else if(InkData[index].typeVariable === 127) { stateDataOutputs[deviceIndexEOVState].Outputs[InkData[index].A].manual = InkData[index].value;  }
    else if(InkData[index].typeVariable === 128) { stateDataOutputs[deviceIndexEOVState].Outputs[InkData[index].A].auto = InkData[index].value; }
    else if(InkData[index].typeVariable === 129) { }
    else if(InkData[index].typeVariable === 130) { setingsOutputs[deviceIndexEovSettings].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].output = InkData[index].value; }
    else if(InkData[index].typeVariable === 131) { setingsOutputs[deviceIndexEovSettings].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].hystereze = InkData[index].value; }
    else if(InkData[index].typeVariable === 132) { setingsOutputs[deviceIndexEovSettings].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].limit = InkData[index].value; }
    else if(InkData[index].typeVariable === 133) { setingsOutputs[deviceIndexEovSettings].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].phase = InkData[index].value; }
    else if(InkData[index].typeVariable === 134) { setingsOutputs[deviceIndexEovSettings].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].comparation = InkData[index].value; }
    else if(InkData[index].typeVariable === 135) { setingsOutputs[deviceIndexEovSettings].CVA[InkData[index].A].setingsVoltage[InkData[index].B].hystereze = InkData[index].value; }
    else if(InkData[index].typeVariable === 136) { setingsOutputs[deviceIndexEovSettings].CVA[InkData[index].A].setingsVoltage[InkData[index].B].limit = InkData[index].value; }
    else if(InkData[index].typeVariable === 137) { }
    else if(InkData[index].typeVariable === 138) { }
    else if(InkData[index].typeVariable === 139) { }
    else if(InkData[index].typeVariable === 140) { }
    else if(InkData[index].typeVariable === 141) { stateDataOutputs[deviceIndexEOVState].Global.mask[InkData[index].A] = InkData[index].value; }
    else if(InkData[index].typeVariable === 142) { stateDataOutputs[deviceIndexEOVState].Global.openDoor = InkData[index].value; }
    else if(InkData[index].typeVariable === 143) { setingsOutputs[deviceIndexEovSettings].Global.turnout = InkData[index].value; }
    else if(InkData[index].typeVariable === 144) { setingsOutputs[deviceIndexEovSettings].Global.lighting = InkData[index].value; }
    else if(InkData[index].typeVariable === 145) { }
    else if(InkData[index].typeVariable === 146) { }
    else if(InkData[index].typeVariable === 147) { }
    else if(InkData[index].typeVariable === 148) { }
    else if(InkData[index].typeVariable === 149) { }
    else if(InkData[index].typeVariable === 150) { }
    else if(InkData[index].typeVariable === 151) { }
    else if(InkData[index].typeVariable === 152) { }
    else if(InkData[index].typeVariable === 153) { }
    else if(InkData[index].typeVariable === 154) { }
     
    else if(InkData[index].typeVariable === 1) { setingsOutputs[deviceIndexEovSettings].Global.name = InkData[index].value; }
    else if(InkData[index].typeVariable === 2) {}
    else if(InkData[index].typeVariable === 3) {}
    else if(InkData[index].typeVariable === 4) { stateDataOutputs[deviceIndexEOVState].Global.QHM = InkData[index].value; }
    else if(InkData[index].typeVariable === 5) { }
   
    else {
      console.log("Neznámá data ", InkData[index]);
    }  
  }

  function parseEovCommandData(index:number) {
    if(     InkData[index].typeVariable === 100) {// eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].name = InkData[index].index 
    }
    else if(InkData[index].typeVariable === 101) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].typ.typ = InkData[index].id }
    else if(InkData[index].typeVariable === 102) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].typ.phase = InkData[index].id}
    else if(InkData[index].typeVariable === 103) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].typ.his = InkData[index].id }
    else if(InkData[index].typeVariable === 104) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].typ.switch = InkData[index].id }
    else if(InkData[index].typeVariable === 105) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].manual = InkData[index].id }
    else if(InkData[index].typeVariable === 106) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].binInput.state = InkData[index].id }
    else if(InkData[index].typeVariable === 107) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].binInput.Card = InkData[index].id }
    else if(InkData[index].typeVariable === 108) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].binInput.input = InkData[index].id }
    else if(InkData[index].typeVariable === 109) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].binOutput.Card = InkData[index].id }
    else if(InkData[index].typeVariable === 110) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].binOutput.output = InkData[index].id }
    else if(InkData[index].typeVariable === 111) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].workingGroup = InkData[index].id }
//      else if(InkData[index].typeVariable === 112) {  eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].mask[InkData[index].B].state = InkData[index].index }
//      else if(InkData[index].typeVariable === 113) {  eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].QHM[InkData[index].B].state = InkData[index].index }
    else if(InkData[index].typeVariable === 114) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.interval = InkData[index].id }
    else if(InkData[index].typeVariable === 115) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.calendar = InkData[index].id }
    else if(InkData[index].typeVariable === 116) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.sensor.state = InkData[index].id }
    else if(InkData[index].typeVariable === 117) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.sensor.limit = InkData[index].id }
    else if(InkData[index].typeVariable === 118) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.sensor.hystereze = InkData[index].id }
    else if(InkData[index].typeVariable === 119) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].limitAutoOff.state = InkData[index].id }
    else if(InkData[index].typeVariable === 120) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].limitAutoOff.limit = InkData[index].id }
    else if(InkData[index].typeVariable === 121) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.intervals[InkData[index].B].state = InkData[index].id }
    else if(InkData[index].typeVariable === 122) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.intervals[InkData[index].B].name = InkData[index].id }
    else if(InkData[index].typeVariable === 123) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.intervals[InkData[index].B].start = InkData[index].id }
    else if(InkData[index].typeVariable === 124) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.intervals[InkData[index].B].end = InkData[index].id }
    else if(InkData[index].typeVariable === 125) { eovSettingsCommand[deviceIndexEovSettingsCommand].Outputs[InkData[index].A].auto.intervals[InkData[index].B].useDay = InkData[index].id }
    else if(InkData[index].typeVariable === 126) { eovStateCommand[deviceIndexEOVState].Outputs[InkData[index].A].state = InkData[index].id }
    else if(InkData[index].typeVariable === 127) { }
    else if(InkData[index].typeVariable === 128) { eovStateCommand[deviceIndexEOVState].Outputs[InkData[index].A].auto = InkData[index].id }
    else if(InkData[index].typeVariable === 129) { }
//    else if(InkData[index].typeVariable === 130) {  eovSettingsCommand[deviceIndexEovSettingsCommand].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].output = InkData[index].index }
//    else if(InkData[index].typeVariable === 131) {  eovSettingsCommand[deviceIndexEovSettingsCommand].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].hystereze = InkData[index].index }
//    else if(InkData[index].typeVariable === 132) {  eovSettingsCommand[deviceIndexEovSettingsCommand].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].limit = InkData[index].index }
//    else if(InkData[index].typeVariable === 133) {  eovSettingsCommand[deviceIndexEovSettingsCommand].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].phase = InkData[index].index }
//    else if(InkData[index].typeVariable === 134) {  eovSettingsCommand[deviceIndexEovSettingsCommand].CVA[InkData[index].A].setingsMeasurement[InkData[index].B].comparation = InkData[index].index }
//    else if(InkData[index].typeVariable === 135) {  eovSettingsCommand[deviceIndexEovSettingsCommand].CVA[InkData[index].A].setingsVoltage[InkData[index].B].hystereze = InkData[index].index }
//    else if(InkData[index].typeVariable === 136) {  eovSettingsCommand[deviceIndexEovSettingsCommand].CVA[InkData[index].A].setingsVoltage[InkData[index].B].limit = InkData[index].index }
    else if(InkData[index].typeVariable === 137) { }
    else if(InkData[index].typeVariable === 138) { }
    else if(InkData[index].typeVariable === 139) { }
    else if(InkData[index].typeVariable === 140) { }
    else if(InkData[index].typeVariable === 141) { }
    else if(InkData[index].typeVariable === 142) { }
    else if(InkData[index].typeVariable === 143) { eovSettingsCommand[deviceIndexEovSettingsCommand].Global.turnout = InkData[index].id }
    else if(InkData[index].typeVariable === 144) { eovSettingsCommand[deviceIndexEovSettingsCommand].Global.lighting = InkData[index].id }
    else if(InkData[index].typeVariable === 145) { }
    else if(InkData[index].typeVariable === 146) { }
    else if(InkData[index].typeVariable === 147) { }
    else if(InkData[index].typeVariable === 148) { }
    else if(InkData[index].typeVariable === 149) { }
    else if(InkData[index].typeVariable === 150) { }
    else if(InkData[index].typeVariable === 151) { }
    else if(InkData[index].typeVariable === 152) { }
    else if(InkData[index].typeVariable === 153) { }
    else if(InkData[index].typeVariable === 154) { }
     
    else if(InkData[index].typeVariable === 1) { eovSettingsCommand[deviceIndexEovSettingsCommand].Global.name = InkData[index].id }
    else if(InkData[index].typeVariable === 2) {}
    else if(InkData[index].typeVariable === 3) {}
    else if(InkData[index].typeVariable === 4) { stateDataOutputs[deviceIndexEOVState].Global.QHM = InkData[index].id }
    else if(InkData[index].typeVariable === 5) { }
   
    else {
      console.log("Neznámá data ", InkData[index]);
    }
  }

  return {
    stateDataOutputs, stateChange,
    setingsOutputs, settingsChange
  };
}


// Mapování dat na systémové stavy
export function inkParseSystemData(
  InkData: inkDataItemInterface[],
  deviceIndex: number,
  
  setInkSytemDataMaping: (inkSytemDataMaping: systemDataMapingInterface, deviceIndex: number) => void,

  inkSytemDataMaping?: systemDataMapingInterface
){

  let dataObject:systemDataMapingInterface = inkSytemDataMaping || {
    ink_test: null
  };

  for(let i = 0; InkData.length > i; i++){
    if(InkData[i]?.typeVariable !== undefined){
      switch(InkData[i].typeVariable){
        case 110: // Režim test v inku
        dataObject.ink_test = InkData[i].value;
          
          break;
      }
    }
  }

  console.log("INKSystemData",dataObject);

  setInkSytemDataMaping(dataObject,deviceIndex);
}