import React from 'react';
import Style from './Prehled.module.css';
import Button from '@mui/material/Button';

export default function Prehled(){
    const [menu, setMenu] = React.useState("Přehled")

    var x = window.location.pathname;
    var splitted = x.split("/", 4);

    function klik(){
        console.log(x);
        console.log(splitted[3])
    }

    return(
      <div className={Style.Prehled}>
        <div className={Style.Menu}>
            <Menu setMenu={setMenu} />
        </div>
        <div className={Style.Obsah}>
            {menu === "Přehled" ? ( <Prehled1 /> ):("") }      
            {menu === "EOV" ? ( <EOV /> ):("") } 
            {menu === "OV" ? ( <OV /> ):("") }          
            {menu === "EPZ" ? ( <EPZ /> ):("") } 
            {menu === "RH" ? ( <RH /> ):("") }
            {menu === "ELM" ? ( <ELM /> ):("") }  
        </div>
      </div>
    );
}


function Menu({setMenu}:any) {

    function NastavMenu(e: any) {
       let id = e.target.parentElement.getAttribute('id');
       setMenu(id);
    }

    return(
        <React.Fragment>
            <Button variant="contained" color="secondary" id={"Přehled"} onClick={NastavMenu}>
                Přehled
            </Button>
            <Button variant="contained" color="secondary" id={"EOV"} onClick={NastavMenu}>
                EOV
            </Button>
            <Button variant="contained" color="secondary" id={"OV"} onClick={NastavMenu}>
                OV
            </Button>
            <Button variant="contained" color="secondary" id={"EPZ"} onClick={NastavMenu}>
                EPZ
            </Button>
            <Button variant="contained" color="secondary" id={"RH"} onClick={NastavMenu}>
                RH
            </Button>
            <Button variant="contained" color="secondary" id={"ELM"} onClick={NastavMenu}>
                ELM
            </Button>
        </React.Fragment>
    )
}

function Prehled1() {

    return(
        <React.Fragment>
            Přehled           
        </React.Fragment>
    )
}

function EOV() {

    return(
        <React.Fragment>
            EOV          
        </React.Fragment>
    )
}

function OV() {

    return(
        <React.Fragment>
            OV            
        </React.Fragment>
    )
}

function EPZ() {

    return(
        <React.Fragment>
            EPZ          
        </React.Fragment>
    )
}

function RH () {

    return(
        <React.Fragment>
            RH             
        </React.Fragment>
    )
}

function ELM () {

    return(
        <React.Fragment>
            ELM            
        </React.Fragment>
    )
}