import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNetwork } from './utils';

export default function InfoComponent() {
  // const [reloadShow, setReloadShow] = useState(false);
  const isOnline = useNetwork();
  const { t } = useTranslation();

  // useEffect(() => {
  //   const bc = typeof BroadcastChannel !== "undefined" ? new BroadcastChannel("internal_notification") : "";
  //   if (bc) {
  //     bc.onmessage = () => setReloadShow(true);
  //     return () => {
  //       bc.close();
  //     };
  //   }
  // }, []);

  return !isOnline ? (
    <div style={{ position: "fixed", bottom: 0, left: 0, width: "100%", padding: 19, background: "var(--tmave-seda)", zIndex: 10, textAlign: "center",color:"white" }}>
      Není dostupné připojení k internetu. Webvizu běží v offline režimu.
    </div>
  ) : null;
}