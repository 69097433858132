import React from 'react';


export default function Prehled() {

  return(
    <div>
      něco
    </div>
  );
    
}