import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {AppDataContext} from '../../WebSocket/WebSocket';
import { toast } from "react-toastify";
import {
  UpdateUserMutation
} from '../../types';

const Mutation_UpdateUser = loader('../../graphql/UpdateUser.graphql');

export default function SaveButton(){
	const { t } = useTranslation();
	const [login, { loading, error }] = useMutation<UpdateUserMutation>(Mutation_UpdateUser);
	const { appData } = useContext(AppDataContext);

	function save(){
		const user = appData.user
		login({variables:{
			 fname: user.fname,
			 lname: user.lname,
			 email: user.email,
			 image: user.image, 
			 id: user.id,
			 settings: user.settings, 
			 newsletter: user.newsletter
			}
		}).then(()=>{
			toast.success("Uživatelské nastavení uloženo");
		})
	}

  return(
		<Button
			disabled={loading}
			variant="contained"
			onClick={save}
		>
      {t('save')}
    </Button>
	);
}