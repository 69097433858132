import React, { useState, useContext } from "react";
import style from "../style.module.css";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';

import { toNumber } from 'lodash';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DevicesIcon from '@mui/icons-material/Devices';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';

import {ConfigContext, contextInterface, AppDataContext, InkEventDataContext, socket} from '../../../WebSocket/WebSocket';
import {electionInterface} from '../InkData'
import { useTypeVariableStore } from "../../../WebSocket/data";


export default function NavBar({election,setElection}:{election:electionInterface,setElection:React.Dispatch<React.SetStateAction<electionInterface>>}){
  const [inkSytemDataMaping] = useTypeVariableStore((state) => [state.device[election.deviceIndex]?.inkSytemDataMaping]);
  const ws = useContext(AppDataContext);
  const event = useContext(InkEventDataContext);

  const inputStyle = {
    borderRadius: "0", 
  }

  function setData(event: SelectChangeEvent){
    setElection({...election, [event.target.name]: toNumber(event.target.value)})
  }

  // Povelování simulace
  function comandMode(a:number){
    socket[election.deviceIndex].emit("command", {
      messageID: 4,
      requestID: 4,
      messageType: "mode",
      data:[
        { id: 1, value: a }
      ]
    });
  }
  
  return(
    <div className={style.navBar}>
      <DevicesIcon />
      <FormControl size="small" >
        <InputLabel id="inkdata-device-select-label">Zařízení</InputLabel>
        <Select
          labelId="inkdata-device-select-label"
          id="inkdata-device-select"
          value={ws.deviceData.length > 0 ? election.deviceIndex.toString() : ""}
          name="deviceIndex"
          label="Zařízení"
          style={inputStyle}
          onChange={setData}
         >
          {ws.deviceData.map(({settings},index:number)=>{
            return(
              <MenuItem key={index} value={index}>{settings.hostName}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
  
      <GridViewOutlinedIcon />
      <FormControl  size="small" >
        <InputLabel id="inkdata-view-select-label">Zobrazení</InputLabel>
        <Select
          labelId="inkdata-view-select-label"
          id="inkdata-view-select"
          value={election.display.toString()}
          name="display"
          label="Zobrazení"
          style={inputStyle}
          onChange={setData}
        >
          <MenuItem value={0}>Online data</MenuItem>
          <MenuItem value={1}>Vybraná data</MenuItem>
          <MenuItem value={2}>Události</MenuItem>
          <MenuItem value={3}>Drivery</MenuItem>
        </Select>
      </FormControl>
  
      <AccessTimeIcon />
      <FormControl  size="small" >
        <InputLabel id="inkdata-time-select-label">Formát času</InputLabel>
        <Select
          labelId="inkdata-time-select-label"
          id="inkdata-time-select"
          value={election.time.toString()}
          name="time"
          label="Formát času"
          style={inputStyle}
          onChange={setData}
        >
          <MenuItem value={0}>Bez formátu</MenuItem>
          <MenuItem value={1}>Místní</MenuItem>
          <MenuItem value={2}>UTC</MenuItem>
        </Select>
      </FormControl>

      {election.display === 2 ?
        <Button 
          variant="contained"
          style={{position:"absolute",right:"15px"}}
          sx={{borderRadius:"0px"}}
          onClick={()=>{event.clearInkEventData(election.deviceIndex)}}
        >
          Vymazat události
        </Button>
        : ""
      }

      {inkSytemDataMaping?.ink_test !== null &&
        <React.Fragment>
          {inkSytemDataMaping?.ink_test === 1 ?
            <Button 
              variant="contained"
              style={{position:"absolute",right:"15px"}}
              sx={{borderRadius:"0px"}}
              onClick={()=>{comandMode(0)}}
            >
              Vypnout simulaci
            </Button>
          :
            <Button 
              variant="contained"
              style={{position:"absolute",right:"15px"}}
              sx={{borderRadius:"0px"}}
              onClick={()=>{comandMode(1)}}
            >
              Zapnout simulaci
            </Button>
          }
        </React.Fragment>
      }


      
    </div>
  );
}
  