import React from 'react';
import style from './style.module.css';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useTranslation } from 'react-i18next';

export default function Menu() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <NavLink to={"/settings/customize"} className={({ isActive }) => (isActive ? style.isActive : undefined)}>
        <Button variant="contained" color="secondary" startIcon={<EditIcon />}>
          {t('settings.customize')}
        </Button>
      </NavLink>
      <NavLink to={'/settings/notifications'} className={({ isActive }) => (isActive ? style.isActive : undefined)}>
        <Button variant="contained" color="secondary" startIcon={<NotificationsActiveIcon />}>
          {t('settings.notifications')}
        </Button>
      </NavLink>
      <NavLink to={'/settings/profile'} className={({ isActive }) => (isActive ? style.isActive : undefined)}>
        <Button variant="contained" color="secondary" startIcon={<PersonIcon />}>
          {t('settings.profile')}
        </Button>
      </NavLink>
      <NavLink to={'/settings/users'} className={({ isActive }) => (isActive ? style.isActive : undefined)}>
        <Button variant="contained" color="secondary" startIcon={<><PersonIcon /><PersonIcon /></>}>
          {t('settings.users')}
        </Button>
      </NavLink>
    </React.Fragment>
  );
}
