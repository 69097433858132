import React, {useState, useContext} from 'react';
import style from './Style.module.css';
import AuthModul from '../components/Auth/AuthModul';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import {ConfigContext} from '../WebSocket/WebSocket';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


export default function NavBar(){
  const ws = useContext(ConfigContext);
  const [elementMenu, setElementMenu] = useState(null);
  const { i18n, t } = useTranslation();

  const handleChange = (event:any) => {
    //setAge(event.target.value);
    i18n.changeLanguage(event.target.value);
    //localStorage.setItem('language', event.target.value);
  };

  const NotificationListOpen = (event:any) => {
    setElementMenu(event.currentTarget);
  };

  const NotificationListOpenClose = () => {
    setElementMenu(null);
  }
  
  const pocet = ws.infoData.length;
  //console.log(ws.infoData);

  return(
    <div className={style.NavBar}>
      <Link to="/" className={style.Logo}> Web Vizu </Link>
      <div>
        
      </div>
      <div className={style.Notification}>
        <Select
          id="demo-controlled-open-select"
          value={localStorage.getItem('i18nextLng')}
          onChange={handleChange}
          className={style.language}
          sx={{ 
            fieldset:{border:"none",outline:"none"}
          }}
        >
          <MenuItem value={"cs-CZ"}>{t('czech')}</MenuItem>
          <MenuItem value={"en"}>{t('english')}</MenuItem>
        </Select>
        <IconButton aria-label="Notifications" onClick={NotificationListOpen} >
          <Badge badgeContent={pocet} color="primary">
            <NotificationsIcon/>
          </Badge>
        </IconButton>
        {/*
        <Menu
          id="notifi-list"
          className={style.Notifi}
          anchorEl={elementMenu}
          open={Boolean(elementMenu)}
          onClose={NotificationListOpenClose}
        >
        {ws.infoData[0]?.data?.map(({ name, type, link, time, content, effect }:infoDataInterface, index:number) => {
          return(
          <MenuItem key={index} onClick={NotificationListOpenClose}>
            <div className={style.NotifiHeader} style={{backgroundColor: (type === "danger" ? "DarkOrange": "") + (type === "warning" ? "Gold": "") + (type === "error" ? "red": "")}}>
              <span>{name}</span>
              <span style={{ float: 'right' }} >{time}</span>
            </div>
            <div className={style.NotifiBody}>
              {content}
            </div>
          </MenuItem>
          )
        })}     
        </Menu>
      */}
      </div>
      <div className={style.Login}>
        <AuthModul />
      </div>
    </div>
  );
}

