import React, { useState, useContext, useEffect } from "react";
import Modal from '@mui/material/Modal';
import Style from "../InkData.module.css";
import style from "../style.module.css";
import {ConfigContext, socket, InkItemDataContext, InkDefaultDataContext} from '../../../WebSocket/WebSocket';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {filterTypeData} from '../InkData';
import {inkDataItemInterface} from '../../../types/INK';

import ProtokolText from './ProtocolText.json';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CachedIcon from '@mui/icons-material/Cached';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTypeVariableStore } from "../../../WebSocket/data";


export interface clickDataInterface {
  open: boolean,
  index: number,
  deviceIndex: number
}

export default function ModalData({dataModal, setModal}:{dataModal:clickDataInterface, setModal:React.Dispatch<React.SetStateAction<clickDataInterface>>}){
  const [acordionState,setAcordionState] = useState<string | false>("panel1");

  const itemData = useContext(InkItemDataContext);
  const defaultData = useContext(InkDefaultDataContext);

  function close(){
    setModal({...dataModal, open: false});
  }
  const setAcordion = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setAcordionState(isExpanded ? panel : false);
  }

  const item = itemData[dataModal.deviceIndex][dataModal.index];
  const graphics = defaultData[dataModal.deviceIndex]?.graphics;

  function type(a:number){
    switch(a){
      // signály
      case 1:
        return "SB - 1 bitová informace poslaná hned";
      case 2: 
        return "TON - Zpožděné zapnutí";
      case 3:
        return "TOF - Zpožděné vypnutí";
      case 4:
        return "IMP - 1 impulz";
      case 5:
        return "IMP+OR - Impulz s případným protažením impulzu";
      case 6:
        return "DB - 2 bitová informace (4 stavy)";
      case 7:
        return "RL - Float 4 byte";
      case 8:
        return "DW - 4 bytové celé číslo bez znaménka";
      case 9:
        return "SN - 2 bytové celé číslo se znaménkem";

      // povely
      case 21:
        return "SB - 1 bitový povel"
      case 26:
        return "DB - 2 bitový povel"
      case 27:
        return "RL - 4 bitový float"
      case 28:
        return "DW - 4 bytové celé číslo bez znaménka"
      case 29:
        return "SN - 2 byte číslo se znaménkem"
    }

    return "Neznámý"
  }

  function stateName(){
    if(graphics[item.style]?.item){
      if(graphics[item.style]?.item[item.value]?.name){
        return graphics[item.style]?.item[item.value]?.name
      }
    }else{
      return "nepřiřazen"
    }
  }

  return(
    <Modal
      open={dataModal.open}
      onClose={close}
    >
      <div className={Style.Modal}>
        <div className={Style.Modal_Header}>
          {item.desc}
          <Tooltip title="Zavřít" placement="top" arrow>
            <IconButton component="span" onClick={close}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={Style.Modal_body}>
          <table>
            <tr>
              <th>Název:</th>
              <td>{item.desc}</td>
            </tr>
            <tr>
              <th>Typ:</th>
              <td>{type(item.typ)}</td>
            </tr>
            <tr>
              <th>Index:</th>
              <td>{dataModal.index}</td>
            </tr>
            {item.typ > 0 && item.typ < 20 ?
              <React.Fragment>
                <tr>
                  <th>Hodnota:</th>
                  <td>{item.value}</td>
                </tr>
                <tr>
                  <th>Hodnota simulace:</th>
                  <td>{item?.value_t}</td>
                </tr>
                <tr>
                  <th>Stav:</th>
                  <td>{stateName()}</td>
                </tr>
              </React.Fragment>
            :""}
          </table>

          {item.point?.map((number:number)=>{
            const data = itemData[dataModal.deviceIndex][number]
            if(data.typ  > 20 && data.typ < 30){
              return <Command data={data} deviceIndex={dataModal.deviceIndex} graphics={graphics} />
            }
          })}

          {item.typ > 0 && item.typ < 30 ? <Command data={item} deviceIndex={dataModal.deviceIndex} graphics={graphics} /> : ""}

          <Accordion expanded={acordionState === "panel1"} onChange={setAcordion("panel1")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={style.accordionSummary}
            >
              <Typography>Souvysející položky</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {item.point ? 
                <RelatedItems inkData={itemData[dataModal.deviceIndex]} data={item} graphics={defaultData[dataModal.deviceIndex]?.graphics} />
                :"Zádná data"}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={acordionState === "panel2"} onChange={setAcordion("panel2")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={style.accordionSummary}
            >
              <Typography>Více informací</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Info deviceIndex={dataModal.deviceIndex} index={dataModal.index} />
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={acordionState === "panel3"} onChange={setAcordion("panel3")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={style.accordionSummary}
            >
              <Typography>Log</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Log />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Modal>
  );
}

function Command({data, deviceIndex, graphics}:{data:inkDataItemInterface, deviceIndex:number, graphics:any}){
  const [text,setText] = useState(0);
  const [inkSytemDataMaping] = useTypeVariableStore((state) => [state.device[deviceIndex]?.inkSytemDataMaping]);
  //const text = graphics[data.style]?.item[data.value]?.name
  console.log(inkSytemDataMaping)
  
  let povel = {
    messageID: 3,
    messageType: "command",
    data:[
      { id: data.id, value: 0 }
    ]
  }

  let rewrite = {
    messageID: 5,
    messageType: "rewrite",
    data: [{ id: data.id, value: 0, qualit: 0}]
  }

  function buttonText(value:number){
    return graphics[data.style]?.item[value]?.name
  }

  function sendCommand(){
    povel.data[0].value = text;
    socket[deviceIndex].emit("command", povel);
  }

  function comandOnOff(a:number){
    povel.data[0].value = a;
    socket[deviceIndex].emit("command", povel);
  }

  function comandRewrite(a:number){
    rewrite.data[0].value = a;
    socket[deviceIndex].emit("command", rewrite);
  }

  function setForm(event: React.ChangeEvent<HTMLInputElement>){
    let value = event.target.value;
    /*if(value.includes('.')){
      value = value + "1"
    }*/
    setText(parseFloat(value));
  }

  return(
    <React.Fragment>
      {data.typ === 21 ? 
        <React.Fragment>
          <Button className={Style.Modal_button} style={{marginRight: "5px"}} variant="contained" onClick={()=>{comandOnOff(1)}}>Zapnout</Button>
          <Button className={Style.Modal_button} variant="contained" onClick={()=>{comandOnOff(0)}}>Vypnout</Button>
        </React.Fragment> 
      :""}
      {data.typ === 26 ? 
        <React.Fragment>
          <Button className={Style.Modal_button} style={{marginRight: "5px"}} variant="contained" onClick={()=>{comandOnOff(2)}}>Zapnout</Button>
          <Button className={Style.Modal_button} variant="contained" onClick={()=>{comandOnOff(1)}}>Vypnout</Button>
        </React.Fragment> 
      :""}
      {data.typ === 27 || data.typ === 28 || data.typ === 29 ?
        <React.Fragment>
          <TextField
            label="Počet výhybek"
            name="turnout"
            variant="outlined"
            value={text}
            onChange={setForm}
          />
          <Button className={Style.Modal_button} variant="contained" onClick={sendCommand}>Odeslat Povel</Button>
        </React.Fragment>
      :""}

      {inkSytemDataMaping?.ink_test === 1 &&
        <React.Fragment>
          {data.typ === 1 || data.typ === 2 || data.typ === 3 || data.typ === 4 || data.typ === 5 ?
            <React.Fragment>
              <Button className={Style.Modal_button} style={{marginRight: "5px"}} variant="contained" onClick={()=>{comandRewrite(1)}}>Působí</Button>
              <Button className={Style.Modal_button} variant="contained" onClick={()=>{comandRewrite(0)}}>Nepůsobí</Button>
            </React.Fragment>
          :""}
          {data.typ === 6 ?
            <React.Fragment>
              <Button className={Style.Modal_button} style={{marginRight: "5px"}}  variant="contained" onClick={()=>{comandRewrite(2)}}>Zapnuto</Button>
              <Button className={Style.Modal_button} variant="contained" onClick={()=>{comandRewrite(1)}}>Vypnuto</Button>
              <Button className={Style.Modal_button} style={{marginRight: "5px"}} variant="contained" onClick={()=>{comandRewrite(0)}}>Mezipoloha</Button>
              <Button className={Style.Modal_button} variant="contained" onClick={()=>{comandRewrite(3)}}>Neurčitý stav</Button>
            </React.Fragment>
          :""}
          {data.typ === 7 || data.typ === 8 || data.typ === 9 ?
          <React.Fragment>
              <TextField
                label="Číslo"
                name="turnout"
                variant="outlined"
                value={text}
                onChange={setForm}
              />
              <Button className={Style.Modal_button} variant="contained" onClick={()=>{comandRewrite(text)}}>Zapsat měření</Button>
            </React.Fragment>
          :""}
        </React.Fragment>
      }
    </React.Fragment>
  );
}

function RelatedItems({data,inkData,graphics}:any){
 
  return(
    <React.Fragment>
      <table>
        <thead>
          <tr>
            <th>Index</th>
            <th>Název</th>
            <th>Hodnota</th>
          </tr>
        </thead>
        <tbody>
          {data.point.map((number:number)=>{
            const text = graphics[inkData[number].style]?.item[inkData[number].value]?.name
            return(
              <tr>
                <td>{number}</td>
                <td>{inkData[number].desc}</td>
                <td>{text ? text : inkData[number].value}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}

function Info({deviceIndex, index}:{deviceIndex:number, index:number}){

  const request = {
    messageID: 2,
    requestID: 4,
    messageType: "request item",
    id: index
  }

  const driverInitData = { // INK => web
    messageType: "driver init",
    data:[
      { id: 0,
        name: "PLC_U1", // název driveru
        desc: "Pole trafa T101", // popis driveru
        type: "iec104c", // typ driveru
        state: 0, // stav driveru (0-neběží, 1-běží, 2-chyba, 4-)
        cfid: 10, // id poruchy komunikace
        interface:{
          rport: 0, // vzdálený komunikační port
          lport: 0, // lokální komunikační port
          rip: "172.16.110.75", // vzdálená ip  
          lip: "172.16.0.20", // lokální ip
        }
      },
      {id: 1, name: "PLC_U1", desc: "něco", type: "iec104c", state: 0, interface:{
        rport: 0, // vzdálený komunikační port
        lport: 0, // lokální komunikační port
        rip: "172.16.110.75", // vzdálená ip  
        lip: "172.16.0.20", // lokální ip
      }},
      {id: 2, name: "ED1", desc: "Dispečing 1", type: "iec104s", state: 0, interface:{
        rport: 0, // vzdálený komunikační port
        lport: 2404, // lokální komunikační port
        rip: "172.16.110.75", // vzdálená ip  
        lip: "172.16.0.20", // lokální ip
      }},
      {id: 3, name: "ED2", desc: "Dispečing 2", type: "iec61850c", state: 0, interface:{
        rport: 0, // vzdálený komunikační port
        lport: 2404, // lokální komunikační port
        rip: "172.16.110.75", // vzdálená ip  
        lip: "172.16.0.20", // lokální ip
      }},
      {id: 4, name: "TS", desc: "Dotykový display ASX1", type: "modbus", state: 0, interface:{
        rport: 0, // vzdálený komunikační port
        lport: 1000, // lokální komunikační port
        rip: "172.16.110.75", // vzdálená ip  
        lip: "172.16.0.20", // lokální ip
      }}
    ]
  }

  const itemData = { // INK => web
    messageType: "item",
    dataTarget:[
      { id: 0,
        driverID: 2,
        conv: {id: 0, limit:[]}, 
        off_lo: null,
        lo: null,
        off_hi: null,
        hi: null,

        // povel nebo signál
        comnd:{
          cond: 0, // podmínky povelu
          lp: 0, 
          lpt: 0,
          blkcmd: 0, // blokace povelů
        },
        sig:{},
        // data protokolu
        iec104s:{
          type: 13, // typ iec104
          ioa: 4867100, // adresa 3B (ioa = ioa1 * 65536 + ioa2), (ioa = ioa1 * 65536 + ioa2 * 256 + ioa3)
          asdu: 0,
          group: 0
        }
      },
      {id: 1, conv: {id: 0, limit:[]}, 
      off_lo: null,
      lo: null,
      off_hi: null,
      hi: null,driverID: 3, // konkrétní protokol
      iec104c:{
        type: 0, // typ iec104
        ioa: 1500, // adresa 3B (ioa = ioa1 * 65536 + ioa2), (ioa = ioa1 * 65536 + ioa2 * 256 + ioa3)
        //ioa1: 0, // adresa strukturovaná 1B 
        //ioa2: 0, // adresa strukturovaná 2B
        asdu: 0,
        group: 0
      },
      iec104s:{
        type: 0,
        ioa: 1500, // adresa 3B (ioa = ioa1 * 65536 + ioa2)
        //ioa1: 0, // adresa strukturovaná 1B 
        //ioa2: 0, // adresa strukturovaná 2B
        asdu: 0,
        group: 0
      },
      iec61850c:{
        LDname: null,
        LNname: null,
        ctlModel: null,
        sync: null,
        lock: null,
        test: null
      },
      iec61850:{
        LDname: null,
        RCBname: null,
        LNname: null,
      }},
      {id: 2, conv: {id: 0, limit:[]}, 
      off_lo: null,
      lo: null,
      off_hi: null,
      hi: null,driverID: 4, MODBUS:{}},
      {id: 3, conv: {id: 0, limit:[]}, 
      off_lo: null,
      lo: null,
      off_hi: null,
      hi: null,driverID: 4, MODBUS:{}},
      {id: 4, conv: {id: 0, limit:[]}, 
      off_lo: null,
      lo: null,
      off_hi: null,
      hi: null,driverID: 4, MODBUS:{}}
    ],
    dataSource:[
      {id: 0, conv: {id: 0, limit:[]}, 
      off_lo: null,
      lo: null,
      off_hi: null,
      hi: null, driverID: 1, // konkrétní protokol
      iec104c:{
        type: 0, // typ iec104
        ioa: 1500, // adresa 3B (ioa = ioa1 * 65536 + ioa2), (ioa = ioa1 * 65536 + ioa2 * 256 + ioa3)
        //ioa1: 0, // adresa strukturovaná 1B 
        //ioa2: 0, // adresa strukturovaná 2B
        asdu: 0,
        group: 0
      },
      iec104s:{
        type: 0,
        ioa: 1500, // adresa 3B (ioa = ioa1 * 65536 + ioa2)
        //ioa1: 0, // adresa strukturovaná 1B 
        //ioa2: 0, // adresa strukturovaná 2B
        asdu: 0,
        group: 0
      }}
    ],
    config:{
  
    }
  }

  function Driver({itemData}:any){

    interface dataIEC104 {
      type: number,
      ioa: number,
      asdu: number,
      group: number
    }

    function IEC104({data}:{data:dataIEC104}){

      // ioa = ioa1 * 65536 + ioa2
      const ioa2 = data.ioa % 65536 // 2B
      const ioa1 = (data.ioa - ioa2)/65536 // 1B

      // ioa = ioa1 * 65536 + ioa2), (ioa = ioa1 * 65536 + ioa2 * 256 + ioa3
      const ioa33 = (data.ioa % 65536)%256
      const ioa22 = (data.ioa % 65536 - ioa33)/256
      const ioa11 = (data.ioa - ioa22*256 - ioa33)/65536

      let typeShortcode, typeText;

      if(ProtokolText.IEC104[data.type].shortcode){
        typeShortcode = ProtokolText.IEC104[data.type].shortcode
      }else{
        typeShortcode = "nedefinován";
      }

      if(ProtokolText.IEC104[data.type].text){
        typeText = ProtokolText.IEC104[data.type].text
      }else{
        typeText = "nedefinován";
      }

      return(
        <table>
          <thead>
            <th colSpan={6} style={{textAlign: "center",backgroundColor: "orange"}} >Protokol</th>
          </thead>
          <tbody className={Style.Modal_body_info_protocol}>
            <tr>
              <th> IOA: </th>
              <td> {data.ioa} </td>
              <th> IOA1: </th>
              <td> {ioa1} </td>
              <th> IOA1: </th>
              <td> {ioa11} </td>
            </tr>
            <tr>
              <th> ASDU: </th>
              <td> {data.asdu} </td>
              <th> IOA2: </th>
              <td> {ioa2} </td>
              <th> IOA2: </th>
              <td> {ioa22} </td>
            </tr>
            <tr>
              <th> Group: </th>
              <td> {data.group} </td>
              <th></th>
              <td></td>
              <th> IOA3: </th>
              <td> {ioa33} </td>
            </tr>
            <tr>
              <th>Type:</th>
              <td> {data.type} - {typeShortcode} </td>
              <th></th>
              <td></td>
              <th></th>
              <td></td>
            </tr>
            <tr>
              <td colSpan={6} > {typeText} </td>
            </tr>
          </tbody>
        </table>
      )
    }

    function IEC61850s({data}:any){
      return(
        <table>
          <thead>
            <tr>
            <th colSpan={6} style={{textAlign: "center",backgroundColor: "orange"}} >Protokol neimplementován</th>
            </tr>
          </thead>
          <tbody>

          </tbody>
        </table>
      );
    }

    function IEC61850c({data}:any){
      return(
        <table>
          <thead>
            <tr>
            <th colSpan={6} style={{textAlign: "center",backgroundColor: "orange"}} >Protokol neimplementován</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>
      );
    }

    function MODBUS({data}:any){
      return(
        <table>
          <thead>
            <tr>
            <th colSpan={6} style={{textAlign: "center",backgroundColor: "orange"}} >Protokol neimplementován</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </table>
      );
    }
    
    return(
      <div className={Style.Modal_body_info}>
        {itemData.map(({id,driverID,conv,off_lo,lo,off_hi,hi,iec104c,iec104s,iec61850c,iec61850s,modbus}:any)=>{
          return(
            <div key={id} style={{display: "flex"}}>
              <table>
                <thead>
                  <tr>
                    <th colSpan={6} >{driverInitData.data[driverID].name} ({driverInitData.data[driverID].type})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Popis:</th>
                    <td colSpan={5} >{driverInitData.data[driverID].desc}</td>
                  </tr>
                  <tr>
                    <th>Stav:</th>
                    <td>{driverInitData.data[driverID].state}</td>
                    <th>L IP:</th>
                    <td>{driverInitData.data[driverID].interface.lip}</td>
                    <th>L Port:</th>
                    <td>{driverInitData.data[driverID].interface.lport}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <th>R IP:</th>
                    <td>{driverInitData.data[driverID].interface.rip}</td>
                    <th>R Port:</th>
                    <td>{driverInitData.data[driverID].interface.rport}</td>
                  </tr>
                  <tr>
                    <th> OFF_lo: </th>
                    <td> {off_lo} </td>
                    <th> lo: </th>
                    <td> {lo} </td>
                    <th> Konverze: </th>
                    <td> {conv.id} </td>
                  </tr>
                  <tr>
                    <th> OFF_hi: </th>
                    <td> {off_hi} </td>
                    <th> hi: </th>
                    <td> {hi} </td>
                    <th> Parametry: </th>
                    <td> {conv.limit} </td>
                  </tr>
                </tbody>
              </table>
              {driverInitData.data[driverID].type === "iec104s" ? <IEC104 data={iec104s}/> : ""}
              {driverInitData.data[driverID].type === "iec104c" ? <IEC104 data={iec104c}/> : ""}
              {driverInitData.data[driverID].type === "iec61850s" ? <IEC61850s data={iec61850s}/> : ""}
              {driverInitData.data[driverID].type === "iec61850c" ? <IEC61850c data={iec61850c}/> : ""}
              {driverInitData.data[driverID].type === "modbus" ? <MODBUS data={modbus}/> : ""}
              
            </div>
          );
        })}
      </div>
    );
  }

  return(
    <React.Fragment>
      <Tooltip title="Poslat žádost" placement="top" arrow>
        <IconButton component="span" onClick={()=>{
          socket[deviceIndex].emit("request", request);
        }}>
          <CachedIcon />
        </IconButton>
      </Tooltip>
      <br></br>
      <span>Cíl:</span>
      <Driver itemData={itemData.dataTarget} />
      <span>Zdroj:</span>
      <Driver itemData={itemData.dataSource} />
    </React.Fragment>
  );
}

function Log(){

  return(
    <React.Fragment>
      Log
    </React.Fragment>
  );
}

