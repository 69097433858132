import React, { useCallback, useState, useEffect } from 'react'
// import { client } from "./ApolloConf";
import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import {authLink,httpLink,cache,clientDefault} from './ApolloConf'

export default function ApolloWrapper({children}:{children: JSX.Element}) {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>(clientDefault);
  const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>();

  useEffect(() => {
    async function init() {
      const cache = new InMemoryCache();
      let newPersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: true,
        trigger: 'write',
      });
      await newPersistor.restore();
      setPersistor(newPersistor);
      setClient(
        new ApolloClient({
          link: authLink.concat(httpLink),
          cache,
        }),
      );
    }

    init().catch(console.error);
  }, []);

  const clearCache = useCallback(() => {
    if (!persistor) {
      return;
    }
    persistor.purge();
  }, [persistor]);

  return (
      <ApolloProvider client={client as ApolloClient<NormalizedCacheObject>}>
          {children}
      </ApolloProvider>
  )
}
