import React from 'react';
import style from './Style.module.css';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import {AppDataContext, InkDefaultDataContext} from '../../WebSocket/WebSocket';
import {socketStateInterface} from '../../types/app';

export default function Menu() {

  return (
    <AppDataContext.Consumer>
      {({deviceData,socketState}) => (
        <React.Fragment>
          <NavLink to={'/DDTS'}  className={({isActive}) => isActive ? style.Button_Active:undefined}>
            <Button variant="contained" color="secondary" startIcon={<ViewQuiltIcon />}>
              Přehled
            </Button>
          </NavLink>
          <div className={style.text}>Jednotlivá zařízení</div>
          {socketState.map(({ wsAdress, connect}: socketStateInterface,index:number) => {
            return(
              <InkDefaultDataContext.Consumer>
                {(data) => (
                  <React.Fragment>
                    {connect=== true ? (
                      <React.Fragment>
                        {data[index]?.deviceType === ["EOV_OV"] ? (
                          <NavLink to={'/DDTS/EOV_OV/' + wsAdress} className={({isActive}) => isActive ? style.Button_Active:undefined}>
                            <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
                            {deviceData[index].settings.hostName}
                            </Button>
                          </NavLink>
                        ):("") }
                        {data[index]?.deviceType === ["EPZ"] ? (
                          <NavLink to={'/DDTS/EPZ/' + wsAdress} className={({isActive}) => isActive ? style.Button_Active:undefined}>
                                  <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
                                  {deviceData[index].settings.hostName}
                                  </Button>
                                </NavLink>
                                ):("") }
                        {data[index]?.deviceType === ["RH"] ? (
                                <NavLink to={'/DDTS/RH/' + wsAdress} className={({isActive}) => isActive ? style.Button_Active:undefined}>
                                  <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
                                    {deviceData[index].settings.hostName}
                                  </Button>
                                </NavLink>
                              ):("") }
                        {data[index]?.deviceType === ["ELM"] ? (
                                <NavLink to={'/DDTS/ELM/' + wsAdress} className={({isActive}) => isActive ? style.Button_Active:undefined}>
                                  <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
                                    {deviceData[index].settings.hostName}
                                  </Button>
                                </NavLink>
                              ):("") }
                      </React.Fragment>
                    ):(
                      <React.Fragment>
                        <NavLink to={'/DDTS/'} className={style.Button_Disconnect} >
                          <Button variant="contained" color="secondary" startIcon={<FlashOnIcon />}>
                            {deviceData[index]?.settings.hostName}
                          </Button>
                        </NavLink>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </InkDefaultDataContext.Consumer>
            )
          })}
        </React.Fragment>
      )}
    </AppDataContext.Consumer>
  );
}
