import React from 'react';
import Style from './EOV_OV.module.css';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

import {useParams} from "react-router-dom";


export default function Menu() {

  let { id } = useParams<{id:string}>();
  //console.log(id);

  return(
    <React.Fragment>
      <NavLink to={'/DDTS/EOV_OV/' + id }  className={({isActive}) => isActive ? Style.Button_Active : undefined}>
        <Button variant="contained" color="secondary" >
          Rozvaděč
        </Button>
      </NavLink>
      <NavLink to={'/DDTS/EOV_OV/' + id + '/CVA'} className={({isActive}) => isActive ? Style.Button_Active : undefined}>
        <Button variant="contained" color="secondary" >
          CVA Moduly
        </Button>
      </NavLink>
      <NavLink to={'/DDTS/EOV_OV/' + id + '/outputs'} className={({isActive}) => isActive ? Style.Button_Active : undefined}>
        <Button variant="contained" color="secondary" >
          Výstupy
        </Button>
      </NavLink>
      <NavLink to={'/DDTS/EOV_OV/' + id + '/io'} className={({isActive}) => isActive ? Style.Button_Active : undefined}>
        <Button variant="contained" color="secondary" >
          I/O
        </Button>
      </NavLink>
    </React.Fragment>
  )
}