import React, { useContext, useState, useEffect } from "react";
import Style from "../EOV_OV.module.css";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from '@mui/icons-material/Save';
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Modal } from "@mui/material";
import { ConfigContext, socket } from "../../../../../WebSocket/WebSocket";
import {cloneDeepWith, max} from 'lodash';
import { toast } from "react-toastify";
import CheckIcon from '@mui/icons-material/Check';
import SelectInput from './SelectInput';
import SelectOutput from './SelectOutput';
import SelectCVA from './SelectCVA';
import FormHelperText from '@mui/material/FormHelperText';

export default function Setings({ dataModal, setModal }: any) {
  const [setingsData, setSetingsData] = useState({
    name: "ahojky",
    manual: false,
    workingGroup: 1,
    typ: 0,
    typZ: 0,
    phase: 1,
    his: 0,
    switch: 1,
    inState: true,
    inCard: 0,
    inInput: 5,
    outCard: 0,
    outOutput: 5,
    interval: false,
    calendar: true,
    sensor: true,
    sensorLimit: 20,
    AutoOff: false,
    AutoOffLimit: 200,
    mask: [true],
    QHM: [true],
    CVA: [
      {
        hystereze: 50,
        limit: 2500,
        phase: 1,
        comparation: "R",
        module: 0
      }
    ],
    intervals: [
      {
        state: true,
        name: "nějaký název",
        start: "12:30",
        end: "13:00",
        useDay: [
          {
            name: "PO",
            state: true,
          },
        ],
      },
    ],
  })

  const [errorData, setErrorData] = useState({
    name: false,
    workingGroup: false,
  })

  const ws = useContext(ConfigContext);

  function Save() {
    // kontrola dat
    let error = { ...errorData };
    let konec = false;

    if(setingsData.workingGroup > 0){
      error.workingGroup = false;
    }
    else{
      error.workingGroup = true;
      konec = true;
    }

    setErrorData(error);
    if(konec){
      toast.error("Nesprávné zadání");
      return
    }

    function checkAdult(age: any) {
      return age.io.engine.hostname + ":" + age.io.engine.port == dataModal.adress;
    }
    const IndexDevice = socket.findIndex(checkAdult);

    const data = {
      command: "output-setings",
      setings: {
        name: setingsData.name,
        typ: {
          typ: setingsData.typ,
          typZ: setingsData.typZ,
          phase: setingsData.phase,
          his: setingsData.his,
          switch: setingsData.switch,
        },
        manual: setingsData.manual,
        binInput: {
          state: setingsData.inState,
          Card: setingsData.inCard,
          input: setingsData.inInput,
        },
        binOutput: {
          Card: setingsData.outCard,
          output: setingsData.outOutput,
        },
        workingGroup: setingsData.workingGroup,
        mask: setingsData.mask,
        QHM: setingsData.QHM,
        CVA: setingsData.CVA,
        limitAutoOff: {
          state: setingsData.AutoOff,
          limit: setingsData.AutoOffLimit,
        },
        auto: {
          interval: setingsData.interval,
          calendar: setingsData.calendar,
          sensor: {
            state: setingsData.sensor,
            limit: setingsData.sensorLimit,
          },
          intervals: setingsData.intervals,
        },
      },
    }
    socket[IndexDevice].emit("command", data);
    console.log("odesílání");
  }


  function checkAdult(age: any) {
    return age.DeviceAdress == dataModal.adress;
  }
  const IndexDevice = ws.eovSettingsData.findIndex(checkAdult);
  var orgData = ws.eovSettingsData[IndexDevice].Outputs[dataModal.index];
  useEffect(() => {
  
    console.log("konfigurace index", IndexDevice);
    
    var test = cloneDeepWith(ws.eovSettingsData[IndexDevice].Outputs[dataModal.index]);
    setSetingsData({
      name: test.name,
      manual: test.manual,
      workingGroup: test.workingGroup,
      typ: test.typ.typ,
      typZ: test.typ.typZ,
      phase: test.typ.phase,
      his: test.typ.his,
      switch: test.typ.switch,
      inState: test.binInput.state,
      inCard: test.binInput.Card,
      inInput: test.binInput.input,
      outCard: test.binOutput.Card,
      outOutput: test.binOutput.output,
      interval: test.auto.interval,
      calendar: test.auto.calendar,
      sensor: test.auto.sensor.state,
      sensorLimit: test.auto.sensor.limit,
      AutoOff: test.limitAutoOff.state,
      AutoOffLimit: test.limitAutoOff.limit,
      mask: test.mask,
      QHM: test.QHM,
      CVA: test.CVA,
      intervals: test.auto.intervals
    });
  }, []);

  const handleClose = () => {
    setModal({ ...dataModal, state: false });
  };

  function SetFrom(event:any) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setSetingsData({ ...setingsData, [name]: value });
  }

  function SetCVAphase(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var value = [ ...setingsData.CVA ];
    const index = setingsData.CVA.findIndex(checkAdult);
    value[index].phase = event.target.value;
    setSetingsData({ ...setingsData, CVA: value })
  }

  function SetCVAhystereze(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var value = [ ...setingsData.CVA ];
    const index = setingsData.CVA.findIndex(checkAdult);
    value[index].hystereze = event.target.value;
    setSetingsData({ ...setingsData, CVA: value });
  }

  function SetCVAlimit(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var value = [ ...setingsData.CVA ];
    const index = setingsData.CVA.findIndex(checkAdult);
    value[index].limit = event.target.value;
    setSetingsData({ ...setingsData, CVA: value });
  }

  function SetCVAcomparation(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var value = [ ...setingsData.CVA ];
    const index = setingsData.CVA.findIndex(checkAdult);
    value[index].comparation = event.target.value;
    setSetingsData({ ...setingsData, CVA: value });
  }

  function SetCVAmodule(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var value = [ ...setingsData.CVA ];
    const index = setingsData.CVA.findIndex(checkAdult);
    value[index].module = event.target.value;
    setSetingsData({ ...setingsData, CVA: value });
  }

  function CVA(event:any) {
    const jedno = [
      {
        hystereze: 50,
        limit: 2500,
        phase: 1,
        comparation: "R",
        module: 0
      }
    ]
    const dve = [
      {
        hystereze: 50,
        limit: 2500,
        phase: 1,
        comparation: "W",
        module: 0
      },
      {
        hystereze: 50,
        limit: 2500,
        phase: 2,
        comparation: "W",
        module: 0
      }
    ]
    const tri = [
      {
        hystereze: 50,
        limit: 2500,
        phase: 1,
        comparation: "W",
        module: 0
      },
      {
        hystereze: 50,
        limit: 2500,
        phase: 2,
        comparation: "W",
        module: 0
      },
      {
        hystereze: 50,
        limit: 2500,
        phase: 3,
        comparation: "W",
        module: 0
      }
    ]
    if(setingsData.CVA.length != event.target.value){
      console.log("změna pole");
      if(event.target.value === 3){
        setSetingsData({ ...setingsData, CVA: tri, phase: 3 });
      }
      else if(event.target.value === 2){
        setSetingsData({ ...setingsData, CVA: dve, phase: 2 });
      }
      else if(event.target.value === 1){
        setSetingsData({ ...setingsData, CVA: jedno, phase: 1 });
      }
    }
  }


  function IntervalName(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var text = [ ...setingsData.intervals ];
    const index = setingsData.intervals.findIndex(checkAdult);
    text[index].name = event.target.value;
    setSetingsData({ ...setingsData, intervals: text })
  }

  function IntervalStart(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var text = [ ...setingsData.intervals ];
    const index = setingsData.intervals.findIndex(checkAdult);
    text[index].start = event.target.value;
    setSetingsData({ ...setingsData, intervals: text })
  }

  function IntervalEnd(event:any) {
    function checkAdult(age:any) {
      return age.id >= event.target.name;
    }
    var text = [ ...setingsData.intervals ];
    const index = setingsData.intervals.findIndex(checkAdult);
    text[index].end = event.target.value;
    setSetingsData({ ...setingsData, intervals: text })
  }

  function testBarev(){
    return {border: "2px solid blue"}
  }

  return (
    <Modal open={dataModal.state} onClose={handleClose}>
      <div className={Style.Modal}>
        <div className={Style.Modal_Header}>
          {dataModal.id} {dataModal.name}
          <Tooltip title="Zavřít nastavení" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Close setings"
              onClick={handleClose}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Uložit nastavení" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Save setings"
              onClick={Save}
              component="span"
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={Style.Modal_body}>
          <span>Název vývodu</span>
          <div className={Style.NameOutputSet}>
            <TextField
              style={setingsData.name === orgData.name ? {border: "0"} : {border: "2px solid blue"}}
              name="name"
              error={errorData.name}
              variant="outlined"
              value={setingsData.name}
              onChange={SetFrom}
            />
          </div>
          <hr></hr>

          <span>Typ vývodu</span>
          <div className={Style.Typ}>
            <div>
              <FormHelperText>Typ vývodu</FormHelperText>
              <Select
                style={setingsData.typ === orgData.typ.typ ? {border: "0"} : {border: "2px solid blue"}}
                name="typ"
                variant="outlined"
                value={setingsData.typ}
                onChange={SetFrom}
              >
                <MenuItem value={0}>Rezerva</MenuItem>
                <MenuItem value={1}>EOV</MenuItem>
                <MenuItem value={2}>OSV</MenuItem>
              </Select>
            </div>
            <div>
              <FormHelperText>Počet fází</FormHelperText>
              <Select
                style={setingsData.phase === orgData.typ.phase ? {border: "0"} : {border: "2px solid blue"}}
                name="phase"
                variant="outlined"
                value={setingsData.CVA.length}
                onChange={CVA}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
            </div>

            {setingsData.typ === 1 ? (
              <React.Fragment>
                <div>
                  <FormHelperText>Zátěž</FormHelperText>
                  <Select
                    style={setingsData.switch=== orgData.typ.switch ? {border: "0"} : {border: "2px solid blue"}}
                    name="switch"
                    variant="outlined"
                    value={setingsData.switch}
                    onChange={SetFrom}
                  >
                    <MenuItem value={1}>Táhlo</MenuItem>
                    <MenuItem value={2}>Opornice</MenuItem>
                    <MenuItem value={3}>Jazyk</MenuItem>
                    <MenuItem value={4}>PHS</MenuItem>
                    <MenuItem value={5}>Předtopné pole</MenuItem>
                    <MenuItem value={6}>Výkolejka</MenuItem>
                  </Select>
                </div>
                
                {setingsData.switch === 1 ? (
                  <div>
                    <FormHelperText>HIS</FormHelperText>
                    <Select
                      style={setingsData.his === orgData.typ.his ? {border: "0"} : {border: "2px solid blue"}}
                      name="his"
                      variant="outlined"
                      value={setingsData.his}
                      onChange={SetFrom}
                    >
                      <MenuItem value={0}>Ne</MenuItem>
                      <MenuItem value={1}>T1</MenuItem>
                      <MenuItem value={2}>T2</MenuItem>
                    </Select>
                  </div>
                ) : ("")}

                {setingsData.switch > 1 && setingsData.switch < 5  ? (
                  <div>
                    <FormHelperText>HIS</FormHelperText>
                    <Select
                      style={setingsData.his === orgData.typ.his ? {border: "0"} : {border: "2px solid blue"}}
                      name="his"
                      label="HIS"
                      variant="outlined"
                      value={setingsData.his}
                      onChange={SetFrom}
                    >
                      <MenuItem value={0}>Ne</MenuItem>
                      <MenuItem value={10}>O1</MenuItem>
                      <MenuItem value={20}>O2</MenuItem>
                    </Select>
                  </div>
                ) : ("")}

                {setingsData.switch === 5 ? (
                  <div>
                    <FormHelperText>HIS</FormHelperText>
                    <Select
                      style={setingsData.his === orgData.typ.his ? {border: "0"} : {border: "2px solid blue"}}
                      name="his"
                      label="HIS"
                      variant="outlined"
                      value={setingsData.his}
                      onChange={SetFrom}
                    >
                      <MenuItem value={0}>Ne</MenuItem>
                      <MenuItem value={100}>O1T1</MenuItem>
                      <MenuItem value={200}>O2T1</MenuItem>
                    </Select>
                  </div>
                ) : ("")}

              </React.Fragment>
            ) : ("")}

          </div>
          <hr></hr>

          <span>Provozní skupina</span>
          <div className={Style.Group}>
            <TextField
              style={{border: "2px solid #ff471a"}}
              type="number"
              inputProps={{min: 1}}
              error={errorData.workingGroup}
              name="workingGroup"
              variant="outlined"
              value={setingsData.workingGroup}
              onChange={SetFrom}
            />
          </div>
          <hr></hr>
          
          <span>Typ zpětné signalizace</span>
          <div className={Style.TypZ}>
            <Select
              name="typZ"
              label="Typ vývodu"
              variant="outlined"
              value={setingsData.typZ}
              onChange={SetFrom}
            >
              <MenuItem value={0}>Ne</MenuItem>
              <MenuItem value={1}>CVA</MenuItem>
              <MenuItem value={2}>Zpětný signál</MenuItem>
            </Select>
          </div>
          
    
          {setingsData.typZ === 1 ? (
            <React.Fragment>
              <hr></hr>
              <span>CVA</span>
              <div className={Style.CVA}>
                {setingsData.CVA.map(({ id, hystereze, limit, phase, comparation, module }: any) => {
                  return (
                    <div key={id}>
                      <div>
                      <div>
                      <FormHelperText>Měřící modul</FormHelperText>
                      <SelectCVA value={module} onChange={SetCVAmodule} name={id}/>
                      </div>
                      <div>
                      <FormHelperText>Fáze</FormHelperText>
                      <Select
                        name={id}
                        variant="outlined"
                        value={phase}
                        onChange={SetCVAphase}
                      >
                        <MenuItem value={1}>L1</MenuItem>
                        <MenuItem value={2}>L2</MenuItem>
                        <MenuItem value={3}>L3</MenuItem>
                      </Select>
                      </div>
                      <div>
                      <FormHelperText>Komparace</FormHelperText>
                      <Select
                        name={id}
                        variant="outlined"
                        value={comparation}
                        onChange={SetCVAcomparation}
                      >
                        <MenuItem value={"A"}>Proud</MenuItem>
                        <MenuItem value={"R"}>Odpor</MenuItem>
                        <MenuItem value={"W"}>Výkon</MenuItem>
                      </Select>
                      </div>
                      </div>
                      <div>
                      <div>
                      <FormHelperText>Hystereze</FormHelperText>
                      <TextField
                        type="number"
                        name={id}
                        variant="outlined"
                        value={hystereze}
                        onChange={SetCVAhystereze}
                      />
                      </div>
                      <div>
                      <FormHelperText>Limit</FormHelperText>
                      <TextField
                        type="number"
                        name={id}
                        variant="outlined"
                        value={limit}
                        onChange={SetCVAlimit}
                      />

                      </div>
                      <div className={Style.CVAvalue}>
                        <span>1500 W</span>
                        </div>
                      </div>
                      <hr></hr>
                      
                      
                    </div>
                  )
                })}
              </div>
            </React.Fragment>
          ):("")}

          {setingsData.typZ === 2 ? (
            <React.Fragment>
              <hr></hr>
              <span>Zpětný signál</span>
              <div className={Style.Input}>
                <SelectInput value1={setingsData.inCard} value2={setingsData.inInput} onChange={SetFrom} />
              </div>
            </React.Fragment>
          ):("")}
          

          <span>Výstup</span>
          <div className={Style.Output}>
            <SelectOutput value1={setingsData.outCard} value2={setingsData.outOutput} onChange={SetFrom} />
          </div>
          <hr></hr>

          <span>QHM</span>
          <div className={Style.QHM}>
            {setingsData.QHM.map(( state , index:number) => {
              return (
                <React.Fragment key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={state === true ? Style.ON : Style.OFF}
                    onClick={() => {
                      var value = [ ...setingsData.QHM ];
                      value[index] = !value[index];
                      setSetingsData({ ...setingsData, QHM: value });
                    }}
                  >
                    {index+1}
                  </Button>
                </React.Fragment>
              );
            })}
          </div>
          <hr></hr>

          <span>Společné řízení</span>
          <div className={Style.Drive}>
            {setingsData.mask.map(( state , index:number) => {
              return (
                <React.Fragment key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={state === true ? Style.ON : Style.OFF}
                    onClick={() => {
                      var value = [ ...setingsData.mask ];
                      value[index] = !value[index];
                      setSetingsData({ ...setingsData, mask: value });
                    }}
                  >
                    {index+1}
                  </Button>
                </React.Fragment>
              );
            })}
          </div>
          <hr></hr>

          <span>Automatické vyp.</span>
          <Switch
            name="AutoOff"
            className={Style.switch}
            checked={setingsData.AutoOff}
            onChange={SetFrom}
          /> <br></br>
          {setingsData.AutoOff === true ? (
            <div className={Style.AutoOff}>
              <TextField
                type="number"
                label="Limit"
                name="AutoOffLimit"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">s</InputAdornment>
                  ),
                }}
                value={setingsData.AutoOffLimit}
                onChange={SetFrom}
              />
            </div>
          ) : (
            ""
          )}
          <hr></hr>

          <span>Pouze ručně</span>
          <Switch 
            name="manual"
            className={Style.switch}
            checked={setingsData.manual}
            onChange={SetFrom} 
          /> <br></br>
          <hr></hr>

          {setingsData.manual === false? (
            <React.Fragment>
              <span>Kalendář</span>
              <Switch 
                name="calendar"
                className={Style.switch}
               checked={setingsData.calendar} 
              onChange={SetFrom}
            /> <br></br>
            <hr></hr>

            <span>Čidlo</span>
            <Switch
              name="sensor"
              className={Style.switch}
              checked={setingsData.sensor}
              onChange={SetFrom}
            /> <br></br>
            {setingsData.sensor === true ? (
              <div className={Style.Sensor}>
                <Select
                  name="typZ"
                  label="Typ vývodu"
                  variant="outlined"
                  value={setingsData.typZ}
                  onChange={SetFrom}
                >
                  <MenuItem value={0}>Ne</MenuItem>
                  <MenuItem value={1}>Lux metr 1</MenuItem>
                  <MenuItem value={2}>Teplotní čidlo 1</MenuItem>
                  <MenuItem value={2}>Teplotní čidlo 2</MenuItem>
                </Select>
                <TextField
                  type="number"
                  label="Limit"
                  name="sensorLimit"
                  variant="outlined"
                  InputProps={{
                  endAdornment: (
                   <InputAdornment position="start">lx</InputAdornment>
                  ),
                }}
                value={setingsData.sensorLimit}
                onChange={SetFrom}
                />
              </div>
            ) : ("")}
            <hr></hr>

            <span>Interval</span>
            <Switch 
              name="interval"
              className={Style.switch}
              checked={setingsData.interval}
              onChange={SetFrom} 
            />
            <br></br>
            {setingsData.interval === true ? (
              <div>
                {setingsData.intervals.map(
                  ({ state, name, start, end, useDay }: any, index:any) => {
                    return (
                      <div key={index} className={Style.IntervalGrid}>
                        <div className={Style.IntervalNumber}>
                          <span>{index+1}</span>
                        </div>
                        <div className={Style.IntervalName}>
                          <TextField
                            label="Název"
                            name={index}
                            variant="outlined"
                            value={name}
                            onChange={IntervalName}
                          />
                        </div>
                        <div className={Style.IntervalState}>
                          {state === true ?
                            <Tooltip title="Zakázat interval" placement="top" arrow>
                              <IconButton
                                color="primary"
                                aria-label="Close setings"
                                onClick={() => {
                                  var value = [ ...setingsData.intervals ];
                                  value[index].state = false;
                                  setSetingsData({ ...setingsData, intervals: value });
                                }}
                                component="span"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Tooltip>
                          :
                            <Tooltip title="Povolit interval" placement="top" arrow>
                              <IconButton
                                color="primary"
                                aria-label="Close setings"
                                onClick={() => {
                                  var value = [ ...setingsData.intervals ];
                                  value[index].state = true;
                                  setSetingsData({ ...setingsData, intervals: value });
                                }}
                                component="span"
                              >
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        </div>
                        <div className={Style.IntervalStart}>
                          <TextField
                            label="od"
                            type="time"
                            name={index}
                            variant="outlined"
                            value={start}
                            onChange={IntervalStart}
                          />
                        </div>
                        <div className={Style.IntervalEnd}>
                          <TextField
                            label="do"
                            type="time"
                            name={index}
                            variant="outlined"
                            value={end}
                            onChange={IntervalEnd}
                          />
                        </div>

                        <div className={Style.IntervButton + " " +(state === false ? Style.IntervButtonGrey : "")}>
                          {useDay.map(({ state, name }: any) => {
                            return (
                              <Button
                                key={name}
                                variant="contained"
                                color="primary"
                                className={state === true ? Style.ON : Style.OFF}
                                onClick={() => {
                                
                                  function checkAdult2(age:any) {
                                    return age.name === name;
                                  }
                                  var value = [ ...setingsData.intervals ];
                                  const index2 = setingsData.intervals[index].useDay.findIndex(checkAdult2);
                                  console.log( index, index2, name);
                                  value[index].useDay[index2].state = !value[index].useDay[index2].state;
                                  setSetingsData({ ...setingsData, intervals: value });
                                }}
                              >
                                {name}
                              </Button>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : ("")}
            </React.Fragment>
          ): ("")}
        </div>
      </div>
    </Modal>
  );
}