import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import cs from './Language/cs.json';
import en from './Language/en.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      cs: {translations: cs},
      en: {translations: en},
    },
    fallbackLng: 'cs',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    // defaultNS: 'translations',
    nonExplicitSupportedLngs: true,
    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
