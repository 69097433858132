import React, { useState, useContext, useMemo, useEffect} from "react";
import Style from "./InkData.module.css";
import style from "./style.module.css";
import {ConfigContext, socket, contextInterface, InkDefaultDataContext, InkItemDataContext} from '../../WebSocket/WebSocket';

import Filtry from './components/Filtry';
import Data from './components/Data';
import NavBar from './components/NavBar';

import Events from './components/Events';

import {inkDataFiltersInterface} from '../../types/INK';


export interface electionInterface {
  time: number,
  deviceIndex: number,
  display: number
}

export const filterTypeData =[
  {
    name: "Podle typu dat",
    item: [
      { value: -2, name: "Kvalita dobrá" },
      { value: -1, name: "Kvalita špatná" },
      { value: 0, name: "Rezerva" },
      { name: "Signál", 
        item: [ 
          { value: 1, name: "SB" },
          { value: 2, name: "TON" },
          { value: 3, name: "TOF" },
          { value: 4, name: "IMP" },
          { value: 5, name: "IMP + OR" },
          { value: 6, name: "DB" },
          { value: 7, name: "RL" },
          { value: 8, name: "DW" },
          { value: 9, name: "SN" }
        ]
      },
      { name: "Povel", 
        item: [
          { value: 21, name: "SB" },
          { value: 26, name: "DB" },
          { value: 27, name: "RL" },
          { value: 28, name: "DW" },
          { value: 29, name: "SN" }
        ] 
      }
    ]
  }
]

let jednou = true;

export default function InkData(){
  const [typyDat, setTypyDat] = useState<Array<number>>([]); // vyjmenování nepovolených typů dat
  const [checked, setChecked] = useState<Array<number>>([]); // vyjmenování zakázaných položek filtrem
  const [election, setElection] = useState<electionInterface>({
    time: 1,
    deviceIndex: 0,
    display: 0
  })

  const defaultData = useContext(InkDefaultDataContext);
  const itemData = useContext(InkItemDataContext);
  const inkFilter = filterParseData(defaultData[election.deviceIndex]?.filters);
  const items = itemData[election.deviceIndex]

  const stateData = useMemo(() => regenerate(), [checked,typyDat]);

  if(jednou){
    const string = localStorage.getItem('stateFilters');
    console.log(string);
    if(string){
      const data = JSON.parse(string);
      setChecked(data.filters);
      setTypyDat(data.types);
    }
    regenerate();
    jednou = false;
  }

  function regenerate(){
    localStorage.setItem('stateFilters', JSON.stringify({filters:checked,types:typyDat}));
    let typy:number[] = [-2,-1,0];
    let filtry:number[] = [];
    let checkedInkData:boolean[] = [];

    if(!items){
      return {
        typy: typy,
        filtry: filtry,
        checkedInkData: checkedInkData
      }
    }

    // vyhledání zbývajících typů
    for(let i = 0; items.length > i; i++){
      if(items[i] !== undefined && items[i] !== null){
        let pridat = true;
        
        for(let e = 0; items[i].fl.length > e ; e++){
          // zjistění jestli položka není zakázána filtrem
          if(checked.findIndex((element:number) => element === items[i].fl[e]) !== -1){
            pridat = false; 
          }
          // zjištění jestli položka není zakázána typem
          if(typyDat.findIndex((element:number) => element === items[i].typ) === -1){
            if(filtry.findIndex((element:number) => element === items[i].fl[e]) === -1){
              filtry.push(items[i].fl[e]); // přidání filtru
            }
          }
        }
        
        if(pridat){
          if(typy.findIndex((element:number) => element === items[i].typ) === -1){
            typy.push(items[i].typ); // přidání typu
          }
        }

        // nastavení stavů zobrazení položek dat
        if(zobrazit(items[i].quality, items[i].en, items[i].typ, items[i].fl)){
          checkedInkData[i] = true;
        }else{
          checkedInkData[i] = false;
        }


      }
    }

    function zobrazit(quality:number,en:number,typ:number,fl:number[]){
      // zjištění zda je zakázáno zobrazení rezerv
      if(en !== 1){
        if(typyDat.indexOf(0) !== -1){
          return false
        }
      }
      // zjištění zda je zakázáno zobrazení quality
      if(quality == 1){ // špatná kvalita
        if(typyDat.indexOf(-1) !== -1){
          return false
        }
      }else{ // dobrá kvalita
        if(typyDat.indexOf(-2) !== -1){
          return false
        }
      }
      // zjištění zda je zakázáno zobrazení datového typu
      if(typyDat.indexOf(typ) !== -1){
        return false
      }
      // zjištění zda není zakázáno filtrem
      for(let i = 0; fl.length > i; i++){
        if(checked.indexOf(fl[i]) !== -1){
          return false
        }
      }

      return true
    }

    console.log("typy", typy); // zbývající nalezené typy
    console.log("filtry", filtry); // zbývající nalezené filtry
    console.log("zobrazení", checkedInkData); // stav zobrazení položek dat

    return {
      typy: typy,
      filtry: filtry,
      checkedInkData: checkedInkData
    }
  }

  return(
    <div className={style.body}>
      <NavBar election={election} setElection={setElection} />
      <div className={style.dataBody}>
        <div className={style.panelFiltry}>
          {election.display === 0 && // Filtry online dat
            <React.Fragment>
              <Filtry filterData={filterTypeData} checked={typyDat} setChecked={setTypyDat} display={stateData.typy}/>
              <Filtry filterData={inkFilter} checked={checked} setChecked={setChecked} display={stateData.filtry}/>
            </React.Fragment>
          }
        </div>
        <div className={style.panelData}>
          {election.display === 0 && // Online data
            <Data election={election} checked={stateData.checkedInkData} />
          }
          {election.display === 2 && // Události
            <Events election={election} />
          }
        </div>
      </div>
    </div>
  );
}

// poskládádní struktůry filtrů
function filterParseData(data:inkDataFiltersInterface[] | undefined){
	let dataFilter:any = []
	if(data){
    for(let i = 0; data.length > i; i++){
      makeFilterTree(data[i], dataFilter, data[i].item.length, 0);
    }
    
  }else{
    console.log("WARN: filtry jsou prázdné");
  }
  
  function makeFilterTree(data:inkDataFiltersInterface,dataFilter:any,deep:number,itemIndex:any){
    if(deep === 1){
      if(!data.value){
        dataFilter[data.item[itemIndex]] = {
          name: data.name,
          item: []
        }
      }else{
        dataFilter[data.item[itemIndex]] = {
          name: data.name,
          value: data.value
        }
      }
    }else{
      makeFilterTree(data, dataFilter[data.item[itemIndex]].item, deep-1, itemIndex+1);
    }
  }

  console.log("INFO: Filtry parse data: ", dataFilter);
  return dataFilter;
}