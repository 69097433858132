import React, { useState, useContext, useEffect } from "react";
import {ConfigContext, socket, contextInterface,useSocketStore} from './WebSocket/WebSocket';
import {socketStateInterface} from './types/app'
import {inkDataFiltersInterface} from './types/INK';

const root = document.documentElement;

export function findIndexDeviceByAdress(adress:string|undefined){
  return useSocketStore.getState().socketState.findIndex((age:socketStateInterface)=>{return age.wsAdress === adress});   
}

export function findIndexDeviceByID(id:string){
  return useSocketStore.getState().socketState.findIndex((age:socketStateInterface)=>{return age.wsID === id});
}

// poskládádní struktůry filtrů
export function filterParseData(data:inkDataFiltersInterface[]){
	let dataFilter:any = []
	
	for(let i = 0; data.length > i; i++){
		makeFilterTree(dataFilter,data[i].item.length,i,0);
	}
	
	function makeFilterTree(dataFilter:any,deep:any,index:any,itemIndex:any){
		if(deep === 1){
			if(!data[index].value){
				dataFilter[data[index].item[itemIndex]] = {
					name: data[index].name,
					item: []
				}
			}else{
				dataFilter[data[index].item[itemIndex]] = {
					name: data[index].name,
					value: data[index].value
				}
			}
		}else{
			makeFilterTree(dataFilter[data[index].item[itemIndex]].item,deep-1,index,itemIndex+1);
		}
	}
    
  console.log("sestava dat: ", dataFilter)
  return dataFilter;
}

// načtení blobálních barev css
export function getColorCSS(color: string) {
  return getComputedStyle(document.documentElement).getPropertyValue(color);
}

// zapsání zvolené barvy do css
export function setColorCSS(color: any) {
  root.style.setProperty('--zvolena', color);
  localStorage.setItem('color', color);
}

export function useNetwork() {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  useEffect(() => {
    window.addEventListener("offline", () =>
      setNetwork(window.navigator.onLine)
    );

    window.addEventListener("online", () =>
      setNetwork(window.navigator.onLine)
    );
  });

  return isOnline;
}