import React, { useContext, useState, useEffect } from "react";
import Style from "../Device.module.css";
import modalStyle from "../../modal.module.css";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LinearProgress from "@mui/material/LinearProgress";

import { useParams } from "react-router-dom";
import {
  ConfigContext,
  AppDataContext,
  socket,
} from "../../../WebSocket/WebSocket";

import { settingsDeviceData, networkInterface } from "../../../types/app";

let stateNetwork = false;
let jednou = true;

export default function AddModal({ dataModal, setModal }: any) {
  let { index } = useParams<{ index: any }>();
  const appData = useContext(AppDataContext);
  console.log({ appData });

  const settings = appData.deviceData[index].settings;
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState({
    hostName: settings.hostName, // název zařízení
    domain: settings.domain, // doménový název

    primary: settings.ntp.primary, // primární SNTP server
    secondary: settings.ntp.secondary, // sekundární SNTP server
    zone: settings.ntp.timeZone,

    network: settings.network,

    caServer: settings.caServer, // certifikační server
    ldapServer: settings.ldapServer, // autentifikační server
  });

  /*if(jednou){
    //setLoading(true);
    socket[index].emit("loading",{cmd:"settings"}, (response:any) => {
      console.log(response.status); // ok
      setLoading(false);
    });
    jednou = false;
  }
  useEffect(() => {
    console.log("haha");
  })*/

  let poleKaret: string[] = [];
  if (settingsData?.network) {
    Object.keys(settingsData?.network).forEach((key) => poleKaret.push(key));
  }

  interface setDeviceInterface {
    i:
      | Array<{
          eth: string;
          dhcp: boolean;
          ip: string;
          mask: string;
          gateway: string;
          dns1: string;
          dns2: string;
        }>
      | undefined;
    n:
      | {
          ntpPirm: string;
          ntpSec: string;
          zone: string;
        }
      | undefined;
    s:
      | {
          hostName: string;
          domainName: string;
        }
      | undefined;
  }

  function save() {
    let data: setDeviceInterface = {
      i: undefined,
      n: undefined,
      s: undefined,
    };

    if (
      settingsData.hostName !== "settings.hostName" ||
      settingsData.domain !== "settings.domain"
    ) {
      data.s = {
        hostName: settingsData.hostName,
        domainName: settingsData.domain,
      };
    }

    if (
      settingsData.primary !== "settings.ntp.primary" ||
      settingsData.secondary !== "settings.ntp.secondary" ||
      settingsData.zone !== "settings.ntp.timeZone"
    ) {
      data.n = {
        ntpPirm: settingsData.primary,
        ntpSec: settingsData.secondary,
        zone: settingsData.zone,
      };
    }

    if (stateNetwork) {
      data.i = [];
      for (let i = 0; poleKaret.length > i; i++) {
        //@ts-ignore
        const network = settingsData.network[poleKaret[i]];
        data.i.push({
          eth: poleKaret[i],
          dhcp: network.dhcp,
          ip: network.ip,
          mask: network.mask,
          gateway: network.gateway,
          dns1: network.dns1,
          dns2: network.dns2,
        });
      }
    }

    setLoading(true);
    socket[index].emit("setDevice", data, (response: any) => {
      console.log(response.status); // ok
      setLoading(false);
    });
  }

  const setForm = (name: string) => (event: any) => {
    setSettingsData({ ...settingsData, [name]: event.target.value });
    console.log(event.target.value);
  };

  const handleClose = () => {
    setModal(false);
  };

  const sxTextField = {
    width: "calc(100% - 10px)",
    m: "5px",
    mb: "10px",
  };
  const sxTextField50 = {
    width: "calc(50% - 10px)",
    m: "5px",
    mb: "10px",
  };

  return (
    <Modal open={dataModal} onClose={handleClose}>
      <div className={modalStyle.Modal}>
        <div className={modalStyle.Modal_Header}>
          Nastavení zařízení
          <Tooltip title="Zavřít nastavení" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Close setings"
              onClick={handleClose}
              component="span"
              disabled={loading}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Uložit nastavení" placement="top" arrow>
            <IconButton
              color="primary"
              aria-label="Save setings"
              onClick={save}
              component="span"
              disabled={loading}
            >
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </div>
        {loading ? <LinearProgress /> : ""}
        <div className={modalStyle.Modal_body}>
          <span>Základní informace</span>
          <br></br>
          <br></br>
          <TextField
            variant="outlined"
            size="small"
            label={"Název zařízení"}
            sx={sxTextField50}
            value={settingsData.hostName}
            onChange={setForm("hostName")}
          />
          <TextField
            variant="outlined"
            size="small"
            label={"Doména"}
            sx={sxTextField50}
            value={settingsData.domain}
            onChange={setForm("domain")}
          />

          <hr></hr>
          <span>NTP (časový server)</span>
          <br></br>
          <br></br>
          <TextField
            variant="outlined"
            size="small"
            label={"NTP primár"}
            sx={sxTextField50}
            value={settingsData.primary}
            onChange={setForm("primary")}
          />
          <FormControl sx={sxTextField50} size="small" fullWidth>
            <InputLabel id="costomer-select-label">Časová zóna</InputLabel>
            <Select
              labelId="costomer-select-label"
              id="costomer-select"
              label={"Časová zóna"}
              value={settingsData.zone}
              onChange={setForm("zone")}
            >
              <MenuItem value={"Europe/Prague"}>Europe/Prague</MenuItem>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            label={"NTP secundár"}
            sx={sxTextField50}
            value={settingsData.secondary}
            onChange={setForm("secondary")}
          />

          <hr></hr>
          <span>Interface</span>
          <br></br>
          {poleKaret.map((key) => {
            //@ts-ignore
            const network: networkInterface = settingsData.network[key];
            if (!network) {
              return <></>;
            }
            return (
              <React.Fragment key={key}>
                <br></br>
                <span>{key}</span>
                <Box sx={{ ml: "5px" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={network.dhcp}
                        onChange={(event) => {
                          let test = settingsData.network;
                          //@ts-ignore
                          test[key].dhcp = event.target.checked;
                          setSettingsData({ ...settingsData, network: test });
                          stateNetwork = true;
                        }}
                      />
                    }
                    label="DHCP"
                  />
                </Box>
                <TextField
                  variant="outlined"
                  size="small"
                  label="IP"
                  sx={sxTextField50}
                  value={network.ip}
                  onChange={(event) => {
                    let test = settingsData.network;
                    //@ts-ignore
                    test[key].ip = event.target.value;
                    setSettingsData({ ...settingsData, network: test });
                    stateNetwork = true;
                  }}
                  disabled={network.dhcp}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  label="Maska"
                  sx={sxTextField50}
                  value={network.mask}
                  onChange={(event) => {
                    let test = settingsData.network;
                    //@ts-ignore
                    test[key].mask = event.target.value;
                    setSettingsData({ ...settingsData, network: test });
                    stateNetwork = true;
                  }}
                  disabled={network.dhcp}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  label="Výchozí brána"
                  sx={sxTextField50}
                  value={network.gateway}
                  onChange={(event) => {
                    let test = settingsData.network;
                    //@ts-ignore
                    test[key].gateway = event.target.value;
                    setSettingsData({ ...settingsData, network: test });
                    stateNetwork = true;
                  }}
                  disabled={network.dhcp}
                />{" "}
                <br></br>
                <TextField
                  variant="outlined"
                  size="small"
                  label="DNS primární"
                  sx={sxTextField50}
                  value={network.dns1}
                  onChange={(event) => {
                    let test = settingsData.network;
                    //@ts-ignore
                    test[key].dns1 = event.target.value;
                    setSettingsData({ ...settingsData, network: test });
                    stateNetwork = true;
                  }}
                  disabled={network.dhcp}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  label="DNS sekundární"
                  sx={sxTextField50}
                  value={network.dns2}
                  onChange={(event) => {
                    let test = settingsData.network;
                    //@ts-ignore
                    test[key].dns2 = event.target.value;
                    setSettingsData({ ...settingsData, network: test });
                    stateNetwork = true;
                  }}
                  disabled={network.dhcp}
                />
              </React.Fragment>
            );
          })}

          <hr></hr>
          <span>Zabezpečení</span>
          <br></br>
          <br></br>
          <TextField
            variant="outlined"
            disabled
            size="small"
            label={"CA server"}
            sx={sxTextField}
            value={settingsData.caServer}
            onChange={setForm("caServer")}
          />
          <TextField
            variant="outlined"
            disabled
            size="small"
            label={"LDAP server"}
            sx={sxTextField50}
            value={settingsData.ldapServer}
            onChange={setForm("ldapServer")}
          />
        </div>
      </div>
    </Modal>
  );
}
