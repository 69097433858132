import React, { useState } from "react";
import style from "./Style.module.css";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useNavigate } from "react-router";
import { useUserStore } from "../../App";

let windowObjectReference: any = null;
let previousUrl: any = null;

export default function AuthModul() {
  const navigate = useNavigate();
  const [elementMenu, setElementMenu] = useState(null);
  const [user] = useUserStore((state) => [state.user]);

  const handleClick = (event: any) => {
    setElementMenu(event.currentTarget);
  };

  const handleClose = () => {
    setElementMenu(null);
  };

  function Profile() {
    setElementMenu(null);
    navigate("/settings/profile");
  }

  function Logout() {
    setElementMenu(null);
    window.localStorage.removeItem("apollo-cache-persist");
    window.localStorage.removeItem("token");
    window.location.href = "/";
  }

  return (
    <React.Fragment>
      <div className={style.Auth}>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {user ? (
            <span className={style.Name}>
            {" "}{user?.fname} {user?.lname}{" "}
            </span>
          ):(
            <span className={style.Name}>
            {" "}Anonymní uživatel{" "}
            </span>
          )}
          <Avatar className={style.Avatar} src={user?.image || ""} />
        </IconButton>
        <Menu
          id="simple-menu"
          className={style.Menu}
          anchorEl={elementMenu}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(elementMenu)}
          onClose={handleClose}
        >
          {user && (
            <MenuItem onClick={Profile}>Profil</MenuItem>
          )}
          {user ? (
            <MenuItem onClick={Logout}>Odhlásit se</MenuItem>
          ):(
            <AuthUsingPopup/>
          )}
        </Menu>
      </div>
    </React.Fragment>
  );
}

// Přihlášení přez popup
function AuthUsingPopup() {

  const receiveMessage = async (event: any) => {
    if (event.origin !== window.location.origin) {
      return;
    }
    const data  = JSON.parse(event.data);

    if(data.reload){
      window.location.reload() 
    }
  };

  // otevření popup
  const openSignInWindow = (url: string, name: string) => {
    window.removeEventListener('message', receiveMessage);

    const w = 600, h = 600;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    const strWindowFeatures = 'toolbar=no, menubar=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left;

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    }

    // Přidání posluchače pro zprávu od popup
    window.addEventListener('message', (event) => receiveMessage(event), false);
  };

  function openPopup() {
    openSignInWindow(`https://app.elzel.cz/popup-login?redirect=${window.location.origin}`,"_blank");
  }

  return (
    <MenuItem onClick={openPopup}>Přihlásit se</MenuItem>
  )
}