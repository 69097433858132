import React, { useContext } from "react";
import style from "./Style.module.css";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import DevicesIcon from "@mui/icons-material/Devices";
import GrainIcon from "@mui/icons-material/Grain";
import PowerIcon from "@mui/icons-material/Power";
import SettingsIcon from "@mui/icons-material/Settings";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import { useTranslation } from "react-i18next";
import { AppDataContext } from "../WebSocket/WebSocket";

export default function SideBar() {
  const { t } = useTranslation();
  const { appData } = useContext(AppDataContext);

  return (
    <div className={style.SideBar}>
      <NavLink
        to={"/"}
        className={({ isActive }) => (isActive ? style.isActive : undefined)}
      >
        <Tooltip title="Home" placement="right">
          <IconButton aria-label="home" color="inherit">
            <HomeIcon />
          </IconButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to={"/device"}
        className={({ isActive }) => (isActive ? style.isActive : undefined)}
      >
        <Tooltip title="device" placement="right">
          <IconButton aria-label="device" color="inherit">
            <DevicesIcon />
          </IconButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to={"/data"}
        className={({ isActive }) => (isActive ? style.isActive : undefined)}
      >
        <Tooltip title="data" placement="right">
          <IconButton aria-label="data" color="inherit">
            <GrainIcon />
          </IconButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to={"/DRT"}
        className={({ isActive }) => (isActive ? style.isActive : undefined)}
      >
        <Tooltip title="DŘT" placement="right">
          <IconButton aria-label="DRT" color="inherit">
            <PowerIcon />
          </IconButton>
        </Tooltip>
      </NavLink>
      <NavLink
        to={"/DDTS"}
        className={({ isActive }) => (isActive ? style.isActive : undefined)}
      >
        <Tooltip title="DDTS" placement="right">
          <IconButton aria-label="DDTS" color="inherit">
            <DeviceHubIcon />
          </IconButton>
        </Tooltip>
      </NavLink>
      <div style={{ marginTop: "auto", marginBottom: 20 }}>
        <NavLink
          to={"/settings"}
          className={({ isActive }) => (isActive ? style.isActive : " ") + (appData.user.logged ? " " : style.disabled_link)}
        >
          <Tooltip title={t("menu.settings")} placement="bottom">
            <IconButton aria-label="Nastavení" color="inherit">
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </NavLink>
      </div>
    </div>
  );
}
