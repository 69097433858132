import React, { useState, useContext } from 'react';

import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import {
  ConfigContext,
  DataContext,
  socket,
} from "../../../../../WebSocket/WebSocket"


export default function SelectOutput({value1, value2, onChange}:any){
  const ws = useContext(DataContext);
  const data = ws.eovStateData[0].Global.outputs;
  let card = [];
  let bit = [];

  for(let i = 0; i < data.length; i++){
    let volno = false;
    for(let e = 0; e < data[i].length; e++){
      if(data[i][e] === true){
        volno = true;
      }
    }
    if(volno){
      card.push(i+1);
    }
  }

  if(value1 > 0){
    for(let i = 0; i < data[value1-1].length; i++){
      if(data[value1-1][i] === true){
        bit.push(i+1);
      }
    }
    bit.push(value2);
  }

  return(
    <React.Fragment>
      <div>
        <FormHelperText>Karta</FormHelperText>
        <Select
          variant="outlined"
          value={value1}
          onChange={onChange}
          name={"outCard"}      
        >
          {card.map((number: any) => (
            <MenuItem key={number} value={number} >
              {number}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div>
        <FormHelperText>Bit</FormHelperText>
        <Select
          variant="outlined"
          value={value2}
          onChange={onChange}
          name={"outOutput"}      
        >
          {bit.map((number: any) => (
            <MenuItem key={number} value={number} >
              {number}
            </MenuItem>
          ))}
        </Select>
      </div>
    </React.Fragment>
  );
}