import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import style from "./Device.module.css";
import Button from "@mui/material/Button";
import {
  ConfigContext,
  AppDataContext,
  InkDefaultDataContext,
} from "../../WebSocket/WebSocket";
import { socketStateInterface } from "../../types/app";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import AddModal from "./components/AddDevice";
import LoadModal from "./components/LoadDevice";
import SettingsModal from "./components/SettingsDevice";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const { t } = useTranslation();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openLoadModal, setOpenLoadModal] = useState(false);
  const [editingSettingsIndex, setEditingSettingsIndex] = useState(0);
  const navigate = useNavigate();
  const appData = useContext(AppDataContext);

  function AddAdress() {
    setOpenAddModal(!openAddModal);
  }

  function LoadDevice() {
    setOpenLoadModal(!openLoadModal);
  }

  function Edit(index: number) {
    setEditingSettingsIndex(index);
    setOpenSettingsModal(!openSettingsModal);
  }

  return (
    <ConfigContext.Consumer>
      {({ deleteSocket }) => (
        <AppDataContext.Consumer>
          {({ deviceData, socketState }) => (
            <React.Fragment>
              <div className={style.Menu_Menu}>
                <Button variant="contained" onClick={AddAdress}>
                  {t("add-device")}
                </Button>
                <Button variant="contained" onClick={LoadDevice} disabled={!appData.appData.user.logged}>
                  {t("load-device")}
                </Button>
              </div>

              <div className={style.Menu_Body}>
                {socketState.map(
                  (
                    { connect, wsAdress }: socketStateInterface,
                    index: number
                  ) => {
                    console.log(socketState);
                    return (
                      <div
                        key={Math.random()}
                        className={
                          style.zarizeni +
                          " " +
                          (connect === false ? style.disconect : "")
                        }
                      >
                        <div className={style.zarizeni_Header}>
                          {deviceData[index]?.settings.hostName}
                          <IconButton
                            color="primary"
                            style={{ float: "right", marginLeft: "5px" }}
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Opravdu chcete vymazat zařízení: "${wsAdress}" `
                                )
                              )
                              deleteSocket(index);
                              navigate("/device");
                            }}
                            component="span"
                          >
                            <DeleteIcon />
                          </IconButton>
                          <IconButton
                            color="primary"
                            style={{ float: "right", marginLeft: "5px" }}
                            onClick={() => Edit(index)}
                            component="span"
                          >
                            <SettingsIcon />
                          </IconButton>
                        </div>
                        <NavLink
                          to={"/device/" + index}
                          className={({ isActive }) => (isActive ? style.Button_Active : undefined)}
                        >
                          <InkDefaultDataContext.Consumer>
                            {(data) => (
                              <div className={style.zarizeni_body}>
                                <table>
                                  <tbody>
                                    <tr>
                                      <th>Typ zařízení:</th>
                                      <td>{data[index]?.deviceType}</td>
                                    </tr>
                                    <tr>
                                      <th>WSAdress:</th>
                                      <td>{wsAdress}</td>
                                    </tr>
                                    <tr>
                                      <th>Destinace:</th>
                                      <td>{data[index]?.info.destination}</td>
                                    </tr>
                                    <tr>
                                      <th>Popis:</th>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </InkDefaultDataContext.Consumer>
                        </NavLink>
                      </div>
                    );
                  }
                )}
              </div>
              {openAddModal === true ? (
                <AddModal dataModal={openAddModal} setModal={setOpenAddModal} />
              ) : (
                ""
              )}
              {openSettingsModal === true ? (
                // <SettingsModal
                //   settingsId={editingSettingsIndex}
                //   dataModal={openSettingsModal}
                //   setModal={setOpenSettingsModal}
                // />
                <AddModal
                  deviceIndex={editingSettingsIndex}
                  dataModal={openSettingsModal}
                  setModal={() =>
                    typeof editingSettingsIndex === "number"
                      ? setOpenSettingsModal(false)
                      : setOpenAddModal(false)
                  }
                />
              ) : (
                ""
              )}
              {openLoadModal === true ? (
                <LoadModal
                  dataModal={openLoadModal}
                  setModal={setOpenLoadModal}
                />
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </AppDataContext.Consumer>
      )}
    </ConfigContext.Consumer>
  );
}
