import { useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
//import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button } from '@mui/material';


const QUERY_GetUsers = loader("../../graphql/GetUsers.graphql");
const MUTATION_AddUser = loader("../../graphql/AddUser.graphql");
const MUTATION_DeleteUser = loader("../../graphql/DeleteUser.graphql");

/*const columns: GridColDef[] = [
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  {
    field: "fname",
    headerName: "Jméno",
    width: 100,
  },
  {
    field: "lname",
    headerName: "Přijmení",
    width: 100,
  },
  {
    field: "role",
    headerName: "Role",
    width: 130,
    renderCell: (params:any) => <pre>{params.value}</pre>,
  },
];
*/

export default function ListUsers() {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { loading, error, data, refetch } = useQuery<any>(QUERY_GetUsers);
  const [addUser, { data: dataAdd }] = useMutation(MUTATION_AddUser,{refetchQueries: [QUERY_GetUsers]});
  const [deleteUser, { data: dataDelete }] = useMutation(MUTATION_DeleteUser, {refetchQueries: [QUERY_GetUsers]});

  if (loading) return <p>Loading...</p>;
  if (error) return <>Na tuto stránku nejspíše nemáte práva, zkontrolujte zda jste admin.</>;
  console.log('data',data)

  function deleteUsers() {
    // let promises: Promise<any>[] = [];

    if (window.confirm("Chcete opravdu smazat " + selectedIds.length + " uživatelů?")) {
      selectedIds.forEach((id: any) => {
       deleteUser({variables: {id}});
      });
    }

    // Promise.all(promises).then(() => {
    //   refetch();
    //   setSelectedIds([]);
    // });
  }
  
  return (
    <div>
      {/* {data.getUsers.users.map((user: any) => (<div>
        {user.fname} {" "}
        {user.lname}
      </div>))} */}
       <>
      <h1>Správa uživatelů</h1>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> 
      <div>
        <div style={{ height: "400px" }}>
          <DataGrid
            getRowId={(row: any) => row.id}
            rows={data.getUsers.users}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[10]}
            checkboxSelection={true}
            autoHeight={false}
            rowHeight={50}
            selectionModel={selectedIds}
            onSelectionModelChange={(selectionsIDs:any) => {
              // console.log({ selectionModel });
              setSelectedIds(selectionsIDs as any);
              // console.log({ selectionModel });
              //setSelectedIds(selectionsIDs as any);
            }}
          />
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteUsers()
            }}
          >
            Smazat vybrané {selectedIds.length}
          </Button>

          <Button
            variant="contained"
            color="info"
            onClick={async () => {
              try {
                await addUser({
                  variables: {
                    email: prompt("Zadej email"),
                    fname: prompt("Zadej jméno"),
                    lname: prompt("Zadej přijmení"),
                    password: prompt("Zadej heslo"),
                  },
                })
              } catch {
                alert(
                  "Nepodařilo se přidat uživatele. Zkontrolujte zda tento účet existuje, či již není v seznamu."
                );
              }
              refetch();
            }}
          >
            Přidat uživatele
          </Button>
        </div>
      </div>*/}
    </>
    </div>
  )
}