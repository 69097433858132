import React, { useContext, useMemo } from "react";
import { inkDataItemInterface } from "../../../types/INK";
import { InkDefaultDataContext } from "../../../WebSocket/WebSocket";
import moduleStyle from '../style.module.css';

import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

// ikony ve stylech
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";

import moment from "moment/moment";

interface lineStyleInterface {
  style: {
    line?: React.CSSProperties;
    cell?: React.CSSProperties;
    icon?: any;
  };
  text?: string;
}

export const dataGridLayoutTemplate = "60px 60px 8fr 2.5fr 220px 40px";

export default function DataBox({
  setClickDataModal,
  election,
  data,
  style = {},
}: {
  setClickDataModal: any;
  election: any;
  data: inkDataItemInterface;
  style?: any;
}) {
  const dataStyle = {
    default: {
      line: {
        backgroundColor: "white",
      },
    },
    quality: {
      line: {
        backgroundColor: "#E859FF",
        fontWeight: "600",
        color: "#810099",
      },
    },
    reserve: {
      line: {
        backgroundColor: "#B1B1B1",
        color: "#5c5c5c",
        fontWeight: "600",
      },
    },
    connect: {
      icon: CompareArrowsIcon,
      line: {
        backgroundColor: "#92ff92",
        color: "#178D46",
        fontWeight: "600",
      },
    },
    disconnect: {
      line: {
        backgroundColor: "#FF0000",
        fontWeight: "600",
        color: "#ffce75",
      },
      icon: CompareArrowsIcon,
    },
    // styl info
    infoOFF: {
      icon: InfoOutlinedIcon,
    },
    // styl aktivní info
    infoON: {
      icon: InfoOutlinedIcon,
      line: {
        backgroundColor: "#92beff",
        color: "#005aff",
        fontWeight: "600",
      },
    },
    // styl měření
    measurement: {
      icon: TimelineIcon,
    },
    // styl aktivní poruchy
    errorON: {
      line: {
        backgroundColor: "#FF0000",
      },
      icon: ReportOutlinedIcon,
    },
    // styl poruchy
    errorOFF: {
      icon: ReportOutlinedIcon,
    },
    // styl aktivní výstrahy
    warningON: {
      line: {
        backgroundColor: "#FFFF00",
        fontWeight: "600",
        color: "#818122",
      },
      icon: WarningAmberIcon,
    },
    // styl výstrahy
    warningOFF: {
      icon: WarningAmberIcon,
    },
    // styl přítomnosti napětí
    voltageON: {
      icon: BoltOutlinedIcon,
      line: {
        fontWeight: "600",
        color: "#ff3737",
      },
    },
    // styl nepřítomnosti napětí
    voltageOFF: {
      icon: BoltOutlinedIcon,
      line: {
        fontWeight: "600",
        color: "#38bb38",
      },
    },
  };
  const ws = useContext(InkDefaultDataContext);

  function lineStyleFind(
    style: number,
    value: number,
    en: number,
    quality: number,
    typ: number
  ) {
    const testStyle = ws[election.deviceIndex].graphics;

    let nameStyl, text;
    //console.log("test stylu",testStyle[style], style, value);
    if (testStyle[style]?.item) {
      if (testStyle[style].item[value]) {
        nameStyl = testStyle[style].item[value]?.style;
        text = testStyle[style].item[value]?.name;
      }
    }

    let styleLine: any = {};

    //@ts-ignore
    if (nameStyl === undefined || dataStyle[nameStyl] === undefined) {
      styleLine = dataStyle["default"];
    } else {
      //@ts-ignore
      styleLine = dataStyle[nameStyl];
    }

    if (typ === 7 || typ === 8 || typ === 9 || typ === 27 || typ === 28) {
      styleLine = dataStyle.measurement;
    }
    if (quality === 1) {
      if (en === 0) {
        styleLine.line = dataStyle.reserve?.line;
        //console.log("reserve", typ);
      } else {
        styleLine.line = dataStyle.quality?.line;
      }
    } else if (en === 0) {
      styleLine.line = dataStyle.reserve?.line;
      //console.log("reserve", typ);
    }

    return {
      style: styleLine,
      text: text,
    };
  }

  function selectionTime() {
    switch (election.time) {
      case 0:
        return data.time;
      case 1:
        return moment(data.time / 1000000).format(
          ws[election.deviceIndex]?.timeFormat?.time
        );
      case 2:
        return (
          "UTC: " +
          moment
            .utc(data.time / 1000000)
            .format(ws[election.deviceIndex]?.timeFormat?.timeUTC)
        );
      default:
        return "Error";
    }
  }

  const lineStyle: lineStyleInterface = useMemo(
    () =>
      lineStyleFind(data.style, data.value, data.en, data.quality, data.typ),
    [data.value, data.quality]
  );

  //const lineStyle:lineStyleInterface = lineStyleFind(data.style,data.value,data.en,data.quality,data.typ);

  return (
    <div
      style={{
        ...style,
        ...lineStyle.style.line,
        display: "grid",
        gridTemplateColumns: dataGridLayoutTemplate,
        // alignItems: "center",
      }}
      className={moduleStyle.dataBox}
    >
      <div>{data.id}</div>
      <div>{lineStyle.style.icon ? <lineStyle.style.icon /> : null}</div>
      <div>{data.desc}</div>
      <div style={lineStyle.style.cell}>
        {lineStyle.text ? lineStyle.text : data.value}
      </div>
      <div>{selectionTime()}</div>
      <div>
        <IconButton
          onClick={() => {
            setClickDataModal({
              deviceIndex: election.deviceIndex,
              index: data.id,
              open: true,
            });
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </div>
    </div>
  );
}
