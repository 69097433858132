import React, { useState, useContext } from "react";
import style from "../style.module.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toNumber } from 'lodash';

import {ConfigContext, contextInterface} from '../../../WebSocket/WebSocket';
import {electionInterface} from '../InkData';

export default function Filters({filterData, checked, setChecked, display}:any){

  if(!filterData){return <> </>}

  console.log("test filtru -----");

  // Změna stavu zaškrtnutí položky
  function click(event:React.ChangeEvent<HTMLInputElement>){
    if(event.target.checked){
      let data = [...checked];
      let index = data.indexOf(toNumber(event.target.name));
      data.splice(index, 1);
      setChecked(data);
    }
    else{
      setChecked([...checked, toNumber(event.target.name)]);
    }
  }

  // Změna stavu zaškrtnutí sumarizační položky 
  function viceClick(event:React.ChangeEvent<HTMLInputElement>){
    const item = JSON.parse(event.target.name);
    console.log(item);
    let data = [...checked];
    for(let i = 0; i < item.length; i++){
      if(event.target.checked){
        let index = data.indexOf(item[i].value);
        data.splice(index, 1);
      }
      else{
        if(data.indexOf(item[i].value) == -1){
          data.push(item[i].value);
        }
      }
    }
    setChecked(data);
  }

  // Zjištění sumarizačního stavu pro sumarizační položku
  function stavPolozek(item:any){
    for(let i = 0; i < item.length; i++){
      if(checked.indexOf(item[i].value) == -1){
        return true;
      }
    }
    return false;
  }

  // Zjištění rozdílu stavu pro sumarizační položku
  function rozdilPolozek(item:any){
    let prvni = (checked.indexOf(item[0].value) !== -1);
    for(let i = 0; i < item.length; i++){
      if(prvni !== (checked.indexOf(item[i].value) !== -1)){
        return true;
      }
    }
    return false;
  }

  // Vykreslování položek filtru
  function Polozky({item}:any){
    
    return(
      <React.Fragment>
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
          {item?.map(({value,name,item}:any, index:number)=>{
            return (
              <React.Fragment key={index}>
                <FormControlLabel
                  label={name.toString()}
                  control={item ?
                    <Checkbox
                      name={JSON.stringify(item)}
                      checked={stavPolozek(item)}
                      indeterminate={rozdilPolozek(item)}
                      onChange={viceClick}
                    />
                    :
                    <Checkbox
                      name={value.toString()}
                      disabled={display.indexOf(value) == -1}
                      checked={checked.indexOf(value) == -1}
                      onChange={click}
                    />
                  }
                />
                {item ? <Polozky item={item}/> : ""}
              </React.Fragment>
            );
          })}
        </Box>
      </React.Fragment>
    ); 
  }

  // Vykreslení panelu filtru
  return(
    <React.Fragment>
      {filterData.map(({name, item}:{name:string,item:Array<object>},index:number)=>{
        return(
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={style.accordionSummary}
            >
              <Typography>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{backgroundColor: "white", color:"black"}}
            >
                <Polozky item={item}/>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </React.Fragment>
  );
}

