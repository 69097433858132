import React, { ReactNode, useContext, useState } from "react";
import Style from "./../EOV_OV.module.css";
import Out from "./Out.module.css";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import TimerIcon from "@mui/icons-material/Timer";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { ConfigContext, DataContext } from "../../../../../WebSocket/WebSocket";
import { Tooltip } from "@mui/material";

import { useParams } from "react-router-dom";
import Modal1 from "./Command";
import Modal2 from "./SettingsOutputs";
import {setingsOutputsOutputsInterface} from '../../../../../types/EOV_OV';
import {eovSettingsCommand, eovStateCommand} from '../../../../../WebSocket/WebSocket';



export default function Outputs() {
  let { id } = useParams<{ id: string }>();
  let adress = id;
  const [setings, setSetings] = useState({
    state: false,
    index: 0,
    name: "",
    adress: adress,
  });
  const [command, setCommand] = useState({
    state: false,
    index: 0,
    name: "",
    command: "",
    adress: adress,
  });

  console.log(adress);

  const ws = useContext(ConfigContext);
  const testovani = useContext(DataContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const DeviceSetingsIndex = ws.eovSettingsData.findIndex(checkAdult);
  const StateIndex = testovani.eovStateData.findIndex(checkAdult);
  const CommandDeviceIndex = eovStateCommand.findIndex(checkAdult);
  console.log("index", StateIndex);

  function ManualState(e: any) {
    const index = Number(e.target.parentElement.getAttribute("data-id"));

    const datta = {
      state: true,
      index: eovStateCommand[CommandDeviceIndex].Outputs[index].state,
      name: ws.eovSettingsData[DeviceSetingsIndex]?.Outputs[index]?.name,
      command: "output-state",
      adress: adress,
    };
    setCommand(datta);
  }

  function Auto(e: any) {
    const index = Number(e.target.parentElement.getAttribute("data-id"));

    const datta = {
      state: true,
      index: eovStateCommand[CommandDeviceIndex].Outputs[index].auto,
      name: ws.eovSettingsData[DeviceSetingsIndex]?.Outputs[index]?.name,
      command: "output-auto",
      adress: adress,
    };
    setCommand(datta);
  }

  function QhmState(id: number) {
    return testovani.eovStateData[StateIndex]?.Global.QHM === id ? true : false;
  }
  function MaskState(id: number) {
    function checkAdult(age: any) {
      return age.id >= id;
    }
    const index = testovani.eovStateData[StateIndex]?.Global.mask.findIndex(
      checkAdult
    );
    return testovani.eovStateData[StateIndex]?.Global.mask[index];
  }
  function ButtonState(index: number) {
    return testovani.eovStateData[StateIndex]?.Outputs[index]?.manual;
  }

  function ButtonAuto(index: number) {
    return testovani.eovStateData[StateIndex]?.Outputs[index]?.auto;
  }

  function OutputsState(index: number) {
    return testovani.eovStateData[StateIndex]?.Outputs[index]?.state;
  }

  function HodMaxState(id: number) {
    function checkAdult(age: any) {
      return age.id >= id;
    }
    const index = ws.eovSettingsData[DeviceSetingsIndex].Outputs.findIndex(
      checkAdult
    );

    function checkAdult2(age: any) {
      return age.id >= testovani.eovStateData[StateIndex]?.Global.QHM;
    }
    const index2 = ws.eovSettingsData[DeviceSetingsIndex]?.Outputs[
      index
    ]?.QHM.findIndex(checkAdult2);

    return ws.eovSettingsData[DeviceSetingsIndex]?.Outputs[index]?.QHM[
      index2
    ];
  }

  return (
    <ConfigContext.Consumer>
      {({ eovSettingsData }) => (
        <React.Fragment>
          {eovSettingsData[DeviceSetingsIndex]?.Outputs.map(({name, typ, manual, binInput, binOutput, workingGroup, mask, QHM, CVA, limitAutoOff, auto}: setingsOutputsOutputsInterface, index:number) => {
              return (
                <div
                  key={index}
                  className={
                    Out.outputs +
                    " " +
                    (OutputsState(index) === "onMan" ? Out.ONBorder : Out.OFFBorder)
                  }
                >
                  <div>
                    <div className={Out.grid}>
                      <div className={Out.outNumber}>
                        <span>{index+1}</span>
                      </div>
                      <div className={Out.outName}>
                        <span>{name}</span>
                      </div>
                      <div className={Out.outButton}>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            const modalDatta = {
                              state: true,
                              index: index,
                              name: name,
                              adress: adress,
                            };
                            setSetings(modalDatta);
                          }}
                          component="span"
                        >
                          <SettingsIcon />
                        </IconButton>
                      </div>
                      <div className={Out.outControl}>
                        <span>Ovládání</span>
                        <br></br>
                        <Button
                          className={
                            (ButtonState(index) === "on" ? Out.ON : "") +
                            " " +
                            (ButtonState(index) === "off" ? Out.OFF : "") +
                            " " +
                            (ButtonState(index) === "null" ? Out.NULL : "")
                          }
                          data-id={index}
                          onClick={ManualState}
                        >
                          Ručně
                        </Button>
                        <Button
                          className={
                            manual === false
                              ? ButtonAuto(index) === true
                                ? Out.ON
                                : Out.OFF
                              : Out.DisableNone
                          }
                          data-id={index}
                          onClick={Auto}
                        >
                          Automatikou
                        </Button>
                      </div>
                      <div className={Out.outAuto}>
                        {manual === false?
                          <div
                          
                        >
                          <span>Automatika</span>
                          <br></br>
                          <div className={Out.outAutoGrid}>
                            <Tooltip title="Kalendář" placement="top" arrow>
                              <EventAvailableIcon
                                className={
                                  (auto.calendar === true && ButtonAuto(index) === true ? Out.ONIcon : Out.OFFIcon)
                                  + " " +
                                  (ButtonAuto(index) === false? Out.DisableIcon  : "")
                                }
                              />
                            </Tooltip>
                            <Tooltip title="Interval" placement="top" arrow>
                              <TimerIcon
                                className={
                                  (auto.interval === true && ButtonAuto(index) === true ? Out.ONIcon : Out.OFFIcon)
                                  + " " +
                                  (ButtonAuto(index) === false ? Out.DisableIcon : "")
                                }
                              />
                            </Tooltip>
                            <Tooltip title="Čidlo" placement="top" arrow>
                              <React.Fragment>
                                {typ.typ === 1 ? 
                                  <AcUnitIcon
                                    className={
                                      (auto.sensor.state === true && ButtonAuto(index) === true ? Out.ONIcon : Out.OFFIcon)
                                      + " " +
                                      (ButtonAuto(index) === false ? Out.DisableIcon  : "")
                                    }
                                  />
                                :
                                  <WbSunnyIcon
                                  className={
                                    (auto.sensor.state === true && ButtonAuto(index) === true ? Out.ONIcon : Out.OFFIcon)
                                    + " " +
                                    (ButtonAuto(index) === false ? Out.DisableIcon  : "")
                                  }
                                  />
                                }
                              </React.Fragment>
                            </Tooltip>

                            <Tooltip
                              className={Style.LimitAutoOff}
                              title="Mezní hodnota čidla"
                              placement="top"
                              arrow
                            >
                              <span
                                className={
                                  ButtonAuto(index) === false || auto.sensor.state === false ? Out.DisableIcon : ""
                                }
                              >
                              {typ.typ === 2 ? ( auto.sensor.limit + " lx" ) : " "}
                              </span>
                            </Tooltip> 
                         
                            <Tooltip
                              title="Automatilcké vypnutí limitem"
                              placement="top"
                              arrow
                            >
                              <TimerIcon
                                className={
                                  (limitAutoOff.state === true && ButtonAuto(index) === true ? Out.ONIcon : Out.OFFIcon)
                                  + " " +
                                  (ButtonAuto(index) === false ? Out.DisableIcon : "")
                                }
                              />
                            </Tooltip>
                            <Tooltip
                              className={Style.LimitAutoOff}
                              title="Čas automatického vypnutí"
                              placement="top"
                              arrow
                            >
                              <span
                                className={
                                  ButtonAuto(index) === false || limitAutoOff.state === false ? Out.DisableIcon : ""
                                }
                              >
                                {limitAutoOff.limit} s
                              </span>
                            </Tooltip>
                          </div>
                        </div>
                        : ""}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={Out.grid2}>
                      <Tooltip title="Intervaly" placement="top" arrow>
                        <div className={Out.intervals}>
                          <div
                            className={
                              manual === true ? Out.DisableNone : ""
                            }
                          >
                            {auto.intervals.map(({ id, state, name, useDay, start, end, }: any) => {
                                return (
                                  <React.Fragment key={id}>
                                    <div
                                      className={
                                        (state === true &&
                                        ButtonAuto(id) === true
                                          ? Out.ONIcon
                                          : Out.OFFIcon) +
                                        " " +
                                        (ButtonAuto(id) === false ||
                                        auto.interval === false
                                          ? Out.DisableIcon
                                          : "")
                                      }
                                    >
                                      <span>{name}</span> <br></br>
                                      <span>
                                        {start} : {end}
                                      </span>
                                    </div>
                                  </React.Fragment>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </Tooltip>

                      <div className={Out.cva}>
                        <span>CVA</span>
                        {CVA?.map(({ id, comparation }: any) => {
                          return (
                            <div key={id}>
                              10 {comparation}
                            </div>
                          )
                        })}
                      </div>

                      <div className={Out.info}>
                        <div className={Out.ON}>Testem</div>
                        <div className={Out.WAR}>Ručně</div>
                        <div className={HodMaxState(index) === true ? Out.ERR : ""}>
                          1/4 max.
                        </div>
                        <div>Porucha</div>
                      </div>
           {/*
                      <div className={Out.qhm}>
                        <span>QHM</span>
                        <br></br>
                        <div>
                          {setings.QHM.map(({ id, state }: any) => {
                            return (
                              <React.Fragment key={id}>
                                <div
                                  className={
                                    state === true
                                      ? QhmState(id) === true
                                        ? Out.ON
                                        : Out.OFF
                                      : Out.NULL
                                  }
                                >
                                  {id}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
            */}          

           {/* <div className={Out.group}>
                        <span>Společné řízení</span>
                        <br></br>
                        <div>
                          {setings.mask.map(({ id, state }: any) => {
                            return (
                              <React.Fragment key={id}>
                                <div
                                  className={
                                    state === true
                                      ? MaskState(id) === true
                                        ? Out.ON
                                        : Out.OFF
                                      : Out.NULL
                                  }
                                >
                                  {id}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        </div>*/}
                    </div>
                  </div>
                </div>
              );
            })}
          {setings.state === true ? (
            <Modal2 dataModal={setings} setModal={setSetings} />
          ) : (
            ""
          )}
          {command.state === true ? (
            <Modal1 data={command} setCommand={setCommand} />
          ) : (
            ""
          )}
        </React.Fragment>
      )}
    </ConfigContext.Consumer>
  );
}

function Consumption({id, adress}: any) {

  const ws = useContext(DataContext);

  function checkAdult(age: any) {
    return age.DeviceAdress === adress;
  }
  const DeviceIndex = ws.eovOnlineData.findIndex(checkAdult);
  
  function checkAdult2(age: any) {
    return age.id === id;
  }
  const ConsumptionIndex = ws.eovOnlineData[DeviceIndex]?.Outputs.findIndex(checkAdult2);

  return (
    <DataContext.Consumer>
      {({ eovOnlineData }) => (
        <React.Fragment>
          {eovOnlineData[DeviceIndex]?.Outputs[ConsumptionIndex]?.measurement[0].value}
        </React.Fragment>
      )}
    </DataContext.Consumer>
  );
}
